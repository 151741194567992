import React, { useState, useEffect } from "react";
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import { colors, assets, styles } from "theme-config/index";
import api from "api";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
// import required modules
import { EffectCards, Autoplay, EffectCreative } from "swiper/modules";
import Skeleton from "react-loading-skeleton";

const HomeSlider = () => {
  const navigate = useNavigate();
  const [lstBanner, setlstBanner] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getBanner();
  }, []);

  const getBanner = () => {
    setLoading(true);
    api
      .post("guestlogin/getlistbanner", { shop_name: window.shop_id })
      .then((res) => {
        setlstBanner(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const showListProduct = async () => {
  //     var data = {
  //         currentKeyProduct: category_id
  //     }
  //     await setStorage({ data });
  //     navigate({
  //         pathname: "/ProductList",
  //         search: `catid=${category_id}`
  //     })
  // }

  const creativeEffect = {
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: true,
        origin: "left center",
        translate: ["-5%", 0, -200],
        rotate: [0, 100, 0],
      },
      next: {
        origin: "right center",
        translate: ["5%", 0, -200],
        rotate: [0, -100, 0],
      },
    },
    grabCursor: true,
    modules: [EffectCreative, Autoplay],
  };

  const cardEffect = {
    effect: "cards",
    grabCursor: true,
    modules: [EffectCards, Autoplay],
  };

  const BannerSkeleton = () => {
    return <Skeleton style={{ paddingTop: "56.25%", width: "100%", height: "100%", display: "flex", borderRadius: 12 }} />;
  };

  const renderSlider = () => {
    return isLoading ? (
      <>
        <SwiperSlide>{BannerSkeleton}</SwiperSlide>
        <SwiperSlide>{BannerSkeleton}</SwiperSlide>
        <SwiperSlide>{BannerSkeleton}</SwiperSlide>
        <SwiperSlide>{BannerSkeleton}</SwiperSlide>
      </>
    ) : (
      lstBanner.map((item) => {
        return (
          <SwiperSlide>
            <div
              style={{
                paddingTop: "56.25%",
                backgroundImage: `url(${item.image})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: 8,
              }}
              onClick={() => {
                navgiateBanner(item);
              }}
            ></div>
          </SwiperSlide>
        );
      })
    );
  };

  const navgiateBanner = async (item) => {
    switch (item.link_to) {
      case "product":
        navigate(`/${window.shop_sub_path}/ProductDetail/${item.page.id}`);
        break;
      case "category":
        navigate(`/${window.shop_sub_path}/ProductList?catid${item.page.id}`);
        break;
      case "blog":
        var data = {
          currentKeyProduct: item.page.id,
        };
        await setStorage({ data });
        navigate(`/${window.shop_sub_path}/ExploreDetail/${item.page.id}`);
        break;
      case "game":
        api.post("guestlogin/getgametypebyid", { game_id: item.page.id, shop_name: window.shop_id }).then((res) => {
          switch (res) {
            case "lucky_spin":
              navigate(`/${window.shop_sub_path}/Game/WheelFortune/${item.page.id}`);
              break;
            case "open_giftbox":
              navigate(`/${window.shop_sub_path}/Game/OpenGiftBox/${item.page.id}`);
            default:
              break;
          }
        });

        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="container-fluid py-2 px-4">
        <Swiper
          {...cardEffect}
          className="mySwiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
        >
          {renderSlider()}
        </Swiper>
      </div>
    </>
  );
};
export default HomeSlider;
