import React, { useEffect, useState, useContext } from "react";
import { Sheet, Box, Text, Button, useSnackbar, Page } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import { colors, styles } from "theme-config";
import CartContext from "context/cart/CartContext";
import numeral from "numeral";
import { getAccessToken, getPhoneNumber, getUserInfo } from "zmp-sdk/apis";
import api from "api";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";
import ReactDOM from "react-dom";
import ModalZaUI from "../modal/ModalZaUI";

function SelectProductSheet({ sheetVisible, setSheetVisible, product, mode, setReload, onSubmit }) {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [selectedItem, setSelectedItem] = useState();
  const context = useContext(CartContext);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});

  const getVariants = () => {
    var list = product.variants.sort((a, b) => a.position - b.position);
    return list;
  };

  const getImage = () => {
    if (selectedVariant.option1 && getVariants()[0].isImage) {
      var findImage = getVariants()[0].options.find((item) => {
        return item.id === selectedVariant.option1;
      });
      if (findImage && findImage.image) {
        return findImage.image;
      } else {
        return product.images[0];
      }
    } else {
      return product.images[0];
    }
  };

  const discountNumber = (originalPrice, salePrice) => {
    var discount = ((originalPrice - salePrice) * 100) / originalPrice;
    var split = discount.toString().split(".");

    if (split[0] > 0) {
      return <>{numeral(discount).format("0")}%</>;
    } else {
      return <>{numeral(discount).format("0.0")}%</>;
    }
  };

  const priceRange = (priceMin, priceMax) => {
    return (
      <>
        {numeral(priceMin).format("0,0")} đ{priceMin !== priceMax ? ` ~ ${numeral(priceMax).format("0,0")} đ` : ""}
      </>
    );
  };

  useEffect(() => {
    if (selectedItem) {
      if (window.zalo_mini_app.theme.config?.check_out_of_stock?.is_allow && selectedItem.quantity < 1) {
        setIsSoldOut(true);
      } else {
        setIsSoldOut(false);
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (Object.keys(selectedVariant).length === product.variants.length) {
      var findItem = product.skus.find((item) => {
        if (item.option1 && !item.option2 && !item.option3) {
          return item.option1 === selectedVariant.option1;
        } else if (item.option1 && item.option2 && !item.option3) {
          return item.option1 === selectedVariant.option1 && item.option2 === selectedVariant.option2;
        } else if (item.option1 && item.option2 && item.option3) {
          return item.option1 === selectedVariant.option1 && item.option2 === selectedVariant.option2 && item.option3 === selectedVariant.option3;
        }
      });
      if (findItem) {
        findItem["variants"] = product.variants;
        findItem["image_cover"] = product.images[0];
        setSelectedItem({ product_id: product.id, name: product.name, ...findItem });
      } else {
        setSelectedItem();
        setSelectedVariant({ option1: selectedVariant.option1 });
      }
    }
  }, [selectedVariant]);

  useEffect(() => {
    if (product) {
      var find = product.skus.find((item) => {
        return item.is_selected === true;
      });
      var newSelectedVariant = {};
      if (find) {
        if (find.option1) {
          newSelectedVariant["option1"] = find.option1;
        }

        if (find.option2) {
          newSelectedVariant["option2"] = find.option2;
        }
        setSelectedVariant(newSelectedVariant);
        setQuantity(find.quantity_selected);
      } else if (product.skus.length === 1) {
        //xử lý default pick khi sản phẩm 1 variant
        newSelectedVariant["option1"] = product.skus[0].option1;
        setSelectedVariant(newSelectedVariant);
      }
    }
  }, [product]);

  const handleOnClickButtonWhenUserNotLoggedIn = async () => {
    try {
      var { token } = await getStorage({ keys: ["token"] });
      if (token) {
        buyNow();
      } else {
        //hiện modal xin phép cấp quyền
        confirmZalo();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buyNow = () => {
    if (Object.keys(selectedVariant).length === product.variants.length) {
      var payload = { ...selectedItem };
      payload.quantity = quantity;
      context.addToCart(payload);
      context.addVoucher({});
      navigate(`/${window.shop_sub_path}/Checkout`);
      //   openSnackbar({
      //     type: "success",
      //     text: "Thêm vào giỏ hàng thành công",
      //     position: "top",
      //   });
    } else {
      var modal = {
        type: "info",

        description: "Vui lòng chọn các biến thể",
      };
      setModalConfig(modal);
      setIsModalOpen(true);
      // openSnackbar({
      //   type: "warning",
      //   text: "Vui lòng chọn các biến thể",
      //   position: "top",
      // });
    }
  };

  const confirmZalo = () => {
    getPhoneNumber({
      success: async (data) => {
        let { token } = data;
        const accessToken = await getAccessToken({});
        var { userInfo } = await getUserInfo();
        var zalo = {
          accessToken: accessToken,
          token: token,
          userInfo: userInfo,
        };
        api.post("guestlogin/zalologin", { zalo, shop_name: window.shop_id }).then(async (res) => {
          var data = {
            token: res.token,
            zaloId: userInfo.id,
          };
          var { errorKeys } = await setStorage({ data });
          // window.location.reload();
          buyNow();
        });
      },
      fail: async (error) => {
        console.log(error);
      },
    });
  };

  const renderPrice = () => {
    if (selectedItem) {
      if (selectedItem.sale_price === selectedItem.original_price) {
        return (
          <>
            <span style={{ fontSize: 14, fontWeight: 500, color: colors.primary }}>{priceRange(selectedItem.sale_price, selectedItem.sale_price)}</span>
          </>
        );
      } else {
        return (
          <>
            <span style={{ fontSize: 14, fontWeight: 500, color: colors.primary }}>
              {priceRange(selectedItem.sale_price, selectedItem.sale_price)}{" "}
              <span style={{ padding: "0px 4px", color: colors.error }}>-{discountNumber(selectedItem.original_price, selectedItem.sale_price)}</span>
            </span>
            <span style={{ fontSize: 14, fontWeight: 500, color: colors.outline, textDecorationLine: "line-through" }}>
              {priceRange(selectedItem.original_price, selectedItem.original_price)}
            </span>
          </>
        );
      }
    } else {
      if (product.max_discount === 0) {
        //khi ko có discount
        return (
          <>
            <span style={{ fontSize: 14, fontWeight: 500, color: colors.primary }}>
              {priceRange(product.sale_price_range.sale_price_min, product.sale_price_range.sale_price_max)}{" "}
            </span>
          </>
        );
      } else {
        //khi ko có discount
        return (
          <>
            <span style={{ fontSize: 14, fontWeight: 500, color: colors.primary }}>
              {priceRange(product.sale_price_range.sale_price_min, product.sale_price_range.sale_price_max)}{" "}
              <span style={{ padding: "0px 4px", color: colors.error }}>
                -{numeral(product.max_discount).format("0")}
                {"%"}
              </span>
            </span>
            <span style={{ fontSize: 14, fontWeight: 500, color: colors.outline, textDecorationLine: "line-through" }}>
              {priceRange(product.original_price_range.original_price_min, product.original_price_range.original_price_max)}
            </span>
          </>
        );
      }
    }
  };

  // return ReactDOM.createPortal(
  //   // The content you want to render
  //   <div>
  //     <h1>Portal Content</h1>
  //     <p>This content is rendered outside the normal component hierarchy.</p>
  //   </div>,
  //   // The DOM element where the content should be rendered
  //   document.getElementById('portal-root')
  // );

  return ReactDOM.createPortal(
    <Page>
      <Sheet
        className="custom-sheet"
        visible={sheetVisible}
        onClose={() => {
          setSheetVisible(false);
        }}
        autoHeight
        mask
        handler
        swipeToClose
      >
        {getImage() && (
          <Box p={4} className="custom-bottom-sheet" flex flexDirection="column" style={{ gap: 8 }}>
            <Box flex flexDirection="row" className="bottom-sheet-cover" style={{ gap: 8 }}>
              <div
                style={{
                  width: 100,
                  height: 100,
                  backgroundImage: getImage() ? `url(${getImage()})` : `url(${product.images[0]})`,
                  backgroundSize: "cover",
                }}
              ></div>
              {isSoldOut && (
                <div
                  style={{
                    position: "absolute",
                    width: 100,
                    height: 100,
                    // backgroundImage: getImage() ? `url(${getImage()})` : `url(${product.images[0]})`,
                    // backgroundSize: "cover",
                    backgroundColor: "rgba(127,127,127, 0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text style={{ fontSize: 20, fontWeight: 500, color: "white" }}>Hết hàng</Text>
                </div>
              )}
              <Box flex flexDirection="column">
                {renderPrice()}
              </Box>
            </Box>
            <hr className="mt-0 mb-0" style={{ color: colors.outlineVariant, opacity: 1 }}></hr>
            <Box
              flex
              flexDirection="column"
              className="bottom-sheet-body"
              style={{ height: "calc(100vh - 272px)", overflowY: "auto", paddingBottom: 72, gap: 8 }}
            >
              {getVariants().map((item, index) => {
                return (
                  <Box flex flexDirection="column" style={{ gap: 8 }}>
                    <Text style={{ fontSize: 14, fontWeight: 500 }}>{item.name}</Text>
                    {!item.isImage && (
                      <div className="row gx-0 gy-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        {item.options.map((opt, opt_index) => {
                          var is_render = false;
                          if (item.position === 1) {
                            is_render = true;
                          } else {
                            if (selectedVariant.option1) {
                              var find_opt2 = product.skus.find((item1) => {
                                return item1.option1 === selectedVariant.option1 && item1.option2 === opt.id;
                              });

                              if (!find_opt2 || (find_opt2 && find_opt2.is_active === false)) {
                                is_render = false;
                              } else {
                                is_render = true;
                              }
                            } else {
                              is_render = true;
                            }
                          }
                          if (is_render) {
                            return (
                              <div
                                style={{
                                  width: "fit-content",
                                  padding: "8px 16px",
                                  marginRight: opt_index !== item.options.length - 1 ? 8 : 0,
                                  marginBottom: 8,
                                  border:
                                    selectedVariant[`option${index + 1}`] && opt.id === selectedVariant[`option${index + 1}`]
                                      ? `1px solid ${colors.primary}`
                                      : `1px solid #C7C6CA`,
                                  opacity: is_render ? 1 : 0.5,
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 14,
                                    color: selectedVariant[`option${index + 1}`] && opt.id === selectedVariant[`option${index + 1}`] ? colors.primary : "black",
                                  }}
                                  onClick={() => {
                                    if (is_render) {
                                      var newSelectedVariant = { ...selectedVariant };
                                      newSelectedVariant[`option${index + 1}`] = opt.id;
                                      setSelectedVariant(newSelectedVariant);
                                    }
                                  }}
                                >
                                  {opt.value}
                                </span>
                              </div>
                            );
                          }
                        })}
                      </div>
                      // <Box flex flexDirection="row" style={{ gap: 8 }}>

                      // </Box>
                    )}

                    {item.isImage && (
                      <div className="row gx-2 gy-2" style={{ width: "100%" }}>
                        {item.options.map((opt) => {
                          return (
                            <div className="col-4">
                              <div
                                class="card"
                                style={{
                                  width: "100%",
                                  border:
                                    selectedVariant[`option${index + 1}`] && opt.id === selectedVariant[`option${index + 1}`]
                                      ? `1px solid ${colors.primary}`
                                      : `1px solid #C7C6CA`,
                                  fontSize: 14,
                                  borderRadius: 0,
                                  // opacity: is_render ? 1 : 0.5,
                                }}
                                // disabled={!is_render}
                                onClick={() => {
                                  // if (is_render) {
                                  var newSelectedVariant = { ...selectedVariant };
                                  newSelectedVariant[`option${index + 1}`] = opt.id;
                                  setSelectedVariant(newSelectedVariant);
                                  // }
                                }}
                              >
                                <div
                                  className="variant-card"
                                  style={{
                                    width: "100%",
                                    height: "calc(100vw / 3 - 18px)",
                                    backgroundImage: opt.image ? `url(${opt.image})` : "unset",
                                    backgroundSize: "cover",
                                    // position: "relative",
                                  }}
                                ></div>
                                <div class="card-body d-flex" style={{ padding: "4px 16px" }}>
                                  <span
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      // maxWidth: "18ch",
                                      lineHeight: "20px",
                                      display: "-webkit-box",
                                      WebkitLineClamp: 1,
                                      WebkitBoxOrient: "vertical",
                                      color:
                                        selectedVariant[`option${index + 1}`] && opt.id === selectedVariant[`option${index + 1}`] ? colors.primary : "black",
                                    }}
                                  >
                                    {opt.value}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                          // }
                        })}
                      </div>
                    )}
                  </Box>
                );
              })}
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: 14, fontWeight: 500 }}>Số lượng</span>
                <div class="input-group" style={{ maxWidth: 136 }}>
                  <button
                    class="btn"
                    style={{ border: "1px solid #E3E2E6", height: 28, display: "flex" }}
                    type="button"
                    id="button-addon1"
                    onClick={() => {
                      if (quantity > 1) {
                        document.getElementById(`quantity`).value = quantity - 1;
                        setQuantity(quantity - 1);
                      }
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash mb-1" viewBox="0 0 16 16">
                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                    </svg>
                  </button>
                  <input
                    type="text"
                    pattern="[0-9]*"
                    id={`quantity`}
                    defaultValue={quantity}
                    class="form-control"
                    placeholder=""
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                    // style={{ textAlign: "center" }}
                    onBlur={() => {
                      var quantity = document.getElementById(`quantity`).value;
                      if (!quantity || quantity === 0 || parseInt(quantity) === 0) {
                        document.getElementById(`quantity`).value = 1;
                        setQuantity(1);
                      }
                    }}
                    onChange={(e) => {
                      if (parseInt(e.target.value) > 0) {
                        document.getElementById(`quantity`).value = parseInt(e.target.value);
                        setQuantity(parseInt(e.target.value));
                      } else {
                        if (e.target.value) {
                          document.getElementById(`quantity`).value = 1;
                          setQuantity(1);
                        }
                      }
                    }}
                    style={{ textAlign: "center", height: 28 }}
                  />
                  <button
                    class="btn"
                    style={{ border: "1px solid #E3E2E6", height: 28, display: "flex" }}
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      //   if (quantity > 1) {
                      document.getElementById(`quantity`).value = quantity + 1;
                      setQuantity(quantity + 1);
                      //   }
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus mb-1" viewBox="0 0 16 16">
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </button>
                </div>
              </div>
              {/* </div> */}
            </Box>

            <Box flex flexDirection="row" className="fixed-bottom p-3 bg-white">
              {(mode === "addToCart" || mode === "updateToCart") && quantity > 0 && (
                <Button
                  className="btn"
                  style={
                    isSoldOut
                      ? { background: "#C7C6CA", height: 40, fontWeight: 500, fontSize: 14, width: "100%", borderRadius: 12, color: "white" }
                      : { ...styles.btnBottomPrimary, width: "100%" }
                  }
                  onClick={() => {
                    if (!isSoldOut) {
                      if (Object.keys(selectedVariant).length === product.variants.length) {
                        var payload = { ...selectedItem };
                        payload.quantity = quantity;
                        if (mode === "addToCart") {
                          context.addToCart(payload);
                          context.addVoucher({});
                          openSnackbar({
                            type: "success",
                            text: "Thêm vào giỏ hàng thành công",
                            position: "top",
                          });
                        }
                        if (mode === "updateToCart") {
                          context.updateToCart(payload);
                          context.addVoucher({});
                          openSnackbar({
                            type: "success",
                            text: "Cập nhật giỏ hàng thành công",
                            position: "top",
                          });
                        }

                        if (mode === "updateToCart") {
                          setReload(true);
                        }
                        setSheetVisible(false);
                      } else {
                        // openSnackbar({
                        //   type: "warning",
                        //   text: "Vui lòng chọn các biến thể",
                        //   position: "top",
                        // });
                        var modal = {
                          type: "info",

                          description: "Vui lòng chọn các biến thể",
                        };
                        setModalConfig(modal);
                        setIsModalOpen(true);
                      }
                    }
                    // }
                  }}
                >
                  {isSoldOut ? "Hết hàng" : "Xác nhận"}
                </Button>
              )}
              {mode === "buy" && quantity > 0 && (
                <Button
                  className="btn"
                  style={
                    isSoldOut
                      ? { background: "#C7C6CA", height: 40, fontWeight: 500, fontSize: 14, width: "100%", borderRadius: 12, color: "white" }
                      : { ...styles.btnBottomPrimary, width: "100%" }
                  }
                  disabled={isSoldOut}
                  onClick={() => {
                    onSubmit({ selectedItem: selectedItem, selectedVariant: selectedVariant, quantity: quantity });
                  }}
                >
                  {isSoldOut ? "Hết hàng" : "Mua ngay"}
                </Button>
              )}
              {quantity === 0 && (
                <Button
                  className="btn"
                  style={{ ...styles.btnBottomError, width: "100%" }}
                  onClick={() => {
                    // setSheetVisible(false);
                    if (Object.keys(selectedVariant).length === product.variants.length) {
                      var payload = { ...selectedItem };
                      payload.quantity = quantity;
                      context.removeFromCart(payload);
                      setReload(true);
                      setSheetVisible(false);
                    }
                  }}
                >
                  Xoá
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Sheet>
      <ModalZaUI isModalOpen={isModalOpen} modalConfig={modalConfig} setIsModalOpen={setIsModalOpen} />
    </Page>,
    // The DOM element where the content should be rendered
    document.getElementById("portal-root")
  );
}

export default SelectProductSheet;
