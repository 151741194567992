import OrderInfo from "components/common/order_info";
import ShippingAddress from "components/common/shipping_address";
import TopAppBar from "components/common/top_app_bar";
import CartContext from "context/cart/CartContext";
import numeral from "numeral";
import React, { useContext, useEffect, useState } from "react";
import { colors, styles } from "theme-config";
import api from "api";
import OptionSheet from "components/common/bottom_sheet/SelectProductSheet";
import ModalZaUI from "components/common/modal/ModalZaUI";
import { Modal, Text } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import { getStorage } from "common/zalo_storage/ZaloStorage";

function Checkout() {
  const navigate = useNavigate();
  const context = useContext(CartContext);
  const [productItems, setProductItems] = useState([]);
  const [sheetVisible, setSheetVisible] = useState(false);
  const [product, setProduct] = useState();
  const [reload, setReload] = useState(false);

  const [modalConfig, setModalConfig] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState();
  const [loading, setLoading] = useState(false);
  const [voucher, setVoucher] = useState();
  const [isShowVoucher, setIsShowVoucher] = useState(false);
  useEffect(() => {
    // console.log(context.voucher);
    // localStorage.removeItem("cubetech_cartItems");
    getToken();
    getProductItems();
    getVoucher();
    // setWidth(document.getElementById("checkout").offsetWidth);
  }, []);

  const getProductItems = () => {
    if (context.cartItems.length > 0) {
      var cartItems = context.cartItems.map((item) => {
        return { ...item, name: item.variant_title, price: item.sale_price };
      });
      setProductItems(cartItems);
    } else {
      setProductItems([]);
    }
  };

  const getVoucher = () => {
    if (context.voucher) {
      setVoucher(context.voucher);
    }
  };

  useEffect(() => {
    if (reload) {
      // getProductItems();
      setReload(false);
    }
    // console.log(reload);
  }, [reload]);

  const getToken = async () => {
    try {
      var { token } = await getStorage({ keys: ["token"] });
      if (token) {
        setIsShowVoucher(true);
      } else {
        //hiện modal xin phép cấp quyền
        setIsShowVoucher(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderTotalPrice = () => {
    if (productItems.length > 0) {
      var total = productItems
        .map((x) => {
          return x.price * x.quantity;
        })
        .reduce((x, y) => {
          return x + y;
        });
      return total;
    } else {
      return 0;
    }
  };

  const renderShippingAddress = () => {
    if (window.zalo_mini_app.theme.config?.shipping_fee?.is_allow) {
      return window.zalo_mini_app.theme.config?.shipping_fee?.value;
    } else {
      return 20000;
    }
  };

  const onClickItem = (item) => {
    getProductByVariantId(item);
  };

  const getProductByVariantId = (item) => {
    api.post("guestlogin/getproductbyvariantid", { shop_name: window.shop_id, variant_id: item.variant_id }).then((res) => {
      // console.log(res);
      res.skus.forEach((s) => {
        if (s.variant_id === item.variant_id) {
          s["is_selected"] = true;
          s["quantity_selected"] = item.quantity;
        }
      });
      setProduct(res);
      setSheetVisible(true);
    });
  };

  const onSubmit = () => {
    if (context.shippingAddress && Object.keys(context.shippingAddress).length > 0 && context.cartItems && context.cartItems.length > 0) {
      setLoading(true);
      if (window.zalo_mini_app.theme.config?.area_shipping_limit?.is_allow && window.zalo_mini_app.theme.config?.area_shipping_limit?.value?.length > 0) {
        if (
          !window.zalo_mini_app.theme.config?.area_shipping_limit?.value
            .map((item) => {
              return item.code;
            })
            .includes(context.shippingAddress.province_code)
        ) {
          setLoading(false);
          showInfo(window.zalo_mini_app.theme.config?.area_shipping_limit?.value);
          return;
        }
      }

      api
        .post("guestlogin/getorderid", { shop_name: window.shop_id })
        .then((res) => {
          setOrderId(res);
          var payload = {
            id: res,
            cartItems: context.cartItems,
            total: parseInt(context.total),
            totalDiscount: 0 - renderDiscountNumber(),
            shippingAddress: context.shippingAddress,
            discountsCode: context.voucher.voucher_id ? [context.voucher.voucher_id] : [],
            source_name: "LIVEPORTAL",
            shipping_amount: window.zalo_mini_app.theme.config?.shipping_fee?.is_allow ? window.zalo_mini_app.theme.config?.shipping_fee?.value : 20000,
          };
          saveOrder(payload);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      showError("Vui lòng chọn địa chỉ trước khi đặt hàng!");
    }
  };

  const showError = (message) => {
    var modal = {
      type: "error",
      description: message,
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  const showInfo = (province_list) => {
    var modal = {
      type: "info_shipping_address",
      province_list: province_list,
      // description: message,
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  const saveOrder = (payload) => {
    api.post("guestlogin/saveminiapporder", { data: payload, shop_name: window.shop_id }).then((res) => {
      if (res) {
        setLoading(false);
        context.clearCart();
        context.addShippingAddress({});
        context.addVoucher({});
        navigate(`/${window.shop_sub_path}/OrderDetail/${payload.id}`, { replace: true });
      }
    });
  };

  const openVoucherPicker = () => {
    navigate(`/${window.shop_sub_path}/VoucherPicker`);
  };

  const renderDiscountNumber = () => {
    if (voucher && Object.keys(voucher).length > 0) {
      if (voucher.value.type === "percentage") {
        return (parseInt(context.total) / 100) * voucher.value.value;
      } else if (voucher.value.type === "freeship") {
        return 20000;
      } else {
        return voucher.value.value;
      }
    } else {
      return 0;
    }
  };

  return (
    <div id="checkout" style={{ minHeight: "100vh" }}>
      <TopAppBar mode="back" title="Thanh toán" />
      {productItems && productItems.length > 0 && (
        <div className="d-flex flex-column flex-fill" style={{ gap: 8, paddingBottom: 56 }}>
          <div className="d-flex flex-column" style={{ gap: 8, background: "white" }}>
            <ShippingAddress mode="checkout" />
          </div>
          <div
            style={{
              marginTop: -8,
              height: 4,
              backgroundImage: `url(https://storage.googleapis.com/cubeloyalty_asia/letter-divider.png)`,
              backgroundPosition: "left",
              backgroundSize: "100%",
              backgroundRepeat: "repeat",
            }}
          ></div>
          {productItems && <OrderInfo mode="checkout" title={"Sản phẩm"} items={productItems} onClickItem={onClickItem} />}
          {isShowVoucher && (
            <div className="d-flex flex-column p-2 bg-white" style={{ gap: 8 }}>
              <div
                className="d-flex"
                onClick={() => {
                  if (!context.voucher || Object.keys(context.voucher).length === 0) {
                    openVoucherPicker();
                  }
                }}
              >
                <span style={{ fontSize: 14, fontWeight: 500 }}>Chọn mã giảm giá</span>

                {(!context.voucher || (context.voucher && Object.keys(context.voucher).length === 0)) && (
                  <div className="align-self-center ms-auto">
                    <span style={{ fontSize: 14, color: colors.primary, paddingRight: 8 }}>Chọn</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <g clip-path="url(#clip0_2029_9948)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.4712 7.52879C10.5962 7.65381 10.6664 7.82334 10.6664 8.00012C10.6664 8.1769 10.5962 8.34644 10.4712 8.47145L6.6999 12.2428C6.63841 12.3065 6.56484 12.3572 6.48351 12.3922C6.40217 12.4271 6.31469 12.4455 6.22617 12.4463C6.13765 12.4471 6.04986 12.4302 5.96793 12.3967C5.886 12.3631 5.81157 12.3136 5.74897 12.251C5.68638 12.1885 5.63688 12.114 5.60336 12.0321C5.56983 11.9502 5.55297 11.8624 5.55374 11.7739C5.55451 11.6853 5.5729 11.5979 5.60784 11.5165C5.64277 11.4352 5.69356 11.3616 5.75724 11.3001L9.05724 8.00012L5.75724 4.70012C5.6358 4.57439 5.5686 4.40598 5.57012 4.23119C5.57164 4.05639 5.64175 3.88918 5.76536 3.76557C5.88896 3.64197 6.05617 3.57186 6.23097 3.57034C6.40577 3.56882 6.57417 3.63601 6.6999 3.75745L10.4712 7.52879Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2029_9948">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}

                {voucher && Object.keys(voucher).length > 0 && (
                  <div
                    className="align-self-center ms-auto"
                    onClick={() => {
                      context.addVoucher({});
                      setVoucher({});
                    }}
                  >
                    <span style={{ fontSize: 14, color: colors.error, fontWeight: 500 }}>Xoá</span>
                  </div>
                )}
              </div>
              {voucher && Object.keys(voucher).length > 0 && (
                <div className="d-flex p-2" style={{ border: "0.5px solid #FB3", backgroundColor: "#FFBB331A", borderRadius: 4 }}>
                  <div className="d-flex flex-column" style={{ gap: 8 }}>
                    <Text style={{ fontSize: 16, fontWeight: 500, lineHeight: "24px" }}>
                      {context.voucher?.program?.voucher ? context.voucher?.program?.voucher.name : context.voucher?.program?.name}
                    </Text>
                    <Text style={{ fontSize: 12, color: colors.outline, lineHeight: "16px" }}>{context.voucher.voucher_id}</Text>
                  </div>
                  <Text
                    style={{ fontSize: 12, fontWeight: 500, color: "#FB3", marginLeft: "auto", marginTop: "auto", marginBottom: "auto", lineHeight: "16px" }}
                  >
                    Đã áp dụng
                  </Text>
                </div>
              )}
            </div>
          )}

          <div className="d-flex flex-column flex-fill p-2 bg-white">
            <span>Chi tiết thanh toán</span>
            <div className="d-flex" style={{ fontSize: 12, color: colors.outline }}>
              <span className="me-auto">Tổng tiền hàng</span>
              <span className="ms-auto">{numeral(renderTotalPrice()).format("0,0")}</span>
            </div>
            <div className="d-flex" style={{ fontSize: 12, color: colors.outline }}>
              <span className="me-auto">Tổng tiền vận chuyển</span>
              <span className="ms-auto">{numeral(renderShippingAddress()).format("0,0")}</span>
            </div>
            <div className="d-flex" style={{ fontSize: 12, color: renderDiscountNumber() > 0 ? colors.error : colors.outline }}>
              <span className="me-auto">Giảm voucher</span>
              <span className="ms-auto">
                {renderDiscountNumber() > 0 ? "-" : ""}
                {numeral(renderDiscountNumber()).format("0,0")}
              </span>
            </div>
            <div className="d-flex" style={{ fontSize: 14 }}>
              <span className="me-auto">Tổng thanh toán</span>
              <span className="ms-auto" style={{ color: colors.primary }}>
                {numeral(renderTotalPrice() + renderShippingAddress() - renderDiscountNumber()).format("0,0")}
              </span>
            </div>
          </div>
        </div>
      )}
      {productItems && productItems.length > 0 && (
        <div className="d-flex fixed-bottom justify-content-end bg-white bottom-navigation-cart" style={{ gap: 16, height: 48 }}>
          <div className="d-flex flex-column pt-1">
            <span style={{ fontSize: 14 }}>Tổng thanh toán</span>
            <span style={{ fontSize: 16, fontWeight: 500, color: colors.primary }} className="ms-auto">
              {numeral(parseInt(context.total) + renderShippingAddress() - renderDiscountNumber()).format("0,0")}
            </span>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ width: 120, color: "white", background: colors.primary, opacity: loading ? "0.7" : 1 }}
            onClick={() => {
              if (!loading) {
                onSubmit();
              }
            }}
          >
            {loading && (
              <div class="spinner-border" role="status" style={{ width: 16, height: 16, color: "white", marginRight: 8 }}>
                <span class="visually-hidden">Loading...</span>
              </div>
            )}
            Đặt hàng
          </div>
        </div>
      )}
      {(!productItems || productItems.length === 0) && (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ width: "100%", height: "90vh", gap: 8 }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="200" height="168" viewBox="0 0 200 168" fill="none">
            <g clip-path="url(#clip0_2028_9863)">
              <path
                d="M9.29347 159.615C12.0677 164.76 17.9555 166.968 17.9555 166.968C17.9555 166.968 19.3455 160.835 16.5713 155.69C13.7971 150.545 7.90925 148.337 7.90925 148.337C7.90925 148.337 6.51927 154.47 9.29347 159.615Z"
                fill="#2F2E41"
              />
              <path
                d="M11.2001 157.922C16.2087 160.935 18.1377 166.921 18.1377 166.921C18.1377 166.921 11.9461 168.02 6.93753 165.007C1.92896 161.994 0 156.008 0 156.008C0 156.008 6.19154 154.909 11.2001 157.922Z"
                fill={colors.primary}
              />
              <path d="M123.493 8.53613H92.2432V8.98256H123.493V8.53613Z" fill="#F2F2F2" />
              <path d="M115.011 8.87109H114.564V13.0006H115.011V8.87109Z" fill="#F2F2F2" />
              <path d="M101.395 8.87109H100.948V13.0006H101.395V8.87109Z" fill="#F2F2F2" />
              <path d="M139.341 29.9648H108.091V30.4113H139.341V29.9648Z" fill="#F2F2F2" />
              <path d="M117.02 25.9473H116.573V30.0767H117.02V25.9473Z" fill="#F2F2F2" />
              <path d="M130.636 25.9473H130.189V30.0767H130.636V25.9473Z" fill="#F2F2F2" />
              <path d="M180.859 40.0098H149.609V40.4562H180.859V40.0098Z" fill="#F2F2F2" />
              <path d="M158.538 35.9922H158.091V40.1217H158.538V35.9922Z" fill="#F2F2F2" />
              <path d="M172.153 35.9922H171.707V40.1217H172.153V35.9922Z" fill="#F2F2F2" />
              <path d="M177.734 143.581H146.484V144.027H177.734V143.581Z" fill="#F2F2F2" />
              <path d="M155.413 139.563H154.966V143.693H155.413V139.563Z" fill="#F2F2F2" />
              <path d="M169.028 139.563H168.582V143.693H169.028V139.563Z" fill="#F2F2F2" />
              <path d="M124.386 71.9297H93.1357V72.3761H124.386V71.9297Z" fill="#F2F2F2" />
              <path d="M102.065 67.9111H101.618V72.0406H102.065V67.9111Z" fill="#F2F2F2" />
              <path d="M115.68 67.9111H115.234V72.0406H115.68V67.9111Z" fill="#F2F2F2" />
              <path d="M134.207 125.724H102.957V126.17H134.207V125.724Z" fill="#F2F2F2" />
              <path d="M111.886 121.706H111.439V125.836H111.886V121.706Z" fill="#F2F2F2" />
              <path d="M125.502 121.706H125.056V125.836H125.502V121.706Z" fill="#F2F2F2" />
              <path d="M184.207 109.43H152.957V109.876H184.207V109.43Z" fill="#F2F2F2" />
              <path d="M161.886 105.411H161.439V109.541H161.886V105.411Z" fill="#F2F2F2" />
              <path d="M175.502 105.411H175.056V109.541H175.502V105.411Z" fill="#F2F2F2" />
              <path
                d="M80.8169 157.259H27.9629V156.879H80.4365V138.437H32.4062L30.0729 133.576L30.4158 133.411L32.6455 138.056H80.8169V157.259Z"
                fill="#2F2E41"
              />
              <path
                d="M34.9975 166.575C37.2025 166.575 38.99 164.787 38.99 162.582C38.99 160.377 37.2025 158.59 34.9975 158.59C32.7924 158.59 31.0049 160.377 31.0049 162.582C31.0049 164.787 32.7924 166.575 34.9975 166.575Z"
                fill="#3F3D56"
              />
              <path
                d="M74.3529 166.575C76.558 166.575 78.3455 164.787 78.3455 162.582C78.3455 160.377 76.558 158.59 74.3529 158.59C72.1479 158.59 70.3604 160.377 70.3604 162.582C70.3604 164.787 72.1479 166.575 74.3529 166.575Z"
                fill="#3F3D56"
              />
              <path
                d="M120.743 80.2596C122.108 80.2596 123.214 79.153 123.214 77.788C123.214 76.423 122.108 75.3164 120.743 75.3164C119.378 75.3164 118.271 76.423 118.271 77.788C118.271 79.153 119.378 80.2596 120.743 80.2596Z"
                fill="#3F3D56"
              />
              <path
                d="M86.4697 132.163H27.0616L14.207 90.1455H99.708L99.6302 90.3927L86.4697 132.163ZM27.3428 131.782H86.1909L99.1896 90.5257H14.721L27.3428 131.782Z"
                fill="#2F2E41"
              />
              <path d="M81.8328 129.976H29.6524L18.3618 92.7119H93.4602L93.3919 92.9313L81.8328 129.976Z" fill="#F2F2F2" />
              <path d="M100.773 86.3918L100.406 86.2943L102.915 76.8369H117.511V77.2173H103.208L100.773 86.3918Z" fill="#2F2E41" />
              <path d="M95.4357 102.884H18.3613V103.264H95.4357V102.884Z" fill="#2F2E41" />
              <path d="M91.0216 116.893H22.647V117.273H91.0216V116.893Z" fill="#2F2E41" />
              <path d="M57.1468 90.3359H56.7666V131.973H57.1468V90.3359Z" fill="#2F2E41" />
              <path d="M74.171 90.3242L71.4414 131.96L71.8209 131.985L74.5504 90.3491L74.171 90.3242Z" fill="#2F2E41" />
              <path d="M39.7501 90.3239L39.3706 90.3486L42.0843 131.985L42.4638 131.961L39.7501 90.3239Z" fill="#2F2E41" />
              <path d="M200 166.816H0V167.263H200V166.816Z" fill="#2F2E41" />
              <path
                d="M132.904 14.3333C132.904 14.3333 136.167 23.6204 134.159 25.0492C132.151 26.478 140.936 38.1464 140.936 38.1464L151.479 35.2888L145.705 25.5254C145.705 25.5254 144.952 15.762 144.952 14.3333C144.952 12.9045 132.904 14.3333 132.904 14.3333Z"
                fill="#A0616A"
              />
              <path
                opacity="0.1"
                d="M132.904 14.3333C132.904 14.3333 136.167 23.6204 134.159 25.0492C132.151 26.478 140.936 38.1464 140.936 38.1464L151.479 35.2888L145.705 25.5254C145.705 25.5254 144.952 15.762 144.952 14.3333C144.952 12.9045 132.904 14.3333 132.904 14.3333Z"
                fill="black"
              />
              <path
                d="M127.427 80.534C127.427 80.534 126.475 92.4405 127.427 98.6319C128.38 104.823 129.809 121.969 129.809 121.969C129.809 121.969 129.809 154.355 135.047 154.831C140.286 155.307 144.096 155.783 144.573 153.878C145.049 151.973 142.191 151.021 143.62 150.068C145.049 149.116 145.525 145.782 143.62 141.972C141.715 138.162 143.62 99.5845 143.62 99.5845L152.669 123.874C152.669 123.874 153.622 143.877 154.574 146.734C155.527 149.592 153.622 154.831 156.956 155.307C160.289 155.783 164.099 152.926 166.004 151.973C167.91 151.021 163.147 151.021 164.099 150.545C165.052 150.068 167.91 148.639 166.957 148.163C166.004 147.687 165.052 124.826 165.052 124.826C165.052 124.826 162.433 76.0095 159.099 74.1044C155.765 72.1994 153.622 75.4807 153.622 75.4807L127.427 80.534Z"
                fill="#2F2E41"
              />
              <path
                d="M136 152.926V156.736C136 156.736 131.713 167.093 136 167.093C140.286 167.093 143.62 168.166 143.62 166.737V153.878L136 152.926Z"
                fill="#2F2E41"
              />
              <path
                d="M164.099 152.919V156.729C164.099 156.729 168.386 167.086 164.099 167.086C159.813 167.086 156.479 168.159 156.479 166.731V153.871L164.099 152.919Z"
                fill="#2F2E41"
              />
              <path
                d="M139.572 21.2392C144.306 21.2392 148.144 17.4011 148.144 12.6665C148.144 7.93189 144.306 4.09375 139.572 4.09375C134.837 4.09375 130.999 7.93189 130.999 12.6665C130.999 17.4011 134.837 21.2392 139.572 21.2392Z"
                fill="#A0616A"
              />
              <path
                d="M136.953 28.6211C136.953 28.6211 139.334 35.765 143.144 34.3362L146.954 32.9074L153.622 78.6286C153.622 78.6286 148.383 86.2488 140.763 81.4862C133.142 76.7236 136.953 28.6211 136.953 28.6211Z"
                fill={colors.primary}
              />
              <path
                d="M143.62 27.1925L145.763 22.668C145.763 22.668 158.384 28.6212 160.289 30.5263C162.194 32.4313 162.194 35.2889 162.194 35.2889L158.861 47.1955C158.861 47.1955 159.813 73.3899 159.813 74.3424C159.813 75.295 163.147 80.5338 160.766 78.6288C158.384 76.7237 157.908 74.8187 156.003 77.6763C154.098 80.5338 149.812 83.8677 149.812 83.8677L143.62 27.1925Z"
                fill="#3F3D56"
              />
              <path
                d="M160.289 61.4834L158.86 74.8187C158.86 74.8187 150.288 82.4389 152.193 82.9152C154.098 83.3915 155.05 81.4864 155.05 81.4864C155.05 81.4864 158.384 84.8202 160.289 82.9152C162.194 81.0102 166.957 62.9122 166.957 62.9122L160.289 61.4834Z"
                fill="#A0616A"
              />
              <path
                d="M139.754 0.550473C137.854 0.482534 135.82 0.448926 134.218 1.47306C133.48 1.98704 132.834 2.62004 132.304 3.34617C130.744 5.31904 129.395 7.80137 129.975 10.2492L130.648 9.98657C130.648 10.64 130.503 11.2852 130.223 11.8755C130.318 11.5999 130.635 12.0455 130.55 12.3244L129.809 14.7576C131.028 14.3106 132.544 15.2156 132.73 16.5008C132.815 13.6746 133.108 10.4339 135.401 8.77909C136.557 7.94457 138.02 7.68981 139.428 7.46358C140.726 7.25488 142.088 7.05586 143.332 7.48342C144.576 7.91097 145.635 9.18319 145.353 10.468C145.927 10.2704 146.568 10.6702 146.852 11.2065C147.135 11.7429 147.15 12.3756 147.159 12.9822C147.77 13.4143 148.466 12.5563 148.715 11.8503C149.3 10.193 149.82 8.42896 149.505 6.70003C149.19 4.9711 147.781 3.31884 146.023 3.31298C146.237 3.07868 146.351 2.77059 146.341 2.45388L144.892 2.33149C145.26 2.27943 145.598 2.10113 145.849 1.82713C145.267 2.46707 140.695 0.584092 139.754 0.550473Z"
                fill="#2F2E41"
              />
              <path
                d="M139.334 25.7634C139.334 25.7634 135.457 21.9641 134.061 22.1968C132.666 22.4295 130.761 25.7634 130.761 25.7634C130.761 25.7634 119.331 29.5735 119.807 33.3836C120.283 37.1937 125.522 55.7679 125.522 55.7679C125.522 55.7679 129.808 78.1522 125.998 80.5335C122.188 82.9148 144.096 89.1062 144.573 86.2487C145.049 83.3911 146.001 54.8154 144.573 50.529C143.144 46.2426 139.334 25.7634 139.334 25.7634Z"
                fill="#3F3D56"
              />
              <path
                d="M156.003 33.3838H161.892C161.892 33.3838 166.004 51.4818 166.481 53.3868C166.957 55.2919 167.909 64.3408 167.433 64.3408C166.957 64.3408 157.432 62.4358 157.432 63.8646L156.003 33.3838Z"
                fill="#3F3D56"
              />
              <path
                d="M155.803 78.2001H133.259C131.059 68.0795 130.875 58.3013 133.259 48.959H155.803C152.165 58.1332 151.938 67.8445 155.803 78.2001Z"
                fill="#F2F2F2"
              />
              <path
                d="M124.093 65.7691L130.761 68.6267C130.761 68.6267 143.62 70.5318 143.62 65.2929C143.62 60.054 130.761 62.9116 130.761 62.9116L126.474 61.541L124.093 65.7691Z"
                fill="#A0616A"
              />
              <path
                d="M125.522 30.5264L119.807 31.9552L115.521 57.197C115.521 57.197 114.092 63.8647 116.473 64.341C118.855 64.8172 125.522 68.6274 125.522 68.6274C125.522 68.6274 126.475 61.4834 128.38 61.4834L123.617 57.6733L126.475 41.0041L125.522 30.5264Z"
                fill="#3F3D56"
              />
              <path d="M44.6982 79.2959H13.4482V79.7423H44.6982V79.2959Z" fill="#F2F2F2" />
              <path d="M22.3771 75.2773H21.9307V79.4068H22.3771V75.2773Z" fill="#F2F2F2" />
              <path d="M35.9928 75.2773H35.5464V79.4068H35.9928V75.2773Z" fill="#F2F2F2" />
              <path d="M55.6357 13.2236H24.3857V13.6701H55.6357V13.2236Z" fill="#F2F2F2" />
              <path d="M47.1535 13.5586H46.707V17.6881H47.1535V13.5586Z" fill="#F2F2F2" />
              <path d="M33.5377 13.5586H33.0913V17.6881H33.5377V13.5586Z" fill="#F2F2F2" />
              <path d="M87.1089 57.1973H55.8589V57.6437H87.1089V57.1973Z" fill="#F2F2F2" />
              <path d="M78.6271 57.5322H78.1807V61.6617H78.6271V57.5322Z" fill="#F2F2F2" />
              <path d="M65.0109 57.5322H64.5645V61.6617H65.0109V57.5322Z" fill="#F2F2F2" />
              <path d="M33.9839 56.9736H2.73389V57.4201H33.9839V56.9736Z" fill="#F2F2F2" />
              <path d="M25.5021 57.3086H25.0557V61.4381H25.5021V57.3086Z" fill="#F2F2F2" />
              <path d="M11.8859 57.3086H11.4395V61.4381H11.8859V57.3086Z" fill="#F2F2F2" />
              <path d="M71.4839 34.6523H40.2339V35.0988H71.4839V34.6523Z" fill="#F2F2F2" />
              <path d="M49.1627 30.6348H48.7163V34.7642H49.1627V30.6348Z" fill="#F2F2F2" />
              <path d="M62.7785 30.6348H62.332V34.7642H62.7785V30.6348Z" fill="#F2F2F2" />
            </g>
            <defs>
              <clipPath id="clip0_2028_9863">
                <rect width="200" height="166.958" fill="white" transform="translate(0 0.521484)" />
              </clipPath>
            </defs>
          </svg>
          <span style={{ fontSize: 16, fontWeight: 500 }}>Chưa có sản phẩm nào</span>
          <button
            className="btn"
            style={{ ...styles.btnPrimary }}
            onClick={() => {
              navigate(`/${window.shop_sub_path}/ProductList`);
            }}
          >
            Thêm sản phẩm
          </button>
        </div>
      )}
      {sheetVisible && (
        <OptionSheet
          sheetVisible={sheetVisible}
          setSheetVisible={setSheetVisible}
          product={product}
          mode="updateToCart"
          setReload={setReload}
          //   priceRange={priceRange}
          //   discountNumber={discountNumber}
          //   mode={mode}
        />
      )}
      {isModalOpen && <ModalZaUI modalConfig={modalConfig} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />}
    </div>
  );
}

export default Checkout;
