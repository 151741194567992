import React, { useState, useEffect } from "react";
import TopAppBar from "components/common/top_app_bar";
import { colors, assets, styles } from "theme-config/index";
import api from "api";
import QRCode from "react-qr-code";
import VoucherDivider from "components/common/VoucherDivider";
import moment from "moment";
import { Page, Swiper, Box, Text } from "zmp-ui";
import Barcode from "react-barcode";

const QRCodeAccumulatePoints = () => {
  const [qRConfig, setQRConfig] = useState();
  const showQR = async () => {
    var modal = { width: document.getElementById("QRCodeAccumulatePoints").offsetWidth };
    await api
      .post("/guest/getnumberphone")
      .then((data) => {
        modal["Phone"] = data?.phone;
      })
      .catch((err) => {
        console.log(err);
      });
    setQRConfig(modal);
  };
  useEffect(() => {
    showQR();
  }, []);
  return (
    <>
      <div id="QRCodeAccumulatePoints" className="container-fluid rounded-4" style={{ ...styles.bgPrimary, padding: 12 }}>
        <div
          className="container-fluid p-0 text-center pt-3"
          style={{ ...styles.bgWhite, ...styles.textSecondary, fontSize: 14, borderTopLeftRadius: 16, borderTopRightRadius: 16 }}
        >
          Đưa mã này cho nhân viên khi thanh toán
        </div>
        <div className="container-fluid p-0" style={styles.bgWhite}>
          <VoucherDivider width={(qRConfig?.width || 24) - 24} height={24} background={colors.primary} horizontal={true} />
          {/* <svg className="container-fluid p-0" viewBox="1 0 320 24" fill={colors.onPrimary} xmlns="http://www.w3.org/2000/svg" >
                        <g clip-path="url(#clip0_25_2172)">
                        <circle cy="12" r="12" fill={colors.primary}/>
                        <circle cx="320" cy="12" r="12" fill={colors.primary}/>
                        <path d="M16.0029 12.2064C15.9871 11.7181 16.1553 11.2419 16.4739 10.8735C16.6568 10.6866 16.878 10.5423 17.1221 10.4505C17.3661 10.3587 17.6272 10.3217 17.8869 10.3421C18.1487 10.3237 18.4114 10.3616 18.6576 10.4532C18.9037 10.5448 19.1278 10.688 19.3148 10.8735C19.6435 11.2369 19.8189 11.715 19.8036 12.2064C19.8101 12.4415 19.7702 12.6756 19.6863 12.8951C19.6024 13.1146 19.4761 13.3152 19.3148 13.4853C19.1256 13.6668 18.9009 13.8065 18.655 13.8954C18.4091 13.9843 18.1475 14.0205 17.8869 14.0017C17.641 14.0292 17.3921 14.0023 17.1576 13.9229C16.9231 13.8435 16.7087 13.7135 16.5293 13.542C16.3498 13.3704 16.2098 13.1615 16.1189 12.9298C16.028 12.698 15.9884 12.4491 16.0029 12.2004V12.2064Z" fill={colors.primary}/>
                        <path d="M24.9004 12.2063C24.8846 11.718 25.0528 11.2418 25.3714 10.8734C25.5543 10.6865 25.7755 10.5421 26.0196 10.4503C26.2636 10.3586 26.5247 10.3216 26.7844 10.342C27.0467 10.3231 27.31 10.3608 27.5567 10.4524C27.8034 10.544 28.0279 10.6875 28.2152 10.8734C28.5432 11.2371 28.7175 11.7152 28.7011 12.2063C28.7081 12.4412 28.6688 12.6752 28.5854 12.8947C28.502 13.1143 28.3762 13.3149 28.2152 13.4852C28.0256 13.6669 27.8004 13.8067 27.554 13.8956C27.3076 13.9846 27.0455 14.0206 26.7844 14.0015C26.526 14.0213 26.2664 13.9854 26.0228 13.8964C25.7791 13.8074 25.5571 13.6672 25.3714 13.4852C25.0554 13.1367 24.8865 12.678 24.9004 12.2063Z" fill={colors.primary}/>
                        <path d="M33.8137 12.2064C33.7964 11.7179 33.9648 11.2411 34.2847 10.8735C34.4676 10.6866 34.6888 10.5423 34.9329 10.4505C35.1769 10.3587 35.4379 10.3217 35.6977 10.3421C35.9595 10.3237 36.2222 10.3615 36.4684 10.4532C36.7145 10.5448 36.9385 10.688 37.1255 10.8735C37.4535 11.2372 37.6278 11.7153 37.6114 12.2064C37.6184 12.4413 37.5791 12.6753 37.4957 12.8949C37.4123 13.1144 37.2865 13.3151 37.1255 13.4853C36.9364 13.6668 36.7117 13.8065 36.4658 13.8954C36.2199 13.9843 35.9583 14.0205 35.6977 14.0016C35.4392 14.022 35.1794 13.9864 34.9357 13.8974C34.692 13.8083 34.4701 13.6678 34.2847 13.4853C33.9673 13.1376 33.7982 12.6784 33.8137 12.2064Z" fill={colors.primary}/>
                        <path d="M42.7178 12.2064C42.7019 11.7181 42.8702 11.2419 43.1888 10.8735C43.3716 10.6866 43.5929 10.5423 43.8369 10.4505C44.081 10.3587 44.342 10.3217 44.6017 10.3421C44.8636 10.3233 45.1264 10.361 45.3726 10.4526C45.6188 10.5442 45.8428 10.6877 46.0296 10.8735C46.3584 11.2369 46.5337 11.715 46.5185 12.2064C46.5249 12.4415 46.485 12.6756 46.4011 12.8951C46.3172 13.1146 46.1909 13.3152 46.0296 13.4853C45.8406 13.6669 45.6158 13.8067 45.3699 13.8957C45.124 13.9846 44.8623 14.0207 44.6017 14.0016C44.3558 14.0292 44.107 14.0023 43.8725 13.9229C43.638 13.8435 43.4235 13.7135 43.2441 13.5419C43.0647 13.3704 42.9246 13.1615 42.8337 12.9297C42.7428 12.698 42.7032 12.4491 42.7178 12.2004V12.2064Z" fill={colors.primary}/>
                        <path d="M51.6219 12.2064C51.606 11.7181 51.7743 11.2419 52.0929 10.8735C52.2757 10.6866 52.4969 10.5423 52.741 10.4505C52.9851 10.3587 53.2461 10.3217 53.5058 10.3421C53.7681 10.3233 54.0314 10.3609 54.2781 10.4525C54.5248 10.5441 54.7493 10.6876 54.9367 10.8735C55.2647 11.2372 55.439 11.7153 55.4226 12.2064C55.4296 12.4413 55.3902 12.6753 55.3068 12.8949C55.2234 13.1144 55.0976 13.3151 54.9367 13.4853C54.7471 13.667 54.5218 13.8068 54.2754 13.8958C54.029 13.9847 53.7669 14.0207 53.5058 14.0016C53.2474 14.0214 52.9878 13.9856 52.7442 13.8965C52.5006 13.8075 52.2786 13.6673 52.0929 13.4853C51.7768 13.1368 51.6079 12.6781 51.6219 12.2064Z" fill={colors.primary}/>
                        <path d="M60.5288 12.2063C60.5114 11.7178 60.6799 11.241 60.9998 10.8734C61.1827 10.6865 61.4039 10.5421 61.6479 10.4503C61.892 10.3586 62.153 10.3216 62.4128 10.342C62.6746 10.3236 62.9373 10.3614 63.1834 10.453C63.4296 10.5446 63.6536 10.6879 63.8406 10.8734C64.1694 11.2368 64.3448 11.7149 64.3295 12.2063C64.3359 12.4414 64.2961 12.6755 64.2122 12.895C64.1282 13.1145 64.002 13.3151 63.8406 13.4852C63.6515 13.6667 63.4268 13.8064 63.1809 13.8953C62.935 13.9842 62.6734 14.0204 62.4128 14.0015C62.1543 14.0219 61.8945 13.9863 61.6508 13.8973C61.4071 13.8082 61.1852 13.6677 60.9998 13.4852C60.6824 13.1375 60.5133 12.6783 60.5288 12.2063Z" fill={colors.primary}/>
                        <path d="M69.4331 12.2064C69.4172 11.7181 69.5855 11.2419 69.9041 10.8735C70.087 10.6866 70.3082 10.5423 70.5522 10.4505C70.7963 10.3587 71.0573 10.3217 71.3171 10.3421C71.5789 10.3233 71.8417 10.361 72.0879 10.4526C72.3342 10.5442 72.5582 10.6877 72.7449 10.8735C73.0737 11.2369 73.2491 11.715 73.2338 12.2064C73.2402 12.4415 73.2004 12.6756 73.1165 12.8951C73.0325 13.1146 72.9063 13.3152 72.7449 13.4853C72.5559 13.6669 72.3312 13.8067 72.0853 13.8957C71.8393 13.9846 71.5777 14.0207 71.3171 14.0016C71.0712 14.0292 70.8223 14.0023 70.5878 13.9229C70.3533 13.8435 70.1389 13.7135 69.9594 13.5419C69.78 13.3704 69.64 13.1615 69.5491 12.9297C69.4581 12.698 69.4186 12.4491 69.4331 12.2004V12.2064Z" fill={colors.primary}/>
                        <path d="M78.337 12.2063C78.3211 11.718 78.4894 11.2418 78.8079 10.8734C78.9908 10.6865 79.212 10.5421 79.4561 10.4503C79.7002 10.3586 79.9612 10.3216 80.2209 10.342C80.4832 10.3231 80.7465 10.3608 80.9932 10.4524C81.2399 10.544 81.4644 10.6875 81.6518 10.8734C81.9797 11.2371 82.1541 11.7152 82.1377 12.2063C82.1447 12.4412 82.1053 12.6752 82.0219 12.8947C81.9385 13.1143 81.8127 13.3149 81.6518 13.4852C81.4622 13.6669 81.2369 13.8067 80.9905 13.8956C80.7441 13.9846 80.482 14.0206 80.2209 14.0015C79.9625 14.0213 79.7029 13.9854 79.4593 13.8964C79.2157 13.8074 78.9936 13.6672 78.8079 13.4852C78.4919 13.1367 78.323 12.678 78.337 12.2063Z" fill={colors.primary}/>
                        <path d="M87.2437 12.2063C87.2263 11.7178 87.3947 11.241 87.7146 10.8734C87.8975 10.6865 88.1187 10.5421 88.3628 10.4503C88.6068 10.3586 88.8679 10.3216 89.1276 10.342C89.3894 10.3236 89.6521 10.3614 89.8983 10.453C90.1444 10.5446 90.3685 10.6879 90.5555 10.8734C90.8843 11.2368 91.0596 11.7149 91.0443 12.2063C91.0508 12.4414 91.0109 12.6755 90.927 12.895C90.8431 13.1145 90.7168 13.3151 90.5555 13.4852C90.3663 13.6667 90.1416 13.8064 89.8957 13.8953C89.6498 13.9842 89.3882 14.0204 89.1276 14.0015C88.8691 14.0219 88.6094 13.9863 88.3657 13.8973C88.122 13.8082 87.9 13.6677 87.7146 13.4852C87.3972 13.1375 87.2281 12.6783 87.2437 12.2063Z" fill={colors.primary}/>
                        <path d="M96.145 12.2063C96.1292 11.718 96.2974 11.2418 96.616 10.8734C96.7989 10.6865 97.0201 10.5421 97.2642 10.4503C97.5082 10.3586 97.7692 10.3216 98.029 10.342C98.2908 10.3232 98.5536 10.3608 98.7999 10.4525C99.0461 10.5441 99.2701 10.6876 99.4568 10.8734C99.7856 11.2368 99.961 11.7149 99.9457 12.2063C99.9522 12.4414 99.9123 12.6755 99.8284 12.895C99.7445 13.1145 99.6182 13.3151 99.4568 13.4852C99.2678 13.6668 99.0431 13.8066 98.7972 13.8955C98.5513 13.9845 98.2896 14.0206 98.029 14.0015C97.7831 14.029 97.5342 14.0022 97.2997 13.9228C97.0652 13.8434 96.8508 13.7134 96.6714 13.5418C96.4919 13.3703 96.3519 13.1613 96.261 12.9296C96.1701 12.6979 96.1305 12.449 96.145 12.2003V12.2063Z" fill={colors.primary}/>
                        <path d="M105.055 12.2063C105.038 11.7184 105.206 11.2421 105.523 10.8734C105.706 10.6865 105.927 10.5421 106.172 10.4503C106.416 10.3586 106.677 10.3216 106.936 10.342C107.199 10.3231 107.462 10.3608 107.709 10.4524C107.955 10.544 108.18 10.6875 108.367 10.8734C108.695 11.2371 108.869 11.7152 108.853 12.2063C108.86 12.4412 108.821 12.6752 108.737 12.8947C108.654 13.1143 108.528 13.3149 108.367 13.4852C108.178 13.6669 107.952 13.8067 107.706 13.8956C107.46 13.9846 107.197 14.0206 106.936 14.0015C106.678 14.0213 106.418 13.9854 106.175 13.8964C105.931 13.8074 105.709 13.6672 105.523 13.4852C105.207 13.1371 105.039 12.6778 105.055 12.2063Z" fill={colors.primary}/>
                        <path d="M113.959 12.2063C113.943 11.718 114.111 11.2418 114.43 10.8734C114.613 10.6865 114.834 10.5421 115.078 10.4503C115.322 10.3586 115.583 10.3216 115.843 10.342C116.105 10.3236 116.367 10.3614 116.614 10.453C116.86 10.5446 117.084 10.6879 117.271 10.8734C117.6 11.2368 117.775 11.7149 117.76 12.2063C117.766 12.4414 117.726 12.6755 117.642 12.895C117.558 13.1145 117.432 13.3151 117.271 13.4852C117.082 13.6667 116.857 13.8064 116.611 13.8953C116.365 13.9842 116.104 14.0204 115.843 14.0015C115.597 14.029 115.348 14.0022 115.114 13.9228C114.879 13.8434 114.665 13.7134 114.485 13.5418C114.306 13.3703 114.166 13.1613 114.075 12.9296C113.984 12.6979 113.944 12.449 113.959 12.2003V12.2063Z" fill={colors.primary}/>
                        <path d="M122.863 12.2064C122.847 11.7181 123.016 11.2419 123.334 10.8735C123.517 10.6866 123.738 10.5423 123.982 10.4505C124.226 10.3587 124.488 10.3217 124.747 10.3421C125.009 10.3233 125.272 10.361 125.518 10.4526C125.764 10.5442 125.988 10.6877 126.175 10.8735C126.504 11.2369 126.679 11.715 126.664 12.2064C126.67 12.4415 126.631 12.6756 126.547 12.8951C126.463 13.1146 126.336 13.3152 126.175 13.4853C125.986 13.6669 125.761 13.8067 125.515 13.8957C125.27 13.9846 125.008 14.0207 124.747 14.0016C124.501 14.0292 124.252 14.0023 124.018 13.9229C123.783 13.8435 123.569 13.7135 123.39 13.5419C123.21 13.3704 123.07 13.1615 122.979 12.9297C122.888 12.698 122.849 12.4491 122.863 12.2004V12.2064Z" fill={colors.primary}/>
                        <path d="M131.771 12.2064C131.753 11.7179 131.922 11.2411 132.242 10.8734C132.424 10.687 132.645 10.5429 132.888 10.4511C133.132 10.3593 133.392 10.3221 133.651 10.3421C133.914 10.3232 134.177 10.3609 134.424 10.4525C134.67 10.5441 134.895 10.6876 135.082 10.8734C135.41 11.2372 135.585 11.7153 135.568 12.2064C135.575 12.4413 135.536 12.6753 135.452 12.8948C135.369 13.1144 135.243 13.315 135.082 13.4852C134.893 13.667 134.667 13.8068 134.421 13.8957C134.175 13.9846 133.913 14.0207 133.651 14.0016C133.393 14.0217 133.134 13.986 132.891 13.897C132.648 13.8079 132.426 13.6675 132.242 13.4852C131.924 13.1376 131.755 12.6784 131.771 12.2064Z" fill={colors.primary}/>
                        <path d="M140.674 12.2063C140.658 11.718 140.827 11.2418 141.145 10.8734C141.328 10.6865 141.549 10.5421 141.793 10.4503C142.038 10.3586 142.299 10.3216 142.558 10.342C142.82 10.3236 143.083 10.3614 143.329 10.453C143.575 10.5446 143.799 10.6879 143.986 10.8734C144.315 11.2368 144.49 11.7149 144.475 12.2063C144.481 12.4414 144.442 12.6755 144.358 12.895C144.274 13.1145 144.147 13.3151 143.986 13.4852C143.797 13.6667 143.572 13.8064 143.326 13.8953C143.08 13.9842 142.819 14.0204 142.558 14.0015C142.312 14.029 142.064 14.0022 141.829 13.9228C141.595 13.8434 141.38 13.7134 141.201 13.5418C141.021 13.3703 140.881 13.1613 140.79 12.9296C140.699 12.6979 140.66 12.449 140.674 12.2003V12.2063Z" fill={colors.primary}/>
                        <path d="M149.578 12.2063C149.562 11.718 149.731 11.2418 150.049 10.8734C150.232 10.6865 150.453 10.5421 150.697 10.4503C150.941 10.3586 151.202 10.3216 151.462 10.342C151.724 10.3231 151.988 10.3608 152.234 10.4524C152.481 10.544 152.706 10.6875 152.893 10.8734C153.22 11.2377 153.394 11.7154 153.379 12.2063C153.39 12.6804 153.216 13.1399 152.893 13.4852C152.703 13.6669 152.478 13.8067 152.232 13.8956C151.985 13.9846 151.723 14.0206 151.462 14.0015C151.204 14.0215 150.944 13.9857 150.7 13.8967C150.457 13.8077 150.235 13.6674 150.049 13.4852C149.733 13.1367 149.564 12.678 149.578 12.2063Z" fill={colors.primary}/>
                        <path d="M158.486 12.2064C158.468 11.7179 158.637 11.2411 158.957 10.8734C159.139 10.687 159.36 10.5429 159.603 10.4511C159.847 10.3593 160.107 10.3221 160.367 10.3421C160.629 10.3232 160.892 10.3609 161.139 10.4525C161.386 10.5441 161.61 10.6876 161.797 10.8734C162.125 11.2372 162.3 11.7153 162.283 12.2064C162.29 12.4413 162.251 12.6753 162.168 12.8948C162.084 13.1144 161.958 13.315 161.797 13.4852C161.608 13.667 161.383 13.8068 161.136 13.8957C160.89 13.9846 160.628 14.0207 160.367 14.0016C160.109 14.0217 159.849 13.986 159.606 13.897C159.363 13.8079 159.141 13.6675 158.957 13.4852C158.639 13.1376 158.47 12.6784 158.486 12.2064Z" fill={colors.primary}/>
                        <path d="M167.389 12.2063C167.374 11.718 167.542 11.2418 167.86 10.8734C168.043 10.6865 168.264 10.5421 168.509 10.4503C168.753 10.3586 169.014 10.3216 169.273 10.342C169.535 10.3236 169.798 10.3614 170.044 10.453C170.29 10.5446 170.514 10.6879 170.701 10.8734C171.03 11.2368 171.205 11.7149 171.19 12.2063C171.197 12.4414 171.157 12.6755 171.073 12.895C170.989 13.1145 170.863 13.3151 170.701 13.4852C170.512 13.6667 170.287 13.8064 170.041 13.8953C169.796 13.9842 169.534 14.0204 169.273 14.0015C169.027 14.029 168.779 14.0022 168.544 13.9228C168.31 13.8434 168.095 13.7134 167.916 13.5418C167.736 13.3703 167.596 13.1613 167.505 12.9296C167.414 12.6979 167.375 12.449 167.389 12.2003V12.2063Z" fill={colors.primary}/>
                        <path d="M176.293 12.2063C176.277 11.718 176.446 11.2418 176.764 10.8734C176.947 10.6865 177.168 10.5421 177.412 10.4503C177.656 10.3586 177.917 10.3216 178.177 10.342C178.44 10.3231 178.703 10.3608 178.95 10.4524C179.196 10.544 179.421 10.6875 179.608 10.8734C179.935 11.2377 180.109 11.7154 180.094 12.2063C180.105 12.6804 179.931 13.1399 179.608 13.4852C179.418 13.6669 179.193 13.8067 178.947 13.8956C178.7 13.9846 178.438 14.0206 178.177 14.0015C177.919 14.0213 177.659 13.9854 177.416 13.8964C177.172 13.8074 176.95 13.6672 176.764 13.4852C176.448 13.1367 176.279 12.678 176.293 12.2063Z" fill={colors.primary}/>
                        <path d="M185.201 12.2064C185.184 11.7179 185.352 11.2411 185.672 10.8735C185.855 10.6866 186.076 10.5423 186.32 10.4505C186.564 10.3587 186.825 10.3217 187.085 10.3421C187.347 10.3237 187.61 10.3615 187.856 10.4532C188.102 10.5448 188.326 10.688 188.513 10.8735C188.841 11.2372 189.015 11.7153 188.999 12.2064C189.006 12.4413 188.967 12.6753 188.883 12.8949C188.8 13.1144 188.674 13.3151 188.513 13.4853C188.324 13.6668 188.099 13.8065 187.853 13.8954C187.607 13.9843 187.346 14.0205 187.085 14.0016C186.827 14.022 186.567 13.9864 186.323 13.8974C186.079 13.8083 185.858 13.6678 185.672 13.4853C185.355 13.1376 185.186 12.6784 185.201 12.2064Z" fill={colors.primary}/>
                        <path d="M194.105 12.2063C194.089 11.718 194.258 11.2418 194.576 10.8734C194.759 10.6865 194.98 10.5421 195.224 10.4503C195.468 10.3586 195.729 10.3216 195.989 10.342C196.251 10.3236 196.514 10.3614 196.76 10.453C197.006 10.5446 197.23 10.6879 197.417 10.8734C197.746 11.2368 197.921 11.7149 197.906 12.2063C197.912 12.4414 197.872 12.6755 197.789 12.895C197.705 13.1145 197.578 13.3151 197.417 13.4852C197.228 13.6667 197.003 13.8064 196.757 13.8953C196.511 13.9842 196.25 14.0204 195.989 14.0015C195.743 14.029 195.494 14.0022 195.26 13.9228C195.025 13.8434 194.811 13.7134 194.632 13.5418C194.452 13.3703 194.312 13.1613 194.221 12.9296C194.13 12.6979 194.091 12.449 194.105 12.2003V12.2063Z" fill={colors.primary}/>
                        <path d="M203.009 12.2063C202.993 11.718 203.162 11.2418 203.48 10.8734C203.663 10.6865 203.884 10.5421 204.128 10.4503C204.373 10.3586 204.634 10.3216 204.893 10.342C205.156 10.3231 205.419 10.3608 205.666 10.4524C205.912 10.544 206.137 10.6875 206.324 10.8734C206.652 11.2371 206.826 11.7152 206.81 12.2063C206.817 12.4412 206.778 12.6752 206.694 12.8947C206.611 13.1143 206.485 13.3149 206.324 13.4852C206.135 13.6669 205.909 13.8067 205.663 13.8956C205.416 13.9846 205.154 14.0206 204.893 14.0015C204.635 14.0213 204.375 13.9854 204.132 13.8964C203.888 13.8074 203.666 13.6672 203.48 13.4852C203.164 13.1367 202.995 12.678 203.009 12.2063Z" fill={colors.primary}/>
                        <path d="M211.916 12.2064C211.899 11.7179 212.067 11.2411 212.387 10.8735C212.57 10.6866 212.791 10.5423 213.035 10.4505C213.279 10.3587 213.54 10.3217 213.8 10.3421C214.062 10.3237 214.324 10.3615 214.571 10.4532C214.817 10.5448 215.041 10.688 215.228 10.8735C215.556 11.2372 215.73 11.7153 215.714 12.2064C215.721 12.4413 215.681 12.6753 215.598 12.8949C215.515 13.1144 215.389 13.3151 215.228 13.4853C215.039 13.6668 214.814 13.8065 214.568 13.8954C214.322 13.9843 214.061 14.0205 213.8 14.0016C213.542 14.022 213.282 13.9864 213.038 13.8974C212.794 13.8083 212.572 13.6678 212.387 13.4853C212.07 13.1376 211.9 12.6784 211.916 12.2064Z" fill={colors.primary}/>
                        <path d="M220.82 12.2063C220.804 11.718 220.973 11.2418 221.291 10.8734C221.474 10.6865 221.695 10.5421 221.939 10.4503C222.184 10.3586 222.445 10.3216 222.704 10.342C222.966 10.3232 223.229 10.3608 223.475 10.4525C223.721 10.5441 223.945 10.6876 224.132 10.8734C224.461 11.2368 224.636 11.7149 224.621 12.2063C224.627 12.4414 224.588 12.6755 224.504 12.895C224.42 13.1145 224.293 13.3151 224.132 13.4852C223.943 13.6668 223.718 13.8066 223.472 13.8955C223.227 13.9845 222.965 14.0206 222.704 14.0015C222.458 14.029 222.21 14.0022 221.975 13.9228C221.741 13.8434 221.526 13.7134 221.347 13.5418C221.167 13.3703 221.027 13.1613 220.936 12.9296C220.845 12.6979 220.806 12.449 220.82 12.2003V12.2063Z" fill={colors.primary}/>
                        <path d="M229.724 12.2063C229.708 11.718 229.877 11.2418 230.195 10.8734C230.378 10.6865 230.599 10.5421 230.843 10.4503C231.087 10.3586 231.348 10.3216 231.608 10.342C231.87 10.3231 232.134 10.3608 232.38 10.4524C232.627 10.544 232.852 10.6875 233.039 10.8734C233.367 11.2371 233.541 11.7152 233.525 12.2063C233.532 12.4412 233.493 12.6752 233.409 12.8947C233.326 13.1143 233.2 13.3149 233.039 13.4852C232.849 13.6669 232.624 13.8067 232.378 13.8956C232.131 13.9846 231.869 14.0206 231.608 14.0015C231.35 14.0213 231.09 13.9854 230.846 13.8964C230.603 13.8074 230.381 13.6672 230.195 13.4852C229.879 13.1367 229.71 12.678 229.724 12.2063Z" fill={colors.primary}/>
                        <path d="M238.634 12.2064C238.617 11.7179 238.785 11.2411 239.105 10.8735C239.288 10.6866 239.509 10.5423 239.753 10.4505C239.997 10.3587 240.258 10.3217 240.518 10.3421C240.78 10.3237 241.043 10.3615 241.289 10.4532C241.535 10.5448 241.759 10.688 241.946 10.8735C242.275 11.2369 242.45 11.715 242.435 12.2064C242.441 12.4415 242.402 12.6756 242.318 12.8951C242.234 13.1146 242.107 13.3152 241.946 13.4853C241.757 13.6668 241.532 13.8065 241.286 13.8954C241.04 13.9843 240.779 14.0205 240.518 14.0016C240.26 14.022 240 13.9864 239.756 13.8974C239.513 13.8083 239.291 13.6678 239.105 13.4853C238.788 13.1376 238.619 12.6784 238.634 12.2064Z" fill={colors.primary}/>
                        <path d="M247.535 12.2064C247.52 11.7181 247.688 11.2419 248.006 10.8735C248.189 10.6866 248.41 10.5423 248.655 10.4505C248.899 10.3587 249.16 10.3217 249.419 10.3421C249.681 10.3233 249.944 10.361 250.19 10.4526C250.436 10.5442 250.66 10.6877 250.847 10.8735C251.176 11.2369 251.351 11.715 251.336 12.2064C251.343 12.4415 251.303 12.6756 251.219 12.8951C251.135 13.1146 251.009 13.3152 250.847 13.4853C250.658 13.6669 250.433 13.8067 250.188 13.8957C249.942 13.9846 249.68 14.0207 249.419 14.0016C249.173 14.0292 248.925 14.0023 248.69 13.9229C248.456 13.8435 248.241 13.7135 248.062 13.5419C247.882 13.3704 247.742 13.1615 247.651 12.9297C247.56 12.698 247.521 12.4491 247.535 12.2004V12.2064Z" fill={colors.primary}/>
                        <path d="M256.439 12.2064C256.423 11.7175 256.592 11.2408 256.913 10.8734C257.096 10.687 257.316 10.5429 257.56 10.4511C257.803 10.3593 258.064 10.3221 258.323 10.3421C258.585 10.3232 258.849 10.3609 259.095 10.4525C259.342 10.5441 259.567 10.6876 259.754 10.8734C260.082 11.2372 260.256 11.7153 260.24 12.2064C260.247 12.4413 260.207 12.6753 260.124 12.8948C260.041 13.1144 259.915 13.315 259.754 13.4852C259.564 13.667 259.339 13.8068 259.093 13.8957C258.846 13.9846 258.584 14.0207 258.323 14.0016C258.065 14.0217 257.806 13.986 257.563 13.897C257.319 13.8079 257.098 13.6675 256.913 13.4852C256.595 13.1381 256.424 12.6788 256.439 12.2064Z" fill={colors.primary}/>
                        <path d="M265.346 12.2064C265.329 11.7179 265.497 11.2411 265.817 10.8735C266 10.6866 266.221 10.5423 266.465 10.4505C266.709 10.3587 266.97 10.3217 267.23 10.3421C267.492 10.3237 267.754 10.3615 268.001 10.4532C268.247 10.5448 268.471 10.688 268.658 10.8735C268.987 11.2369 269.162 11.715 269.147 12.2064C269.153 12.4415 269.113 12.6756 269.029 12.8951C268.945 13.1146 268.819 13.3152 268.658 13.4853C268.469 13.6668 268.244 13.8065 267.998 13.8954C267.752 13.9843 267.49 14.0205 267.23 14.0016C266.971 14.022 266.712 13.9864 266.468 13.8974C266.224 13.8083 266.002 13.6678 265.817 13.4853C265.5 13.1376 265.33 12.6784 265.346 12.2064Z" fill={colors.primary}/>
                        <path d="M274.25 12.2063C274.234 11.718 274.402 11.2418 274.721 10.8734C274.904 10.6865 275.125 10.5421 275.369 10.4503C275.613 10.3586 275.874 10.3216 276.134 10.342C276.396 10.3235 276.659 10.3613 276.906 10.4529C277.152 10.5445 277.377 10.6878 277.565 10.8734C277.891 11.2377 278.066 11.7154 278.05 12.2063C278.062 12.6804 277.887 13.1399 277.565 13.4852C277.375 13.6666 277.15 13.8062 276.903 13.8951C276.657 13.984 276.395 14.0202 276.134 14.0015C275.888 14.029 275.639 14.0022 275.404 13.9228C275.17 13.8434 274.956 13.7134 274.776 13.5418C274.597 13.3703 274.457 13.1613 274.366 12.9296C274.275 12.6979 274.235 12.449 274.25 12.2003V12.2063Z" fill={colors.primary}/>
                        <path d="M283.157 12.2064C283.14 11.7179 283.308 11.2411 283.628 10.8734C283.811 10.687 284.031 10.5429 284.275 10.4511C284.519 10.3593 284.779 10.3221 285.038 10.3421C285.301 10.3232 285.564 10.3609 285.81 10.4525C286.057 10.5441 286.282 10.6876 286.469 10.8734C286.797 11.2372 286.971 11.7153 286.955 12.2064C286.962 12.4413 286.923 12.6753 286.839 12.8948C286.756 13.1144 286.63 13.315 286.469 13.4852C286.279 13.667 286.054 13.8068 285.808 13.8957C285.561 13.9846 285.299 14.0207 285.038 14.0016C284.78 14.0217 284.521 13.986 284.278 13.897C284.035 13.8079 283.813 13.6675 283.628 13.4852C283.311 13.1376 283.142 12.6784 283.157 12.2064Z" fill={colors.primary}/>
                        <path d="M292.061 12.2064C292.044 11.7179 292.212 11.2411 292.532 10.8735C292.715 10.6866 292.936 10.5423 293.18 10.4505C293.424 10.3587 293.686 10.3217 293.945 10.3421C294.207 10.3237 294.47 10.3615 294.716 10.4532C294.962 10.5448 295.186 10.688 295.373 10.8735C295.702 11.2369 295.877 11.715 295.862 12.2064C295.868 12.4415 295.829 12.6756 295.745 12.8951C295.661 13.1146 295.534 13.3152 295.373 13.4853C295.184 13.6668 294.959 13.8065 294.713 13.8954C294.467 13.9843 294.206 14.0205 293.945 14.0016C293.687 14.022 293.427 13.9864 293.183 13.8974C292.94 13.8083 292.718 13.6678 292.532 13.4853C292.215 13.1376 292.046 12.6784 292.061 12.2064Z" fill={colors.primary}/>
                        <path d="M300.966 12.2063C300.95 11.718 301.118 11.2418 301.437 10.8734C301.62 10.6865 301.841 10.5421 302.085 10.4503C302.329 10.3586 302.59 10.3216 302.85 10.342C303.112 10.3235 303.375 10.3613 303.622 10.4529C303.869 10.5445 304.093 10.6878 304.281 10.8734C304.608 11.2377 304.782 11.7154 304.767 12.2063C304.778 12.6804 304.603 13.1399 304.281 13.4852C304.091 13.6666 303.866 13.8062 303.619 13.8951C303.373 13.984 303.111 14.0202 302.85 14.0015C302.604 14.029 302.355 14.0022 302.121 13.9228C301.886 13.8434 301.672 13.7134 301.492 13.5418C301.313 13.3703 301.173 13.1613 301.082 12.9296C300.991 12.6979 300.951 12.449 300.966 12.2003V12.2063Z" fill={colors.primary}/>
                        </g>
                        <defs>
                        <clipPath id="clip0_25_2172">
                        <rect width="322" height="24" fill={colors.onPrimary}/>
                        </clipPath>
                        </defs>
                    </svg> */}
        </div>
        {/* <div className="container-fluid rounded-bottom p-3 d-flex justify-content-center align-items-center" style={styles.bgWhite}>
                    {qRConfig?.Phone?<QRCode title="GeeksForGeeks" value={qRConfig.Phone} bgColor="#FFFFFF" fgColor="#000000" size={190} />:""}
                </div> */}
        <Box
          className="container-fluid pt-3"
          style={{ ...styles.bgWhite, borderBottomRightRadius: 16, borderBottomLeftRadius: 16 }}
          flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Swiper style={{ paddingBottom: 40 }}>
            <Swiper.Slide className="d-flex justify-content-center align-items-center">
              {qRConfig?.Phone ? <QRCode title="GeeksForGeeks" value={qRConfig.Phone} bgColor="#FFFFFF" fgColor="#000000" size={190} /> : ""}
              {/* <QRCode title="GeeksForGeeks" value="123" bgColor="#FFFFFF" fgColor="#000000" size={190} /> */}
            </Swiper.Slide>
            <Swiper.Slide className="d-flex justify-content-center align-items-center p-2">
              {qRConfig?.Phone ? <Barcode value={qRConfig.Phone} /> : ""}
              {/* <QRCode title="GeeksForGeeks" value="123" bgColor="#FFFFFF" fgColor="#000000" size={190} /> */}
              {/* <Barcode value="0797225886" /> */}
            </Swiper.Slide>
            {/* <Swiper.Slide>
              <img className="slide-img" src="https://stc-zmp.zadn.vn/zmp-zaui/images/321fb45f18f6c4a89de78.jpg" alt="slide-3" />
            </Swiper.Slide>
            <Swiper.Slide>
              <img className="slide-img" src="https://stc-zmp.zadn.vn/zmp-zaui/images/4f417921d58809d650997.jpg" alt="slide-4" />
            </Swiper.Slide>
            <Swiper.Slide>
              <img className="slide-img" src="https://stc-zmp.zadn.vn/zmp-zaui/images/677fad2e0187ddd984969.jpg" alt="slide-5" />
            </Swiper.Slide> */}
          </Swiper>
        </Box>
      </div>
    </>
  );
};
export default QRCodeAccumulatePoints;
