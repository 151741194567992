import React, { useEffect, useState } from "react";
import Home from "../home_page";
import ExchangePoint from "../exchange_point";
import ProgramDetail from "components/exchange_point/ProgramDetail";
import api from "api";
import VoucherList from "components/voucher_list";
import ExploreList from "components/explore_list";
import ExploreDetail from "components/explore_detail";
import AccumulatePoints from "components/accumulate_points";
import HistoryPoints from "components/history_points";
import Menu from "components/menu";
import Shop from "components/shop";
import Profile from "components/profile";
import { styles } from "theme-config";
import Setting from "components/setting";
import MyAccount from "components/profile/MyAccount";
import EditAccount from "components/profile/EditAccount";
import FollowOAButton from "components/common/floating_button/FollowOAButton";
import CartButton from "components/common/floating_button/CartButton";
import FollowOANotification from "components/common/modal/FollowOANotification";
import MemberBenefit from "components/member_benefit";
import moment from "moment";
import BottomNavigation from "../common/bottom_navigation";
import TierDetail from "components/tier_detail";
import { getUserInfo } from "zmp-sdk/apis";
// import { AnimationRoutes, App, ZMPRouter } from "zmp-ui";
import OrderHistory from "components/order_history";
import OrderDetail from "components/order_history/OrderDetail";
import RatingOrder from "components/order_history/RatingOrder";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";
import ProductList from "components/product";
import ProductDetail from "components/product/ProductDetail";
import Checkout from "components/checkout";
import Cart from "components/cart";
import AddressList from "components/common/shipping_address/AddressList";
import AddressForm from "components/common/address_picker/AddressForm";
import VoucherPickerList from "components/common/voucher_picker";
// import { Route } from "react-router-dom";
import GameInfo from "components/game/GameInfo";
import GameHistory from "components/game/GameHistory";
import ExchangeTurn from "components/game/ExchangeTurn";
import WheelOfFortuneWrapper from "components/game/wheel_fortune/WheelOfFortuneWrapper";
import OpenGiftBox from "components/game/open_giftbox";
import OpenGiftBoxWrapper from "components/game/open_giftbox/OpenGiftBoxWrapper";
import RatingProductList from "components/product/RatingProductList";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NotFound from "components/not_found";

const hellowork = [
  "Cười to, yêu nhiều! 😄❤️",
  "Tận hưởng, vui sống! 🌟🎉",
  "Hạnh phúc, thành công! 🌈🚀",
  "Yêu thương, bình an! 💕🕊️",
  "Vui vẻ, mạnh khỏe! 😃💪",
  "Niềm vui, tươi sáng! 🌞🌼",
  "Cùng nhau, hãy cười! 👭😄",
  "May mắn, hạnh phúc! 🍀😊",
  "Thành công, tỏa sáng! 🌟💫",
  "Cuộc sống, mơ ước! 🌌🌠",
  "Bình yên, tình bạn! 🕊️👫",
  "Niềm tin, vượt qua! 🙏🚀",
  "Hãy cười, vui vẻ! 😃😄",
  "Hạnh phúc, vui vẻ! 😊🌈",
  "Mỉm cười, hạnh phúc! 😃💖",
  "Đắm say, kỳ diệu! 😍✨",
  "Yêu bạn, cảm ơn! ❤️🙏",
  "Hãy tin, thành công! 🌟🙌",
  "Niềm vui, nắng mới! 🌞🌻",
  "Cùng nhau, bay cao! 🎈🕊️",
  "Năng lượng, mạnh mẽ! 💪⚡",
  "Hãy yêu, vui vẻ! 😍😊",
  "Sáng ngời, tươi cười! ✨😄",
  "Tình bạn, vui vẻ! 👫😃",
  "Vui vẻ, mỉm cười! 😄😊",
  "Niềm tin, thắp sáng! 🌟🕯️",
  "Hãy sống, hãy cười! 🌞😄",
  "Hạnh phúc, may mắn! 💖🍀",
  "Nắng vàng, cuộc sống! 🌞🌼",
  "Yêu thương, hòa bình! ❤️🕊️",
];

function MiniApp() {
  const [isShowFollow, setIsShowFollow] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // const [pathName, setPathName] = useState();
  const [greeting, setGreeting] = useState(hellowork[(Math.random() * hellowork.length) | 0]);
  const pathName = window.location.pathname.split("/")[2];
  // window.shop_sub_path = pathName;
  useEffect(() => {
    //setGreeting(hellowork[(Math.random() * hellowork.length) | 0]);
    const getZaloConfig = async () => {
      api
        .post("guestlogin/getappzalo", { shop_name: window.shop_id })
        .then(async (res) => {
          if (res && res.oas && res.oas.length > 0) {
            checkFollowOA(res.oas[0].access_token);
            var data = {
              oaLogo: res.oas[0].avatar,
              oaId: res.oas[0].oa_id,
              oaName: res.oas[0].name,
            };
            var { errorKeys } = await setStorage({ data });
          } else {
            var data = {
              oaLogo: "",
              oaId: "",
              oaName: "",
            };
            var { errorKeys } = await setStorage({ data });
          }
        })
        .catch((error) => {
          console.log("getappzalo:", error);
        });
    };
    const getPoint = async () => {
      api
        .post("guest/getpoint")
        .then(async (res) => {
          var data = {
            pointLogo: res.logo,
          };
          var { errorKeys } = await setStorage({ data });
        })
        .catch((error) => {
          console.log("getpoint", error);
        });
    };
    getPoint();
    getZaloConfig();
  }, []);

  // const getPathName = async () => {
  //   var { pathName } = await getStorage({ keys: ["pathName"] });
  //   setPathName(pathName);
  // };

  const checkFollowOA = async (access_token) => {
    // console.log("checkFollowOA");
    getUserInfo({
      success: (data) => {
        // xử lý khi gọi api thành công
        const { userInfo } = data;
        api
          .post("guestlogin/checkfollow", { zalo_id: userInfo.id, access_token: access_token })
          .then((res) => {
            if (res) {
              setIsShowFollow(false);
              // checkLastDisplayModal();
            } else {
              setIsShowFollow(true);
              checkLastDisplayModal();
            }
          })
          .catch((error) => {
            setIsShowFollow(true);
            checkLastDisplayModal();
            console.log("checkfollow:", error);
          });
      },
      fail: (error) => {
        // xử lý khi gọi api thất bại
        console.log(error);
      },
    });

    // getStorage({
    //   keys: ["zalo_id"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { zalo_id } = data;
    //     if (zalo_id) {

    // }
    // },
    // fail: (error) => {
    //   // xử lý khi gọi api thất bại
    //   console.log(error);
    // },
    // });
  };

  const checkLastDisplayModal = async () => {
    // console.log("checkLastDisplayModal");
    // getStorage({
    //   keys: ["lastDisplayModal"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { lastDisplayModal } = data;
    //     if ((lastDisplayModal && lastDisplayModal !== moment(new Date()).format("DD/MM/YYYY")) || !lastDisplayModal) {
    //       setTimeout(() => {
    //         setModalVisible(true);
    //       }, 10000);
    //     } else {
    //       setModalVisible(false);
    //     }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });

    var { lastDisplayModal } = await getStorage({ keys: ["lastDisplayModal"] });
    if ((lastDisplayModal && lastDisplayModal !== moment(new Date()).format("DD/MM/YYYY")) || !lastDisplayModal) {
      setTimeout(() => {
        setModalVisible(true);
      }, 10000);
    } else {
      //   setTimeout(() => {
      //     setModalVisible(true);
      //   }, 10000);
      setModalVisible(false);
    }
  };

  return (
    // <>
    <div className="layout-container">
      <div className="layout" style={{ ...styles.mainBackground, position: "relative" }}>
        <Routes>
          <Route path={`${window.shop_sub_path}/*`} Component={() => <Home greeting={greeting}></Home>}></Route>
          {/* <Route path="/Home" Component={Home}></Route> */}
          <Route path={`${window.shop_sub_path}/Home`} Component={() => <Home greeting={greeting}></Home>}></Route>
          <Route path={`${window.shop_sub_path}/ExchangePoint`} Component={ExchangePoint}></Route>
          <Route path={`${window.shop_sub_path}//ProgramDetail/:id`} Component={ProgramDetail}></Route>
          <Route path={`${window.shop_sub_path}/VoucherList`} Component={VoucherList}></Route>
          <Route path={`${window.shop_sub_path}/ExploreList`} Component={ExploreList}></Route>
          <Route path={`${window.shop_sub_path}/ExploreDetail/:id`} Component={ExploreDetail}></Route>
          <Route path={`${window.shop_sub_path}/Point`} Component={() => <AccumulatePoints greeting={greeting}></AccumulatePoints>}></Route>
          <Route path={`${window.shop_sub_path}/HistoryPoints`} Component={HistoryPoints}></Route>
          <Route path={`${window.shop_sub_path}/Menu`} Component={Menu}></Route>
          <Route path={`${window.shop_sub_path}/Shop`} Component={Shop}></Route>
          <Route path={`${window.shop_sub_path}/Profile`} Component={() => <Profile greeting={greeting}></Profile>}></Route>
          <Route path={`${window.shop_sub_path}/Setting`} Component={Setting}></Route>
          <Route path={`${window.shop_sub_path}/MyAccount`} Component={MyAccount}></Route>
          <Route path={`${window.shop_sub_path}/EditAccount`} Component={EditAccount} />
          <Route path={`${window.shop_sub_path}/MemberBenefit`} Component={MemberBenefit} />
          <Route path={`${window.shop_sub_path}/TierDetail/:id`} Component={TierDetail} />
          <Route path={`${window.shop_sub_path}/OrderHistory`} Component={OrderHistory} />
          <Route path={`${window.shop_sub_path}/OrderDetail/:id`} Component={OrderDetail} />
          <Route path={`${window.shop_sub_path}/RatingOrder`} Component={RatingOrder} />
          <Route path={`${window.shop_sub_path}/ProductList`} Component={ProductList} />
          <Route path={`${window.shop_sub_path}/ProductDetail/:id`} Component={ProductDetail} />
          <Route path={`${window.shop_sub_path}/RatingProductList/:product_id`} Component={RatingProductList} />
          <Route path={`${window.shop_sub_path}/Checkout`} Component={Checkout} />
          <Route path={`${window.shop_sub_path}/Cart`} Component={Cart} />
          <Route path={`${window.shop_sub_path}/AddressList`} Component={AddressList} />
          <Route path={`${window.shop_sub_path}/AddressForm`} Component={AddressForm} />
          <Route path={`${window.shop_sub_path}/VoucherPicker`} Component={VoucherPickerList} />
          <Route path={`${window.shop_sub_path}/Game/WheelFortune/:id`} Component={WheelOfFortuneWrapper} />
          <Route path={`${window.shop_sub_path}/Game/OpenGiftBox/:id`} Component={OpenGiftBoxWrapper} />
          <Route path={`${window.shop_sub_path}/Game/ExchangeTurn`} Component={ExchangeTurn} />
          <Route path={`${window.shop_sub_path}/Game/History`} Component={GameHistory} />
          <Route path={`${window.shop_sub_path}/Game/Info`} Component={GameInfo} />
          <Route path={`${window.shop_sub_path}/NotFound`} Component={NotFound} />
        </Routes>
        <BottomNavigation />
      </div>
      {/* {isShowFollow && <FollowOAButton style={{ right: 24, bottom: 72, left: "unset", zIndex: 9999 }} setIsShow={setIsShowFollow} />} */}
      <div className="float-button" style={{ right: 16, bottom: 72, left: "unset", zIndex: 2, position: "fixed" }}>
        {/* <div className="pt-1">
        {isShowFollow && <FollowOAButton setIsShow={setIsShowFollow} />}
        </div> */}
        <div className="pt-1">
          <CartButton />
        </div>
      </div>
      {/* <FollowOANotification modalVisible={modalVisible} setModalVisible={setModalVisible} /> */}
    </div>
    // </>
  );
}

export default MiniApp;
