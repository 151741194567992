import React, { useEffect, useState } from "react";
import api from "api";
import { getAppInfo, saveImageToGallery, showToast, openShareSheet, createShortcut, closeApp, favoriteApp } from "zmp-sdk/apis";
import TopAppBar from "components/common/top_app_bar";
import ModalZaUI from "components/common/modal/ModalZaUI";
import { styles } from "theme-config";
import { getStorage, removeStorage, setStorage } from "common/zalo_storage/ZaloStorage";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
// import { setStorage } from "zmp-sdk/apis";
function Profile({ greeting }) {
  const navigate = useNavigate();
  const [brand, setBrand] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [zaloAppInfo, setZaloAppInfo] = useState({});
  const [shopName, setShopName] = useState();

  useEffect(() => {
    // setStorage({
    //   data: {
    //     currentKey: "",
    //   },
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });

    setCurrentKey();
    getInfo();
    getStorageShopName();
    api
      .get("/guest/getbrand")
      .then((res) => {
        setBrand(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const setCurrentKey = async () => {
    var data = { currentKey: "" };
    var { errorKeys } = await setStorage({ data });
  };

  const getStorageShopName = async () => {
    var { shopName } = await getStorage({ keys: ["shopName"] });
    setShopName(shopName);
    // try {
    //   const { shop_name } = await getStorage({
    //     keys: ["shop_name"],
    //   });
    //   setShopName(shop_name);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const getInfo = async () => {
    try {
      const { name, appUrl, qrCodeUrl } = await getAppInfo({});
      // console.log('zalo', appUrl, qrCodeUrl);
      setZaloAppInfo({ name: name, appUrl: appUrl, qrCodeUrl: qrCodeUrl });
    } catch (error) {
      // xử lý khi gọi api thất bại
      console.log(error);
    }
  };

  /**
   * Hàm đăng xuất
   */
  const logOut = () => {
    //hiện modal
    var modal = {
      type: "warning",
      description: "Bạn có chắc muốn đăng xuất khỏi tài khoản này?",
      async onOk() {
        // removeStorage({
        //   keys: ["token", "zalo_id"],
        //   success: (data) => {
        //     // xử lý khi gọi api thành công
        //     const { errorKeys } = data;
        //     closeApp({});
        //   },
        //   fail: (error) => {
        //     // xử lý khi gọi api thất bại
        //     console.log(error);
        //   },
        // });
        var keys = ["token", "zaloId"];
        var { errorKeys } = await removeStorage({ keys: keys });
        closeApp({});
      },
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  const saveQRCode = () => {
    // saveImageToGallery({
    //   imageUrl: zaloAppInfo.qrCodeUrl,
    //   success: (res) => {
    //     console.log(res)
    //     // xử lý khi gọi api thành công
    //     showToast({
    //       message: "Đã lưu mã QR vào thư viện",
    //       success: (data) => {
    //         // xử lý khi gọi api thành công
    //       },
    //       fail: (error) => {
    //         // xử lý khi gọi api thất bại
    //         console.log(error);
    //       },
    //     });
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    createMiniAppShortcut();
  };
  const createMiniAppShortcut = async () => {
    try {
      await createShortcut({
        params: {
          utm_source: `shortcut_${shopName || "default"}`,
        },
      });
    } catch (error) {
      // xử lý khi gọi api thất bại
      console.log(error);
    }
  };
  const shareQRCode = async () => {
    var { miniapConfig } = await getStorage({ keys: ["miniappConfig"] });
    var { name } = await getAppInfo({});
    if (miniapConfig) {
      openShareSheet({
        type: "zmp_deep_link",
        data: {
          title: name,
          description: JSON.parse(miniapConfig).description,
          thumbnail: JSON.parse(miniapConfig).thumbnail_url,
          path: "/",
        },
        success: (data) => {},
        fail: (err) => {},
      });
    } else {
      openShareSheet({
        type: "zmp_deep_link",
        data: {
          title: name,
          thumbnail: "https://storage.googleapis.com/cubeloyalty_asia/logo192.png",
          description: "Mini App này được tạo bởi CubeLoyalty, một sản phẩm của Cubetech",
          path: "/",
        },
        success: (data) => {},
        fail: (err) => {},
      });
    }
    // getStorage({
    //   keys: ["miniapp_config"],
    //   success: async (data) => {
    //     // xử lý khi gọi api thành công
    //     var { miniapp_config } = data;
    //     const { name } = await getAppInfo({});
    //     if (miniapp_config) {
    //       openShareSheet({
    //         type: "zmp_deep_link",
    //         data: {
    //           title: name,
    //           description: JSON.parse(miniapp_config).description,
    //           thumbnail: JSON.parse(miniapp_config).thumbnail_url,
    //           path: "/",
    //         },
    //         success: (data) => {},
    //         fail: (err) => {},
    //       });
    //     } else {
    //       openShareSheet({
    //         type: "zmp_deep_link",
    //         data: {
    //           title: name,
    //           thumbnail: "https://storage.googleapis.com/cubeloyalty_asia/logo192.png",
    //           description: "Mini App này được tạo bởi CubeLoyalty, một sản phẩm của Cubetech",
    //           path: "/",
    //         },
    //         success: (data) => {},
    //         fail: (err) => {},
    //       });
    //     }
    //   },
    //   fail: async (error) => {
    //     // xử lý khi gọi api thất bại
    //     // setLogState("notLogged");
    //     const { name } = await getAppInfo({});
    //     openShareSheet({
    //       type: "zmp_deep_link",
    //       data: {
    //         title: name,
    //         thumbnail: "https://storage.googleapis.com/cubeloyalty_asia/logo192.png",
    //         description: "Mini App này được tạo bởi CubeLoyalty, một sản phẩm của Cubetech",
    //         path: "/",
    //       },
    //       success: (data) => {},
    //       fail: (err) => {},
    //     });
    //   },
    // });
  };

  const addFavoriteApp = () => {
    favoriteApp({
      success: (res) => {
        // xử lý khi gọi api thành công
        // console.log("success", res);
      },
      fail: (error) => {
        // xử lý khi gọi api thất bại
        console.log("error", error);
      },
    });
  };

  return (
    <div
      style={{
        paddingBottom: 72,
        // height: "calc(100vh)",
        position: "relative",
      }}
    >
      <TopAppBar greeting={greeting} />
      <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
        <div
          class="card mb-2 shadow-sm"
          onClick={() =>
            //navigate("/Setting")
            addFavoriteApp()
          }
        >
          <div class="card-body">
            <div className="d-flex flex-row justify-content-between">
              {/* <svg className="me-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.31 21.03C15.21 21.71 14.59 22.25 13.85 22.25H10.15C9.40999 22.25 8.78999 21.71 8.69999 20.98L8.42999 19.09C8.15999 18.95 7.89999 18.8 7.63999 18.63L5.83999 19.35C5.13999 19.61 4.36999 19.32 4.02999 18.7L2.19999 15.53C1.84999 14.87 1.99999 14.09 2.55999 13.65L4.08999 12.46C4.07999 12.31 4.06999 12.16 4.06999 12C4.06999 11.85 4.07999 11.69 4.08999 11.54L2.56999 10.35C1.97999 9.90001 1.82999 9.09001 2.19999 8.47001L4.04999 5.28001C4.38999 4.66001 5.15999 4.38001 5.83999 4.65001L7.64999 5.38001C7.90999 5.21001 8.16999 5.06001 8.42999 4.92001L8.69999 3.01001C8.78999 2.31001 9.40999 1.76001 10.14 1.76001H13.84C14.58 1.76001 15.2 2.30001 15.29 3.03001L15.56 4.92001C15.83 5.06001 16.09 5.21001 16.35 5.38001L18.15 4.66001C18.86 4.40001 19.63 4.69001 19.97 5.31001L21.81 8.49001C22.17 9.15001 22.01 9.93001 21.45 10.37L19.93 11.56C19.94 11.71 19.95 11.86 19.95 12.02C19.95 12.18 19.94 12.33 19.93 12.48L21.45 13.67C22.01 14.12 22.17 14.9 21.82 15.53L19.96 18.75C19.62 19.37 18.85 19.65 18.16 19.38L16.36 18.66C16.1 18.83 15.84 18.98 15.58 19.12L15.31 21.03ZM10.62 20.25H13.38L13.75 17.7L14.28 17.48C14.72 17.3 15.16 17.04 15.62 16.7L16.07 16.36L18.45 17.32L19.83 14.92L17.8 13.34L17.87 12.78L17.8731 12.7531C17.902 12.5027 17.93 12.2607 17.93 12C17.93 11.73 17.9 11.47 17.87 11.22L17.8 10.66L19.83 9.08001L18.44 6.68001L16.05 7.64001L15.6 7.29001C15.18 6.97001 14.73 6.71001 14.27 6.52001L13.75 6.30001L13.38 3.75001H10.62L10.25 6.30001L9.71999 6.51001C9.27999 6.70001 8.83999 6.95001 8.37999 7.30001L7.92999 7.63001L5.54999 6.68001L4.15999 9.07001L6.18999 10.65L6.11999 11.21C6.08999 11.47 6.05999 11.74 6.05999 12C6.05999 12.26 6.07999 12.53 6.11999 12.78L6.18999 13.34L4.15999 14.92L5.53999 17.32L7.92999 16.36L8.37999 16.71C8.80999 17.04 9.23999 17.29 9.70999 17.48L10.24 17.7L10.62 20.25ZM15.5 12C15.5 13.933 13.933 15.5 12 15.5C10.067 15.5 8.49999 13.933 8.49999 12C8.49999 10.067 10.067 8.50001 12 8.50001C13.933 8.50001 15.5 10.067 15.5 12Z"
                  fill="#44474F"
                />
              </svg> */}
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-heart-fill me-3" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
              </svg>

              <div className="flex-fill" style={{ fontSize: 16 }}>
                {/* Cài đặt */}
                Thêm vào mục yêu thích
              </div>

              <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#44474F" />
              </svg>
            </div>
          </div>
        </div>
        <div class="card mb-2 shadow-sm" onClick={() => navigate(`/${window.shop_sub_path}/OrderHistory`)}>
          <div class="card-body">
            <div className="d-flex flex-row justify-content-between">
              <svg width="24" height="24" className="me-3" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group">
                  <path
                    id="Vector"
                    d="M5 9.5C5.26522 9.5 5.51957 9.39464 5.70711 9.20711C5.89464 9.01957 6 8.76522 6 8.5C6 8.23478 5.89464 7.98043 5.70711 7.79289C5.51957 7.60536 5.26522 7.5 5 7.5C4.73478 7.5 4.48043 7.60536 4.29289 7.79289C4.10536 7.98043 4 8.23478 4 8.5C4 8.76522 4.10536 9.01957 4.29289 9.20711C4.48043 9.39464 4.73478 9.5 5 9.5ZM7 8.5C7 8.23478 7.10536 7.98043 7.29289 7.79289C7.48043 7.60536 7.73478 7.5 8 7.5H11C11.2652 7.5 11.5196 7.60536 11.7071 7.79289C11.8946 7.98043 12 8.23478 12 8.5C12 8.76522 11.8946 9.01957 11.7071 9.20711C11.5196 9.39464 11.2652 9.5 11 9.5H8C7.73478 9.5 7.48043 9.39464 7.29289 9.20711C7.10536 9.01957 7 8.76522 7 8.5ZM8 10.5C7.73478 10.5 7.48043 10.6054 7.29289 10.7929C7.10536 10.9804 7 11.2348 7 11.5C7 11.7652 7.10536 12.0196 7.29289 12.2071C7.48043 12.3946 7.73478 12.5 8 12.5H11C11.2652 12.5 11.5196 12.3946 11.7071 12.2071C11.8946 12.0196 12 11.7652 12 11.5C12 11.2348 11.8946 10.9804 11.7071 10.7929C11.5196 10.6054 11.2652 10.5 11 10.5H8ZM8 13.5C7.73478 13.5 7.48043 13.6054 7.29289 13.7929C7.10536 13.9804 7 14.2348 7 14.5C7 14.7652 7.10536 15.0196 7.29289 15.2071C7.48043 15.3946 7.73478 15.5 8 15.5H11C11.2652 15.5 11.5196 15.3946 11.7071 15.2071C11.8946 15.0196 12 14.7652 12 14.5C12 14.2348 11.8946 13.9804 11.7071 13.7929C11.5196 13.6054 11.2652 13.5 11 13.5H8ZM6 11.5C6 11.7652 5.89464 12.0196 5.70711 12.2071C5.51957 12.3946 5.26522 12.5 5 12.5C4.73478 12.5 4.48043 12.3946 4.29289 12.2071C4.10536 12.0196 4 11.7652 4 11.5C4 11.2348 4.10536 10.9804 4.29289 10.7929C4.48043 10.6054 4.73478 10.5 5 10.5C5.26522 10.5 5.51957 10.6054 5.70711 10.7929C5.89464 10.9804 6 11.2348 6 11.5ZM5 15.5C5.26522 15.5 5.51957 15.3946 5.70711 15.2071C5.89464 15.0196 6 14.7652 6 14.5C6 14.2348 5.89464 13.9804 5.70711 13.7929C5.51957 13.6054 5.26522 13.5 5 13.5C4.73478 13.5 4.48043 13.6054 4.29289 13.7929C4.10536 13.9804 4 14.2348 4 14.5C4 14.7652 4.10536 15.0196 4.29289 15.2071C4.48043 15.3946 4.73478 15.5 5 15.5Z"
                    fill="#44474F"
                  />
                  <path
                    id="Vector_2"
                    d="M5 0C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1H2C1.46957 1 0.960859 1.21071 0.585786 1.58579C0.210714 1.96086 0 2.46957 0 3V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H14C14.5304 20 15.0391 19.7893 15.4142 19.4142C15.7893 19.0391 16 18.5304 16 18V3C16 2.46957 15.7893 1.96086 15.4142 1.58579C15.0391 1.21071 14.5304 1 14 1H12C12 0.734784 11.8946 0.48043 11.7071 0.292893C11.5196 0.105357 11.2652 0 11 0H5ZM12 3H14V18H2V3H4V4C4 4.26522 4.10536 4.51957 4.29289 4.70711C4.48043 4.89464 4.73478 5 5 5H11C11.2652 5 11.5196 4.89464 11.7071 4.70711C11.8946 4.51957 12 4.26522 12 4V3ZM6 3V2H10V3H6Z"
                    fill="#44474F"
                  />
                </g>
              </svg>

              <div className="flex-fill" style={{ fontSize: 16 }}>
                Lịch sử đơn hàng
              </div>

              <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#44474F" />
              </svg>
            </div>
          </div>
        </div>
        <div class="card mb-2 shadow-sm" onClick={() => navigate(`/${window.shop_sub_path}/MyAccount`)}>
          <div class="card-body">
            <div className="d-flex flex-row justify-content-between">
              <svg className="me-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM18.36 16.83C16.93 15.09 13.46 14.5 12 14.5C10.54 14.5 7.07 15.09 5.64 16.83C4.62 15.49 4 13.82 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 13.82 19.38 15.49 18.36 16.83ZM8.5 9.5C8.5 7.56 10.06 6 12 6C13.94 6 15.5 7.56 15.5 9.5C15.5 11.44 13.94 13 12 13C10.06 13 8.5 11.44 8.5 9.5Z"
                  fill="#44474F"
                />
              </svg>

              <div className="flex-fill" style={{ fontSize: 16 }}>
                Tài khoản của tôi
              </div>

              <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#44474F" />
              </svg>
            </div>
          </div>
        </div>

        <div class="card mb-2 shadow-sm" onClick={() => brand.phone_number && (window.location.href = `tel:${brand.phone_number}`)}>
          <div class="card-body">
            <div className="d-flex flex-row justify-content-between">
              <svg className="me-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M21.1406 17.5313C20.3963 16.7813 18.5935 15.6867 17.7188 15.2456C16.5797 14.6719 16.486 14.625 15.5907 15.2902C14.9935 15.7341 14.5964 16.1306 13.8975 15.9816C13.1986 15.8325 11.6799 14.992 10.35 13.6664C9.02018 12.3408 8.13096 10.778 7.98143 10.0814C7.8319 9.38485 8.23502 8.9925 8.67471 8.39391C9.2944 7.55016 9.24752 7.40953 8.71784 6.27047C8.30487 5.38453 7.17846 3.59859 6.42565 2.85797C5.62034 2.0625 5.62034 2.20313 5.10143 2.41875C4.67898 2.5965 4.27369 2.81255 3.89065 3.06422C3.14065 3.5625 2.7244 3.97641 2.43331 4.59844C2.14221 5.22047 2.01143 6.67875 3.51471 9.40969C5.01799 12.1406 6.07268 13.537 8.25565 15.7139C10.4386 17.8908 12.1172 19.0613 14.5711 20.4375C17.6067 22.1377 18.7711 21.8063 19.395 21.5156C20.0189 21.225 20.4347 20.8125 20.9339 20.0625C21.1862 19.6801 21.4028 19.2753 21.5808 18.8531C21.7969 18.3361 21.9375 18.3361 21.1406 17.5313Z"
                  stroke="#44474F"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                />
              </svg>

              <div className="flex-fill" style={{ fontSize: 16 }}>
                Liên hệ
              </div>

              <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#44474F" />
              </svg>
            </div>
          </div>
        </div>

        {/* <div class="card mb-2 shadow-sm" onClick={() => navigate("/WheelFortune")}>
          <div class="card-body">
            <div className="d-flex flex-row justify-content-between">
              <svg className="me-3 bi bi-controller" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
                <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z" />
                <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z" />
              </svg>

              <div className="flex-fill" style={{ fontSize: 16 }}>
                Wheel game
              </div>

              <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#44474F" />
              </svg>
            </div>
          </div>
        </div> */}

        <div className="card border-0" style={{ ...styles.mainBackground }}>
          <div className="card-body text-center" style={{ paddingTop: 0 }}>
            <p className="mb-3">Chia sẻ mã QR này đến khách hàng tiềm năng</p>

            <img src={zaloAppInfo.qrCodeUrl} style={{ width: 189 }}></img>

            <div className="row">
              <div className="col-12">
                <a
                  role="button"
                  class="btn rounded-pill shadow-sm border border-1 me-2 py-2 ps-3 pe-4 mt-3"
                  download="qrcodezalominiapp"
                  style={{ backgroundColor: "white" }}
                  onClick={saveQRCode}
                >
                  <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path
                      d="M14 11.5159V13.7659H5V11.5159H3.5V13.7659C3.5 14.5909 4.175 15.2659 5 15.2659H14C14.825 15.2659 15.5 14.5909 15.5 13.7659V11.5159H14ZM13.25 8.51587L12.1925 7.45837L10.25 9.39337V3.26587H8.75V9.39337L6.8075 7.45837L5.75 8.51587L9.5 12.2659L13.25 8.51587Z"
                      fill="#1B1B1F"
                    />
                  </svg>
                  Tải xuống
                </a>

                <button
                  type="button"
                  class="btn rounded-pill shadow-sm border border-1 ms-2 py-2 ps-3 pe-4 mt-3"
                  style={{ backgroundColor: "white" }}
                  onClick={shareQRCode}
                >
                  <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.4775 12.8734C12.875 12.4984 13.4075 12.2659 14 12.2659C15.245 12.2659 16.25 13.2709 16.25 14.5159C16.25 15.7609 15.245 16.7659 14 16.7659C12.755 16.7659 11.75 15.7609 11.75 14.5159C11.75 14.3359 11.78 14.1634 11.8175 13.9984L6.53 10.9084C6.125 11.2834 5.5925 11.5159 5 11.5159C3.755 11.5159 2.75 10.5109 2.75 9.26587C2.75 8.02087 3.755 7.01587 5 7.01587C5.5925 7.01587 6.125 7.24837 6.53 7.62337L11.8175 4.54087C11.78 4.37587 11.75 4.19587 11.75 4.01587C11.75 2.77087 12.755 1.76587 14 1.76587C15.245 1.76587 16.25 2.77087 16.25 4.01587C16.25 5.26087 15.245 6.26587 14 6.26587C13.4075 6.26587 12.8675 6.03337 12.47 5.65837L7.1825 8.74087C7.22 8.91337 7.25 9.08587 7.25 9.26587C7.25 9.44587 7.22 9.61837 7.1825 9.79087L12.4775 12.8734ZM14.75 4.01587C14.75 3.60337 14.4125 3.26587 14 3.26587C13.5875 3.26587 13.25 3.60337 13.25 4.01587C13.25 4.42837 13.5875 4.76587 14 4.76587C14.4125 4.76587 14.75 4.42837 14.75 4.01587ZM5 10.0159C4.5875 10.0159 4.25 9.67837 4.25 9.26587C4.25 8.85337 4.5875 8.51587 5 8.51587C5.4125 8.51587 5.75 8.85337 5.75 9.26587C5.75 9.67837 5.4125 10.0159 5 10.0159ZM13.25 14.5159C13.25 14.9284 13.5875 15.2659 14 15.2659C14.4125 15.2659 14.75 14.9284 14.75 14.5159C14.75 14.1034 14.4125 13.7659 14 13.7659C13.5875 13.7659 13.25 14.1034 13.25 14.5159Z"
                      fill="#1B1B1F"
                    />
                  </svg>
                  Chia sẻ
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <p style={{ fontSize: 12, color: "gray" }}>
                Phát triển bởi <span style={{ fontWeight: "bold" }}>CubeLoyalty</span>
              </p>
            </div>
          </div>
        </div>

        <button
          type="button"
          class="btn border border-1 text-danger"
          style={{
            // width: "calc(100% - 32px)",
            borderRadius: 12,
            height: 40,
            width: "100%",
            // paddingTop: 10,
            // paddingBottom: 10,
            // position: "absolute",
            // bottom: 12,
          }}
          onClick={logOut}
        >
          Đăng xuất
        </button>
      </div>

      <ModalZaUI isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalConfig={modalConfig} />
    </div>
  );
}

export default Profile;
