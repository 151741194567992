import CartContext from "context/cart/CartContext";
import React, { useContext, useEffect } from "react";
import { colors } from "theme-config";
import { Text, useLocation } from "zmp-ui";
import { useNavigate } from "react-router-dom";

function CartButton({ style }) {
  const context = useContext(CartContext);
  const navigate = useNavigate();
  const location = window.location;

  const renderCart = () => {
    var arr = location.pathname.split("/");
    var check = arr[arr.length - 1];
    // console.log('posistion', check)

    if (check == "" || check == "Home" || check == "Profile" || check == "Point") {
      return (
        <div
          className="d-flex justify-content-center align-items-center shadow"
          style={{ ...style, backgroundColor: "white", width: 48, height: 48, borderRadius: 100 }}
          onClick={() => {
            navigate(`/${window.shop_sub_path}/Cart`);
          }}
        >
          <div className="d-flex justify-content-center align-items-center " style={{ position: "relative", width: "100%", height: "100%" }}>
            {context.itemCount > 0 && (
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ width: 14, height: 14, borderRadius: 7, background: colors.error, color: "white", position: "absolute", top: 8, right: 8 }}
              >
                <Text style={{ fontSize: 11 }}>{context.itemCount}</Text>
              </div>
            )}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="shopping-bag 1">
                <path
                  id="Vector"
                  d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                  stroke={colors.primary}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path id="Vector_2" d="M3 6H21" stroke={colors.primary} stroke-linecap="round" stroke-linejoin="round" />
                <path
                  id="Vector_3"
                  d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                  stroke={colors.primary}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </div>
        </div>
      );
    }
  };

  return <>{renderCart()}</>;
}

export default CartButton;
