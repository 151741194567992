import React, { useEffect, useState } from "react";
import { colors, assets, styles } from "theme-config/index";
import api from "api";
import ModalZaUI from "components/common/modal/ModalZaUI";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Skeleton from "react-loading-skeleton";
import NewsSkeleton from "components/news/NewsSkeleton";
import NewsCard from "components/news/NewsCard";
function NewsSlider({ lablel }) {
  const navigate = useNavigate();
  const [lstNews, setLstNews] = useState([]);
  const [isLoading, setLoading] = useState(false);
  //   const showListNews = () => {
  //     navigate("/ExploreList");
  //   };
  useEffect(() => {
    getNews();
  }, []);

  const getListNewsByToken = async (access_token) => {
    const response = await fetch("https://openapi.zalo.me/v2.0/article/getslice?offset=0&limit=10&type=normal", {
      method: "GET", // *GET, POST, PUT, DELETE, etc.

      headers: {
        access_token: access_token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      // body data type must match "Content-Type" header
    });
    return new Promise((resolve) => {
      resolve(response.json());
    });
  };
  const getNews = async () => {
    setLoading(true);
    var { oaId } = await getStorage({ keys: ["oaId"] });
    if (oaId) {
      api.post("guestlogin/getoaaccesstoken", { shop_name: window.shop_id, oa_id: oaId }).then(async (res) => {
        if (res.access_token) {
          var data = await getListNewsByToken(res.access_token);
          if (data.error === 0) {
            var newList = data.data.medias;
            if (newList.length > 0) {
              // console.log("newList", newList);
              setLstNews(newList);
              setLoading(false);
            } else {
              setLstNews([]);
              setLoading(false);
            }
          } else {
            setLstNews([]);
            setLoading(false);
          }
        }
      });
    }
    // api
    //   .post("guestlogin/getpublishblog", { shop_name: window.shop_id, query: { category: "all", pagging: { skip: 0, limit: 10 } } })
    //   .then((res) => {
    //     console.log(res);
    //     setLstNews(res);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (
    <>
      <div className="container-fluid p-2">
        <div className="d-flex justify-content-between mb-1">
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={styles.textOnPrimary}>{lablel}</span>
          </div>
          {/* <div
            onClick={showListNews}
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              fontSize: 16,
              height: 24,
              ...styles.textPrimary,
              fontSize: 12,
            }}
          >
            Xem tất cả
          </div> */}
        </div>
        <div className="row p-1">
          {isLoading ? (
            <Swiper
              slidesPerView={1.2}
              spaceBetween={4}
              freeMode={true}
              pagination={{
                clickable: false,
                renderBullet: function (index, className) {
                  return ""; // Return an empty string to hide the bullet
                },
              }}
              modules={[FreeMode, Pagination]}
            >
              <SwiperSlide>
                <NewsSkeleton mode={"swipper"} />
              </SwiperSlide>
              <SwiperSlide>
                <NewsSkeleton mode={"swipper"} />
              </SwiperSlide>
              <SwiperSlide>
                <NewsSkeleton mode={"swipper"} />
              </SwiperSlide>
            </Swiper>
          ) : (
            <Swiper
              slidesPerView={1.2}
              spaceBetween={4}
              freeMode={true}
              pagination={{
                clickable: false,
                renderBullet: function (index, className) {
                  return ""; // Return an empty string to hide the bullet
                },
              }}
              modules={[FreeMode, Pagination]}
            >
              {lstNews.map((item) => {
                return (
                  <SwiperSlide>
                    <NewsCard key={item.id} item={item} mode={"swipper"}></NewsCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
}

export default NewsSlider;
