import React, { useEffect, useState } from "react";
import api from "api";
import TopAppBar from "components/common/top_app_bar";
import { styles } from "theme-config";
import moment from "moment";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";

function MyAccount() {
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  useEffect(() => {
    api.get("guest/me").then((res) => {
      setUser(res);
    });
  }, []);

  return (
    <div style={{ height: "calc(100vh - 60px)" }}>
      <TopAppBar mode="back" title="Tài khoản của tôi" />

      <ul class="list-group rounded-0 mb-3">
        <li class="list-group-item border-0">
          <div class="d-flex w-100 justify-content-between">
            <text class="my-2">Họ và tên</text>
            <text class="my-2">{user.first_name && `${user.last_name}  ${user.first_name}`}</text>
          </div>
        </li>

        <li class="list-group-item border-0">
          <div class="d-flex w-100 justify-content-between">
            <text class="my-2">Số điện thoại</text>
            <text class="my-2">{user.phone}</text>
          </div>
        </li>

        <li class="list-group-item border-0">
          <div class="d-flex w-100 justify-content-between">
            <text class="my-2">Mã khách hàng</text>
            <text class="my-2 text-end">{user.id}</text>
          </div>
        </li>

        <li class="list-group-item border-0">
          <div class="d-flex w-100 justify-content-between">
            <text class="my-2">Ngày đăng ký</text>
            <text class="my-2">{user.created_on ? moment(user.created_on).format("DD/MM/YYYY") : "Chưa cập nhật"}</text>
          </div>
        </li>
      </ul>

      <ul class="list-group rounded-0">
        <li class="list-group-item border-0">
          <div class="d-flex w-100 justify-content-between">
            <text class="my-2">Ngày sinh</text>
            <text class="my-2">{user.birth_day ? moment(user.birth_day).format("DD/MM/YYYY") : "Chưa cập nhật"}</text>
          </div>
        </li>

        <li class="list-group-item border-0">
          <div class="d-flex w-100 justify-content-between">
            <text class="my-2">Giới tính</text>
            <text class="my-2">{user.gender ? (user.gender === "male" ? "Nam" : "Nữ") : ""}</text>
          </div>
        </li>

        <li class="list-group-item border-0">
          <div class="d-flex w-100 justify-content-between">
            <text class="my-2">Email</text>
            <text class="my-2">{user.email ? user.email : "Chưa cập nhật"}</text>
          </div>
        </li>
      </ul>

      <nav
        className="navbar fixed-bottom"
        style={{ ...styles.mainBackground, boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)" }}
      >
        <div className="row" style={{ gap: 8, padding: "4px 16px", margin: 0, width: "100%", fontSize: 12 }}>
          <button
            className="btn"
            style={{ ...styles.btnPrimary, borderRadius: 12, height: 40 }}
            onClick={() => navigate(`/${window.shop_sub_path}/EditAccount`)}
          >
            Chỉnh sửa
          </button>
        </div>
      </nav>
    </div>
  );
}

export default MyAccount;
