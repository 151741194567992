import React, { useState } from "react";
import { useEffect } from "react";
import { BottomNavigation, Icon, Page, Sheet, Box, Text, Button, Tabs, List } from "zmp-ui";
// import { province } from "js_data/Trust_Province";
// import { district } from "js_data/Trust_District";
// import { ward } from "js_data/Trust_Ward";
import { colors } from "theme-config";
import api from "api";
const { Item } = List;

function AddressPickerSheet({ sheetVisible, setSheetVisible, onChange, value }) {
  const [tabsList, setTabsList] = useState([]);
  const [activeKey, setActiveKey] = useState("province");
  const [address, setAddress] = useState({});
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [ward, setWard] = useState([]);
  // cons

  useEffect(() => {
    getProvince();
  }, []);

  useEffect(() => {
    if (province.length > 0) {
      if (value.province && value.province_code) {
        var tabs = [
          {
            key: "province",
            label: province.find((item) => {
              return item.ID === value.province_code;
            }).Name,
            content: province,
          },
        ];
        setTabsList(tabs);
        var newAddress = { ...address };
        newAddress.province = province.find((item) => {
          return item.ID === value.province_code;
        });
        setAddress(newAddress);
        // }
      } else {
        var tabs = [{ key: "province", label: "Tỉnh", content: province }];
        setTabsList(tabs);
      }
    }
  }, [province]);

  useEffect(() => {
    if (address.province) {
      getDistrictByProvince(address.province.ID);
    }
  }, [address.province]);

  useEffect(() => {
    if (address.district) {
      getWardByDistrict(address.district.ID);
    }
  }, [address.district]);

  useEffect(() => {
    if (district.length > 0) {
      var tabs = [...tabsList];
      var findDistrictTab = tabs.find((item) => {
        return item.key === "district";
      });
      if (findDistrictTab) {
        var findDistrictTabIndex = tabs.findIndex((item) => {
          return item.key === "district";
        });
        tabs[findDistrictTabIndex] = {
          key: "district",
          label:
            value.district &&
            value.district_code &&
            district.find((item) => {
              return item.ID === value.district_code;
            })
              ? value.district
              : "Quận",
          content: district,
        };
      } else {
        tabs.push({
          key: "district",
          label: value.district ? value.district : "Quận",
          content: district,
        });
      }
      if (
        !value.district ||
        (value.district &&
          !district.find((item) => {
            return item.ID === value.district_code;
          }))
      ) {
        var findWardTab = tabs.find((tabItem) => {
          return tabItem.key === "ward";
        });
        if (findWardTab) {
          var findWardTabIndex = tabs.findIndex((tabItem) => {
            return tabItem.key === "ward";
          });
          tabs.splice(findWardTabIndex, 1);
        }
      }
      setTabsList(tabs);
      if (value.district) {
        var newAddress = { ...address };
        newAddress.district = district.find((item) => {
          return item.ID === value.district_code;
        });
        setAddress(newAddress);
      }
      setActiveKey("district");
    }
  }, [district]);

  useEffect(() => {
    if (ward.length > 0) {
      var tabs = [...tabsList];
      var findWardTab = tabs.find((item) => {
        return item.key === "ward";
      });
      if (findWardTab) {
        var findWardTabIndex = tabs.findIndex((item) => {
          return item.key === "ward";
        });
        tabs[findWardTabIndex] = {
          key: "ward",
          label:
            value.ward &&
            ward.find((item) => {
              return item.ID === value.ward_code;
            })
              ? value.ward
              : "Phường",
          content: ward,
        };
      } else {
        tabs.push({
          key: "ward",
          label:
            value.ward &&
            value.ward_code &&
            ward.find((item) => {
              return item.ID === value.ward_code;
            })
              ? value.ward
              : "Phường",
          content: ward,
        });
      }
      setTabsList(tabs);
      setActiveKey("ward");
    }
  }, [ward]);

  const getProvince = () => {
    api
      .post("guestlogin/province")
      .then((res) => {
        setProvince(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDistrictByProvince = (provinceId) => {
    api
      .post("guestlogin/district", { provinceId: provinceId })
      .then((res) => {
        setDistrict(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getWardByDistrict = (districtId) => {
    api
      .post("guestlogin/ward", { districtId: districtId })
      .then((res) => {
        setWard(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (sheetVisible && tabsList.length > 0) {
      var activeLine = document.querySelector(".address-tabs .zaui-tabs-tabbar-active-line").style;
      //   console.log("activeLine", activeLine);
      activeLine.setProperty("--primary", colors.primary);
    }
  }, [sheetVisible, tabsList]);

  const selectItem = (record) => {
    var tabs = [...tabsList];

    //đổi tên tab bằng tên item chọn tương ứng
    var findIndex = tabs.findIndex((tabItem) => {
      return tabItem.key === activeKey;
    });
    tabs[findIndex].label = activeKey !== "province" ? record.Type + " " + record.Name : record.Name;
    var newAddress = { ...address };
    switch (activeKey) {
      case "province":
        newAddress.province = record;
        newAddress.district = null;
        newAddress.ward = null;
        setAddress(newAddress);
        onChange(newAddress);
        break;
      case "district":
        newAddress.district = record;
        newAddress.ward = null;
        setAddress(newAddress);
        onChange(newAddress);
        break;
      case "ward":
        newAddress.ward = record;
        setAddress(newAddress);
        onChange(newAddress);
        setSheetVisible(false);
        // setActiveKey("ward");
        break;
      default:
        break;
    }
  };

  return (
    <Page className="page">
      <Sheet className="custom-sheet" visible={sheetVisible} onClose={() => setSheetVisible(false)} autoHeight mask handler={false}>
        <Box className="custom-bottom-sheet" flex flexDirection="column" style={{ padding: "16px 0px" }}>
          <Text.Title style={{ textAlign: "center", fontSize: 16, fontWeight: 500, lineHeight: "24px" }}>Chưa có địa chỉ</Text.Title>
          <Box className="bottom-sheet-body" style={{ overflowY: "auto" }}>
            {tabsList.length > 0 && (
              <Tabs
                className="address-tabs"
                activeKey={activeKey}
                onChange={(e) => {
                  setActiveKey(e);
                }}
              >
                {tabsList.map((tabItem) => {
                  return (
                    <Tabs.Tab
                      style={{ padding: 0 }}
                      key={tabItem.key}
                      label={<Text style={{ color: tabItem.key === activeKey ? colors.primary : colors.onSurfaceVariant, fontSize: 14 }}>{tabItem.label}</Text>}
                    >
                      <Box style={{ overflowY: "scroll", height: "calc(65vh - 84px)", padding: "0px 16px" }}>
                        {tabItem.content.map((record) => {
                          return (
                            <Box
                              style={{
                                padding: "8px 12px",
                                background: value[`${tabItem.key}`] && value[`${tabItem.key}_code`] === record.ID ? colors.primary : "white",
                                color: value[`${tabItem.key}`] && value[`${tabItem.key}_code`] === record.ID ? colors.onPrimary : "black",
                              }}
                              onClick={() => {
                                selectItem(record);
                              }}
                            >
                              <Text style={{ fontSize: 14 }}>{parseInt(record.Name) > 0 ? record.Type + " " + record.Name : record.Name}</Text>
                            </Box>
                          );
                        })}
                      </Box>
                    </Tabs.Tab>
                  );
                })}
              </Tabs>
            )}
          </Box>
        </Box>
      </Sheet>
    </Page>
  );
}

export default AddressPickerSheet;
