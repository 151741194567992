import numeral from "numeral";
import React from "react";
import { useNavigate } from "react-router-dom";
import { colors } from "theme-config";
import { openWebview } from "zmp-sdk/apis";

function NewsCard({ item, position, mode = "list" }) {
  // console.log("newscard", item);
  const navigate = useNavigate();
  return (
    <div className={mode === "list" ? "col-6 p-1" : "col-12 p-1"} ref={position}>
      <div className="card" style={{ border: "unset", borderRadius: 8 }}>
        <div
          className="product-card"
          style={{
            width: "100%",
            height: "calc(50vw - 16px)",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            backgroundImage: `url(${item.thumb})`,
            backgroundSize: "cover",
            // backgroundPosition: "center",
          }}
          onClick={() =>
            openWebview({
              url: item.link_view,
              success: (res) => {
                // xử lý khi gọi api thành công
              },
              fail: (error) => {
                // xử lý khi gọi api thất bại
                console.log(error);
              },
            })
          }
        ></div>
        <div className="card-body d-flex p-2">
          <div className="d-flex flex-column" style={{ gap: 8, lineHeight: "20px", height: "44px", position: "relative", width: "100%" }}>
            <span
              className="card-text flex-fill"
              style={{
                fontSize: 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                fontWeight: 500,
              }}
              //   onClick={() => navigate(`/ProductDetail/${item.id}`)}
            >
              {item.title}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
