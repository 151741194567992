import React, { useState, useEffect } from "react";
import { colors, assets, styles } from "theme-config/index";
import { useParams } from "react-router-dom";
import api from "api";
import moment from "moment";
import { getStorage } from "common/zalo_storage/ZaloStorage";

const ListHistory = ({ applyHistoryDate }) => {
  const [pointLogo, setPointLogo] = useState();
  const [listHistoryPoint, setListHistoryPoint] = useState();
  const getData = async (history_date) => {
    await api
      .post("/guest/getcustomerpointhistoryfollowdate", { history_date: history_date })
      .then((data) => {
        setListHistoryPoint(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const convertPoint1000 = (NB) => {
    if (NB < 1000 && NB > -1000) {
      return NB;
    }
    if (NB < 1000000 && NB > -1000000) {
      return `${Math.floor(NB / 1000)}K`;
    } else {
      return `${Math.floor(NB / 1000000)}Tr`;
    }
  };
  useEffect(() => {
    // getStorage({
    //   keys: ["pointLogo"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { pointLogo } = data;
    //     if (pointLogo) {
    //       setPointLogo(pointLogo);
    //     }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    getPointLogo();
  }, []);

  const getPointLogo = async () => {
    try {
      var { pointLogo } = await getStorage({ keys: ["pointLogo"] });
      setPointLogo(pointLogo);
    } catch (error) {}
  };

  useEffect(() => {
    var history_date = { ...applyHistoryDate };
    if (history_date?.end && history_date?.start && history_date?.end !== "" && history_date?.start !== "") {
      history_date.end = moment(history_date.end).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();
      history_date.start = moment(history_date.start).utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format();
    }
    getData(history_date);
  }, [applyHistoryDate]);
  return (
    <>
      {listHistoryPoint && listHistoryPoint?.length > 0 ? (
        listHistoryPoint.map((item, key) => {
          return (
            <React.Fragment key={key}>
              <div className="container-fluid m-0 p-3 border-bottom" style={styles.bgWhite}>
                <div className="container-fluid p-0 row m-0" style={{ padding: 12, ...styles.textOnSurfaceNormal }}>
                  <div className="col-9 p-0">
                    <p className="m-0 p-0" style={{ fontWeight: 500 }}>
                      {item?.description}
                    </p>
                    <p className="m-0 p-0" style={{ fontSize: 12 }}>
                      {moment(item?.history_date).format("hh:ss DD/MM/YYYY")}
                    </p>
                  </div>
                  <div className="col-3 d-flex justify-content-end align-items-center flex-wrap p-0" style={{ gap: 4 }}>
                    <span style={item?.change_point >= 0 ? styles.textSuccess : styles.textError}>
                      {item?.type === "add" ? `+${convertPoint1000(item?.change_point)}` : convertPoint1000(item?.change_point)}
                    </span>
                    {pointLogo ? (
                      <img src={pointLogo} alt="" style={{ width: 16, height: 16, alignSelf: "center" }} />
                    ) : (
                      <svg style={{ marginTop: 2 }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Iconify/G">
                          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill={colors.primary} />
                          <path
                            id="Vector"
                            d="M12.9917 11.2318C12.4741 11.6453 11.8098 11.9536 11.0673 12.1249C10.1819 12.3436 9.24942 12.4514 8.31164 12.4437C7.33747 12.4584 6.37541 12.289 5.5248 11.9531C4.71689 11.6221 4.06985 11.1258 3.66899 10.5297C3.21454 9.84432 2.98642 9.09491 3.00189 8.33807V7.7505C2.97746 6.97973 3.19018 6.2144 3.62534 5.50745C3.99784 4.90833 4.6189 4.40329 5.40634 4.05911C6.23089 3.71479 7.17548 3.54069 8.1329 3.55667C9.58347 3.55667 10.7175 3.80149 11.5349 4.29114C12.3523 4.78078 12.8372 5.49227 12.9896 6.42553H10.5374C10.4251 5.9305 10.1778 5.56916 9.79337 5.33853C9.4089 5.10791 8.88312 4.99476 8.2098 4.99476C7.8281 4.98087 7.44775 5.03642 7.10503 5.15599C6.76231 5.27557 6.46868 5.45525 6.25216 5.67787C5.80535 6.13323 5.57675 6.81038 5.57259 7.70789V8.26018C5.57259 9.16797 5.81574 9.85347 6.30203 10.3167C6.78833 10.7799 7.50252 11.0115 8.44463 11.0115C9.39228 11.0115 10.0677 10.869 10.4709 10.5841V9.09312H8.18486V7.78134H13L12.9917 11.2318Z"
                            fill={colors.onPrimary}
                          />
                          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={colors.primary} />
                        </g>
                      </svg>
                    )}
                    {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.500244" width="15" height="15" rx="7.5" fill={colors.primary}/>
                                            <path d="M12.9917 11.2321C12.4741 11.6455 11.8098 11.9539 11.0673 12.1252C10.1819 12.3438 9.24942 12.4517 8.31164 12.4439C7.33747 12.4586 6.37541 12.2893 5.5248 11.9533C4.71689 11.6223 4.06985 11.1261 3.66899 10.5299C3.21454 9.84456 2.98642 9.09515 3.00189 8.33831V7.75074C2.97746 6.97997 3.19018 6.21464 3.62534 5.50769C3.99784 4.90858 4.6189 4.40354 5.40634 4.05935C6.23089 3.71504 7.17548 3.54093 8.1329 3.55692C9.58347 3.55692 10.7175 3.80174 11.5349 4.29138C12.3523 4.78102 12.8372 5.49251 12.9896 6.42577H10.5374C10.4251 5.93074 10.1778 5.5694 9.79337 5.33878C9.4089 5.10816 8.88312 4.995 8.2098 4.995C7.8281 4.98111 7.44775 5.03666 7.10503 5.15624C6.76231 5.27582 6.46868 5.45549 6.25216 5.67811C5.80535 6.13348 5.57675 6.81063 5.57259 7.70814V8.26042C5.57259 9.16822 5.81574 9.85371 6.30203 10.3169C6.78833 10.7801 7.50252 11.0117 8.44463 11.0117C9.39228 11.0117 10.0677 10.8693 10.4709 10.5843V9.09336H8.18486V7.78158H13L12.9917 11.2321Z" fill="white"/>
                                            <rect x="0.5" y="0.500244" width="15" height="15" rx="7.5" stroke={colors.primary}/>
                                        </svg> */}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })
      ) : (
        <>
          <div className="container-fluid d-flex flex-column justify-content-center align-items-center" style={{ height: "80vh" }}>
            <div className="d-flex justify-content-center p-0">
              <img src={assets.empty_bg.img} alt="Logo" style={{ width: 190, height: 170 }} />
            </div>
            <div className="fw-bold text-center" style={{ ...styles.textSecondary, fontSize: 20 }}>
              Không có lịch sử tích điểm tương ứng
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ListHistory;
