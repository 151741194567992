import React, { useState, useEffect } from "react";
import * as bootstrap from "bootstrap";
import { colors, styles } from "theme-config";
import moment from "moment";
import DatePicker from "react-datepicker";

function RangePicker({ value, onChange }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [modal, setModal] = useState();
  const [isToggle, setIsToggle] = useState(false);
  const [modalConfig, setModalConfig] = useState();
  useEffect(() => {
    const modal = new bootstrap.Modal(document.getElementById("dateRangePicker"));
    setModal(modal);
  }, []);

  useEffect(() => {
    if (modal) {
      if (modalConfig?.type === "dateRangePicker") {
        modal.toggle();
        if (modal._isShown === true) {
          document.getElementsByClassName("modal-backdrop")[1].style.backgroundColor = "transparent";

          setStartDate(value[0]);
          setEndDate(value[1]);
        }
      }
    }
  }, [isToggle, modal]);

  const showCalendar = () => {
    var newModalConfig = {
      type: "dateRangePicker",
      onOk: null,
    };
    setModalConfig(newModalConfig);
    setIsToggle(!isToggle);
  };

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div class="input-group">
        <input
          style={{ height: 48 }}
          type="text"
          class="form-control"
          placeholder="Chọn ngày"
          aria-describedby="basic-addon1"
          value={`${moment(value[0]).format("DD/MM/YYYY")} - ${moment(value[1]).format("DD/MM/YYYY")}`}
        />
        <span class="input-group-text" onClick={showCalendar}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.5 4H18.5V2H16.5V4H8.5V2H6.5V4H5.5C4.39 4 3.5 4.9 3.5 6V20C3.5 21.1 4.39 22 5.5 22H19.5C20.6 22 21.5 21.1 21.5 20V6C21.5 4.9 20.6 4 19.5 4ZM19.5 20H5.5V9H19.5V20ZM7 13C7 11.62 8.12 10.5 9.5 10.5C10.88 10.5 12 11.62 12 13C12 14.38 10.88 15.5 9.5 15.5C8.12 15.5 7 14.38 7 13Z"
              fill="#44474F"
            />
          </svg>
        </span>
      </div>

      <div class="modal fade-scale" id="dateRangePicker" tabindex="-1" aria-labelledby="dateRangePickerLabel" aria-hidden="true">
        <div class="modal-dialog" style={{ margin: 0, minHeight: "100%", display: "flex", alignItems: "end", maxWidth: "100vw" }}>
          <div
            class="modal-content"
            style={{
              background: "white",
              width: "100vw",
              margin: 0,
              marginTop: 64,
              borderTopLeftRadius: 24,
              borderTopRightRadius: 24,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,

              padding: "16px 16px 24px 16px",
            }}
          >
            <div class="d-flex flex-column" style={{ gap: 16 }}>
              <div className="align-self-center" style={{ padding: 16 }}>
                <div
                  style={{ width: 32, height: 4, background: colors.outline, opacity: 0.12, borderRadius: 8 }}
                  onClick={() => {
                    setIsToggle(!isToggle);
                  }}
                ></div>
              </div>
              <DatePicker selected={startDate} startDate={startDate} endDate={endDate} onChange={onChangeDate} selectsRange inline />
              <div className="d-flex justify-content-end align-items-center" style={{ gap: 8 }}>
                <button
                  className="btn"
                  style={{ ...styles.btnText, height: 40, color: colors.error }}
                  onClick={() => {
                    setIsToggle(!isToggle);
                  }}
                >
                  Huỷ
                </button>
                <button
                  className="btn"
                  style={{ ...styles.btnText, height: 40, color: colors.primary }}
                  onClick={() => {
                    var newValue = [startDate, endDate];
                    onChange(newValue);
                    setIsToggle(!isToggle);
                  }}
                >
                  Áp dụng
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RangePicker;
