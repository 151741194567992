import React from "react";
import TopAppBar from "../top_app_bar";
import { useLocation } from "react-router-dom";
import { Box, Text, Input, Icon, Button, Switch } from "zmp-ui";
import AddressPickerSheet from "./AddressPickerSheet";
import { useState } from "react";
import { styles } from "theme-config";
import { useContext } from "react";
import CartContext from "context/cart/CartContext";
import api from "api";
import ModalZaUI from "../modal/ModalZaUI";
import { useEffect } from "react";
import { getStorage } from "common/zalo_storage/ZaloStorage";
import { useNavigate } from "react-router-dom";

function AddressForm() {
  // const context = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();
  const mode = location.state.mode;
  const [sheetVisible, setSheetVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [validateForm, setValidateForm] = useState({});
  const [shippingAddress, setShippingAddress] = useState({
    name: "",
    phone: "",
    address: "",
    province: "",
    province_code: "",
    district: "",
    district_code: "",
    ward: "",
    ward_code: "",
    is_default: false,
  });
  const context = useContext(CartContext);

  useEffect(() => {
    if (location.state.addressSelected) {
      setShippingAddress(location.state.addressSelected);
    }
  }, []);

  const onChangeAddressPicker = (value) => {
    var newValue = { ...shippingAddress };
    if (value.province && value.district && value.ward) {
      newValue.province = value.province.Type + " " + value.province.Name;
      newValue.province_code = value.province.ID;
      newValue.district = value.district.Type + " " + value.district.Name;
      newValue.district_code = value.district.ID;
      newValue.ward = value.ward.Type + " " + value.ward.Name;
      newValue.ward_code = value.ward.ID;
    }
    setShippingAddress(newValue);
  };

  const onChangeInput = (e, name) => {
    var newValue = { ...shippingAddress };
    newValue[name] = e.target.value;
    setShippingAddress(newValue);
  };

  const onChangeSwitch = () => {
    var newValue = { ...shippingAddress };
    newValue["is_default"] = !shippingAddress["is_default"];
    setShippingAddress(newValue);
  };

  const renderValueAddressPicker = () => {
    if (shippingAddress.province && shippingAddress.district && shippingAddress.ward) {
      var res = `${shippingAddress.province} - ${shippingAddress.district} - ${shippingAddress.ward}`;
      return res;
    } else {
      return null;
    }
  };

  const submit = async () => {
    var newValidate = { ...validateForm };
    var arr = [];
    Object.keys(shippingAddress).forEach((key) => {
      if ((!shippingAddress[key] || shippingAddress[key] === "") && !["is_default", "updated_date"].includes(key)) {
        // console.log(key);
        newValidate[key] = "error";
        arr.push("error");
      } else {
        newValidate[key] = "success";
      }
    });
    setValidateForm(newValidate);
    try {
      var { token } = await getStorage({ keys: ["token"] });
      if (token) {
        if (
          !arr.find((item) => {
            return item === "error";
          })
        ) {
          api.post("guest/saveshippingaddress", shippingAddress).then((res) => {
            if (res) {
              showSuccess();
            }
          });
        }
      } else {
        //hiện modal xin phép cấp quyền
        // confirmZalo(value);
        if (
          !arr.find((item) => {
            return item === "error";
          })
        ) {
          context.addShippingAddress(shippingAddress);
          navigate(`/${window.shop_sub_path}/Checkout`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showSuccess = () => {
    //hiện modal
    var modal = {
      type: "success",
      description: "Thêm địa chỉ mới hoàn tất",
      async onOk() {
        // removeStorage({
        //   keys: ["token", "zalo_id"],
        //   success: (data) => {
        //     // xử lý khi gọi api thành công
        //     const { errorKeys } = data;
        //     closeApp({});
        //   },
        //   fail: (error) => {
        //     // xử lý khi gọi api thất bại
        //     console.log(error);
        //   },
        // });
        navigate(-1);
      },
    };
    setModalConfig(modal);
    setModalVisible(true);
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <TopAppBar mode="back" title={mode === "ADD" ? "Thêm địa chỉ" : "Chỉnh sửa địa chỉ"} />
      <Box flex flexDirection="column" style={{ gap: 8, padding: "8px 0px 72px 0px" }}>
        <Box flex flexDirection="column" p={2} style={{ background: "white", gap: 8 }}>
          <Text style={{ fontSize: 16, fontWeight: 500, lineHeight: "24px" }}>Thông tin liên hệ</Text>
          <Input
            type="text"
            placeholder="Họ tên người nhận"
            label="Họ tên"
            value={shippingAddress.name}
            onChange={(e) => {
              onChangeInput(e, "name");
            }}
            status={!validateForm.name || validateForm.name === "success" ? "success" : "error"}
            errorText="Vui lòng nhập họ tên người nhận"
          />
          <Input
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            // pattern="[0-9]*"
            placeholder="Số điện thoại"
            label="Số điện thoại"
            value={shippingAddress.phone}
            onChange={(e) => {
              onChangeInput(e, "phone");
            }}
            status={!validateForm.phone || validateForm.phone === "success" ? "success" : "error"}
            errorText="Vui lòng nhập số điện thoại người nhận"
          />
        </Box>
        <Box flex flexDirection="column" p={2} style={{ background: "white", gap: 8 }}>
          <Text style={{ fontSize: 16, fontWeight: 500, lineHeight: "24px" }}>Thông tin địa chỉ</Text>
          <Input
            type="text"
            placeholder="Địa chỉ"
            label="Số nhà, tên đường"
            value={shippingAddress.address}
            onChange={(e) => {
              onChangeInput(e, "address");
            }}
            status={!validateForm.address || validateForm.address === "success" ? "success" : "error"}
            errorText="Vui lòng nhập địa chỉ người nhận"
          />
          <div
            style={{ position: "relative" }}
            onClick={() => {
              setSheetVisible(true);
            }}
          >
            <Input
              type="text"
              placeholder="Chọn tỉnh thành"
              label="Tỉnh thành / Quận Huyện"
              readOnly
              value={renderValueAddressPicker()}
              status={!validateForm.province || validateForm.province === "success" ? "" : "error"}
              errorText="Vui lòng chọn tỉnh thành quận huyện"
            />
            <Icon icon="zi-chevron-down" size={24} style={{ position: "absolute", right: 12, top: 38 }} />
          </div>
        </Box>

        <Box flex flexDirection="column" p={2} style={{ background: "white", gap: 8 }}>
          <Text style={{ fontSize: 16, fontWeight: 500, lineHeight: "24px" }}>Địa chỉ mặc định</Text>
          <Switch checked={shippingAddress.is_default} onChange={onChangeSwitch} />
        </Box>
      </Box>

      {sheetVisible && (
        <AddressPickerSheet sheetVisible={sheetVisible} setSheetVisible={setSheetVisible} value={shippingAddress} onChange={onChangeAddressPicker} />
      )}
      {modalVisible && <ModalZaUI modalConfig={modalConfig} isModalOpen={modalVisible} setIsModalOpen={setModalVisible} />}
      <Box className="fixed-bottom bg-white bottom-navigation-cart" flex flexDirection="column" p={2} style={{ zIndex: 1 }}>
        <Button style={{ ...styles.btnBottomPrimary }} onClick={submit}>
          Lưu
        </Button>
      </Box>
    </div>
  );
}

export default AddressForm;
