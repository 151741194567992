const setStorage = (item) => {
  if (Object.keys(item.data).length > 0) {
    Object.keys(item.data).forEach((key) => {
      localStorage.setItem(`${window.shop_id}_${key}`, item.data[key]);
    });

    // setStorage({
    //   data: data,
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(`setStorageError`, error);
    //   },
    // });
    return new Promise((resolve) => {
      resolve({ errorKeys: false });
    });
  } else {
    return new Promise((resolve) => {
      resolve({ errorKeys: true });
    });
  }
};

const getStorage = (item) => {
  var data = {};
  if (item.keys.length > 0) {
    item.keys.forEach((key) => {
      data[key] = localStorage.getItem(`${window.shop_id}_${key}`);
    });
  }

  // getStorage({
  //   keys: keys,
  //   success: (res) => {
  //     // xử lý khi gọi api thành công
  //     data = res;
  //   },
  //   fail: (error) => {
  //     // xử lý khi gọi api thất bại
  //     console.log(error);
  //   },
  // });
  return new Promise((resolve) => {
    resolve(data);
  });
};

const removeStorage = (item) => {
  if (item.keys.length > 0) {
    item.keys.forEach((key) => {
      localStorage.removeItem(`${window.shop_id}_${key}`);
    });
    return new Promise((resolve) => {
      resolve({ errorKeys: false });
    });
  } else {
    return new Promise((resolve) => {
      resolve({ errorKeys: false });
    });
  }
};

export { setStorage, getStorage, removeStorage };
