import TopAppBar from "components/common/top_app_bar";
import React, { useEffect, useState } from "react";
import { colors, assets, styles } from "theme-config";
import api from "api";
import moment from "moment";
import VoucherDetailModal from "./VoucherDetailModal";
import VoucherItem from "./VoucherItem";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";

function VoucherList() {
  const navigate = useNavigate();
  const [lstVoucher, setLstVoucher] = useState([]);
  const [isToggleModal, setIsToggleModal] = useState(false);
  const [modalConfig, setModalConfig] = useState();
  useEffect(() => {
    getLstVoucher();
  }, []);
  const getLstVoucher = () => {
    api.post("guest/getlstvoucher", { used: false, pagging: { skip: 0, limit: 1000 } }).then((res) => {
      setLstVoucher(res.list);
      // console.log(res.list);
    });
  };

  const expiryDate = (dateExpiry) => {
    var a = moment(dateExpiry);
    var b = moment();
    var numberDate = a.diff(b, "days");
    if (numberDate > 0) return `Hết hạn trong ${numberDate} ngày`;
    var numberHour = a.diff(b, "hours", true);
    if (numberHour > 0) return `Hết hạn trong ${numberHour} ngày`;
    return "Hết hạn";
  };

  const showDetail = (item) => {
    var modal = {
      type: "voucherDetail",
      onOk: null,
      voucherId: item.voucher_id,
      width: document.getElementById("voucherList").offsetWidth,
    };
    setModalConfig(modal);
    setIsToggleModal(!isToggleModal);
  };

  return (
    <div id="voucherList" style={{ minHeight: "100vh", ...styles.mainBackground }}>
      <TopAppBar mode="back" title="Ưu đãi của tôi" />
      {lstVoucher && lstVoucher.length > 0 ? (
        <div style={{ padding: "16px 8px", gap: 12, display: "grid" }}>
          {lstVoucher.map((item, index) => {
            return <VoucherItem item={item} showDetail={showDetail} />;
          })}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center" style={{ height: "calc(100vh - 50.5px)" }}>
          <div className="d-flex flex-column">
            <img style={{ width: 248, height: 248 }} src={assets.empty_bg.img} className="rounded mx-auto d-block" alt="..." />
            <text className="text-center" style={{ fontSize: 22, fontWeight: 400 }}>
              Chưa có ưu đãi nào
            </text>
            <p className="text-center" style={{ fontWeight: 500, color: colors.secondary }}>
              Hãy đổi điểm để nhận ưu đãi ngay nhé !!!
            </p>
          </div>
        </div>
      )}
      <VoucherDetailModal isToggle={isToggleModal} modalConfig={modalConfig} setIsToggle={setIsToggleModal} />
    </div>
  );
}

export default VoucherList;
