import React, { useState, useEffect } from "react";
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import { colors, assets, styles } from "theme-config/index";
import api from "api";
import ModalZaUI from "components/common/modal/ModalZaUI";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import ProductCard from "components/product/ProductCard";
import ProductSkeleton from "components/product/ProductSkeleton";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Pagination } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Skeleton from "react-loading-skeleton";

const ProductCatSwiper = ({ category_id, lablel }) => {
  const navigate = useNavigate();
  const [lstProduct, setlstProduct] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    //getCategory();
    getProduct();
  }, []);

  const getProduct = () => {
    setLoading(true);
    api
      .post("guestlogin/getlistproduct", { shop_name: window.shop_id, category_id: category_id, skip: 0 })
      .then((res) => {
        setlstProduct(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showListProduct = async () => {
    var data = {
      currentKeyProduct: category_id,
    };
    await setStorage({ data });
    navigate({
      pathname: `/${window.shop_sub_path}/ProductList`,
      search: `catid=${category_id}`,
    });
  };

  return (
    <>
      <div className="container-fluid p-2">
        <div className="d-flex justify-content-between mb-1">
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={styles.textOnPrimary}>{lablel}</span>
          </div>
          <div
            onClick={showListProduct}
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              fontSize: 16,
              height: 24,
              ...styles.textPrimary,
              fontSize: 12,
            }}
          >
            Xem tất cả
          </div>
        </div>
        <div className="row p-1">
          {isLoading ? (
            <Swiper
              slidesPerView={2.1}
              spaceBetween={4}
              freeMode={true}
              pagination={{
                clickable: false,
                renderBullet: function (index, className) {
                  return ""; // Return an empty string to hide the bullet
                },
              }}
              modules={[FreeMode, Pagination]}
            >
              <SwiperSlide>
                <ProductSkeleton mode={"swipper"} />
              </SwiperSlide>
              <SwiperSlide>
                <ProductSkeleton mode={"swipper"} />
              </SwiperSlide>
              <SwiperSlide>
                <ProductSkeleton mode={"swipper"} />
              </SwiperSlide>
            </Swiper>
          ) : (
            <Swiper
              slidesPerView={2.1}
              spaceBetween={4}
              freeMode={true}
              pagination={{
                clickable: false,
                renderBullet: function (index, className) {
                  return ""; // Return an empty string to hide the bullet
                },
              }}
              modules={[FreeMode, Pagination]}
            >
              {lstProduct.map((product) => {
                return (
                  <SwiperSlide>
                    <ProductCard key={product.id} item={product} mode={"swipper"}></ProductCard>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </div>
      </div>
    </>
  );
};
export default ProductCatSwiper;
