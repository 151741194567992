import { getStorage } from "common/zalo_storage/ZaloStorage";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Page, Text, Box, Button, Sheet } from "zmp-ui";
import { colors } from "theme-config";

function PolicySheet({ sheetVisible, setSheetVisible, onSubmit }) {
  const [logo, setLogo] = useState();
  const [shopName, setShopName] = useState();

  useEffect(() => {
    getDataFromStorage();
  }, []);

  const getDataFromStorage = async () => {
    var { logo, shopName } = await getStorage({ keys: ["logo", "shopName"] });
    setLogo(logo);
    setShopName(shopName);
  };

  return (
    <Page className="section-container">
      <Sheet visible={sheetVisible} onClose={() => setSheetVisible(false)} autoHeight mask swipeToClose>
        <Box p={4} className="custom-bottom-sheet" flex flexDirection="column">
          <Box
            style={{
              width: 48,
              height: 48,
              borderRadius: 8,
              border: `0.6px solid ${colors.outlineVariant}`,
              backgroundImage: `url(${logo})`,
              backgroundPosition: "center",
              backgroundSize: 40,
              backgroundRepeat: "no-repeat",
              justifySelf: "center",
              alignSelf: "center",
            }}
          ></Box>
          <Box my={4} style={{ justifySelf: "self-start" }}>
            <Text style={{ fontSize: 16, fontWeight: 500 }}>Kết nối tài khoản để sử dụng tính năng</Text>
          </Box>
          <Box className="bottom-sheet-body" pb={3} style={{ overflowY: "auto" }}>
            <Text style={{ fontSize: 14, color: colors.outline }}>
              Cho phép <span style={{ fontWeight: 500 }}>{shopName}</span> xác định số điện thoại để sử dụng đầy đủ các tính năng tích điểm, nhận voucher, săn
              thưởng
            </Text>
          </Box>
          <Box flex flexDirection="row" mt={1}>
            <Box style={{ flex: 1 }} pl={1}>
              <Button
                style={{ background: colors.primary, color: "white" }}
                fullWidth
                onClick={() => {
                  onSubmit();
                }}
              >
                Đồng ý
              </Button>
            </Box>
          </Box>
        </Box>
      </Sheet>
    </Page>
  );
}

export default PolicySheet;
