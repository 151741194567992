import React, { useEffect, useState } from "react";
import api from "api";
import WheelOfFortune from ".";
import { useLocation, useParams } from "react-router-dom";
import OpenGiftBox from ".";

function OpenGiftBoxWrapper() {
  const { id } = useParams();
  const [configData, setConfigData] = useState();
  const getGameProgram = () => {
    api
      .post("guestlogin/getgameprogrambyid", { shop_name: window.shop_id, game_id: id })
      .then((res) => {
        console.log(res);
        if (res) {
          setConfigData(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getGameProgram();
  }, []);
  return <>{configData && <OpenGiftBox data={configData} />}</>;
}

export default OpenGiftBoxWrapper;
