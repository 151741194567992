import React from 'react';
import TopAppBar from "components/common/top_app_bar";

function Setting() {
  return (
    <div style={{ height: "calc(100vh - 60px)" }}>
      <TopAppBar mode="back" title="Cài đặt" />
      Cài đặt
    </div>
  )
}

export default Setting