import TopAppBar from "components/common/top_app_bar";
import React, { useEffect, useState } from "react";
import { Box, Text } from "zmp-ui";
import api from "api";
import { useLocation } from "react-router-dom";
import { assets, colors } from "theme-config";
import { followOA, getAppInfo, getUserInfo, login, openPostFeed, openShareSheet } from "zmp-sdk/apis";
import ModalZaUI from "components/common/modal/ModalZaUI";
import { getStorage } from "common/zalo_storage/ZaloStorage";

function ExchangeTurn() {
  const location = useLocation();
  const [listAddTurn, setListAddTurn] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [modalConfig, setModalConfig] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getListAddTurn = () => {
    api
      .post("guest/getlistaddturn", { game_id: location.state.game_id })
      .then((res) => {
        console.log(res);
        // var arr = [
        //   {
        //     type: "share_to_chat",
        //     name: "Chia sẻ trò chơi",
        //     // points: 3,
        //     turns: 1,
        //     max_change_times: 1,
        //     id: "215aae5b-9f4f-4825-b091-405df8028feb",
        //     customer_exchanged_turn: 0,
        //   },
        //   {
        //     type: "share_to_feed",
        //     name: "Đăng trò chơi lên nhật ký",
        //     // points: 3,
        //     turns: 1,
        //     max_change_times: 1,
        //     id: "b8647d31-f44b-4ff9-b51a-ac9ed9e52cc2",
        //     customer_exchanged_turn: 0,
        //   },
        // ];
        setListAddTurn(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUser = async () => {
    try {
      const { userInfo } = await getUserInfo({});
      if (userInfo) {
        setUserInfo(userInfo);
      }
    } catch (error) {
      // xử lý khi gọi api thất bại
      console.log(error);
    }
  };

  useEffect(() => {
    getListAddTurn();
    getUser();
  }, []);

  const addTurn = async (item) => {
    if (item.customer_exchanged_turn < item.max_change_times) {
      switch (item.type) {
        case "subscribezalo":
          var { oaId } = await getStorage({ keys: ["oaId"] });
          api
            .post("guestlogin/getoaaccesstoken", { shop_name: window.shop_id, oa_id: oaId })
            .then((res) => {
              checkFollowOA(res.access_token, item);
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        case "point":
          showInfo(`Bạn có muốn đổi ${item.points} điểm để lấy ${item.turns} lượt chơi không?`, item);
          break;
        case "share_to_chat":
          console.log(location.state);
          login({
            success: async () => {
              try {
                var keys = ["queryParam"];
                var { queryParam } = await getStorage({ keys: keys });
              } catch (error) {}
              openShareSheet({
                // type: "link",
                // data: {
                //   link: "https://zalo.me/s/1341096052640481341/",
                //   chatOnly: false,
                // },
                type: "zmp",
                data: {
                  title: location.state.game_title,
                  description: "Tham gia trò chơi trúng thưởng",
                  thumbnail: location.state.game_img,
                  path: `/Game/${location.state.game_type}/${location.state.game_id}` + queryParam,
                },
                success: (data) => {
                  if (data.status === 2) {
                    exchangeTurn(item);
                  }
                },
                fail: (err) => {
                  console.log("openShareSheetError:", err);
                },
              });
            },
            fail: (error) => {
              // login thất bại
              console.log(error);
            },
          });
          break;
        case "share_to_feed":
          login({
            success: async () => {
              try {
                var keys = ["queryParam"];
                var { queryParam } = await getStorage({ keys: keys });
              } catch (error) {}
              // openShareSheet({
              //   // type: "link",
              //   // data: {
              //   //   link: "https://zalo.me/s/1341096052640481341/",
              //   //   chatOnly: false,
              //   // },
              //   type: "zmp",
              //   data: {
              //     title: location.state.game_title,
              //     description: "Tham gia trò chơi trúng thưởng",
              //     thumbnail: location.state.game_img,
              //     path: `/Game/${location.state.game_type}/${location.state.game_id}` + queryParam,
              //   },
              //   success: (data) => {
              //     if (data.status === 2) {
              //       exchangeTurn(item);
              //     }
              //   },
              //   fail: (err) => {
              //     console.log("openShareSheetError:", err);
              //   },
              // });
              getAppInfo({
                success: (data) => {
                  // xử lý khi gọi api thành công
                  const { name, version, appUrl } = data;
                  openPostFeed({
                    type: "link",
                    data: {
                      link: appUrl + `Game/${location.state.game_type}/${location.state.game_id}` + queryParam,
                      title: location.state.game_title,
                      thumb: location.state.game_img,
                      description: "Tham gia trò chơi trúng thưởng",
                    },
                    success: (data) => {
                      if (data.status > 0) {
                        exchangeTurn(item);
                      }
                    },
                    fail: (err) => {},
                  });
                },
                fail: (error) => {
                  // xử lý khi gọi api thất bại
                  console.log(error);
                },
              });
            },
            fail: (error) => {
              // login thất bại
              console.log(error);
            },
          });
          break;

        default:
          break;
      }
    }
  };

  const checkFollowOA = async (access_token, item) => {
    // console.log("checkFollowOA");
    api
      .post("guestlogin/checkfollow", { zalo_id: userInfo.id, access_token: access_token })
      .then((res) => {
        if (res) {
          exchangeTurn(item);
          showSuccess("Cám ơn bạn đã quan tâm OA chúng tôi. Phần thưởng đã được thêm vào tài khoản của bạn");
          // checkLastDisplayModal();
        }
      })
      .catch((err) => {
        followOA({
          id: item.zalo_id,
          success: (res) => {
            // showSuccess()
            exchangeTurn(item);
            showSuccess("Cám ơn bạn đã quan tâm OA chúng tôi. Phần thưởng đã được thêm vào tài khoản của bạn");
          },
          fail: (err) => {
            console.log(err);
            if (err.code === -203) {
              showError("Bạn đã thử quá số lần quy định của ZALO. Vui lòng khởi động lại app để thử lại");
            }
          },
        });
      });
  };

  const exchangeTurn = (item) => {
    api
      .post("guest/exchangegameturn", { id: location.state.game_id, exchange_turn_id: item.id, userId: userInfo.id })
      .then((res) => {
        if (item.type === "point") {
          showSuccess(`Bạn đã đổi +${item.turns} lượt chơi`);
        } else if (item.type === "share_to_feed" || item.type === "share_to_chat") {
          showSuccess(`Bạn đã đổi +${item.turns} lượt chơi`);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.code === -3) {
          showError("Bạn không còn đủ số điểm để đổi lượt chơi. Hãy tham gia mua sắm để có đủ điểm nhé");
        } else {
          showError(err.message);
        }
      });
  };

  const showError = (message) => {
    var modal = {
      type: "error",
      description: message,
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  const showInfo = (message, item) => {
    var modal = {
      type: "info",
      description: message,
      cancelText: "Thoát",
      okText: "Xác nhận",
      onOk() {
        // createVoucher({ id: id });
        exchangeTurn(item);
      },
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  // useEffect(() => {
  //   if (modalConfig?.type === "success" && !isModalOpen) {
  //     getListAddTurn();
  //   }
  // }, [isModalOpen]);

  const showSuccess = (message) => {
    var modal = {
      type: "success",
      description: message,
      onOk() {
        getListAddTurn();
      },
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };
  return (
    <div>
      <TopAppBar mode="back" title="Nhiệm vụ" />
      <Box flex flexDirection="column">
        {listAddTurn && listAddTurn.length > 0 ? (
          listAddTurn.map((item) => {
            return (
              <Box
                flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ borderBottom: `1px solid ${colors.outlineVariant}`, padding: "8px 16px", background: "white" }}
                onClick={() => {
                  addTurn(item);
                }}
              >
                <Box flex flexDirection="column" style={{ height: 60 }}>
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>{`${item.name} (${item.customer_exchanged_turn}/${item.max_change_times})`}</Text>
                  <Text
                    style={{
                      fontSize: 14,
                      color: colors.outline,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >{`${item.description ? item.description : ""}`}</Text>
                </Box>
                <Box flex flexDirection="column" style={{ minWidth: 96 }}>
                  <Text style={{ fontSize: 14, fontWeight: 500, color: colors.primary, textAlign: "end" }}>
                    {item.customer_exchanged_turn < item.max_change_times ? `+ ${item.turns} lượt chơi` : "Hoàn tất"}
                  </Text>
                </Box>
              </Box>
            );
          })
        ) : (
          <Box flex flexDirection="row" alignItems="center" justifyContent="center" style={{ height: "calc(100vh - 50.5px)" }}>
            <Box flex flexDirection="column">
              <img style={{ width: 248, height: 248 }} src={assets.empty_bg.img} className="rounded mx-auto d-block" alt="..." />
              <Text className="text-center" style={{ fontSize: 16, fontWeight: 500 }}>
                Chưa có nhiệm vụ nào
              </Text>
              {/* <Text className="text-center" style={{ fontWeight: 500, color: colors.secondary }}>
              Hãy đổi điểm để nhận ưu đãi ngay nhé !!!
            </Text> */}
            </Box>
          </Box>
        )}
      </Box>
      <ModalZaUI isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalConfig={modalConfig} />
    </div>
  );
}

export default ExchangeTurn;
