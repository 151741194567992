import TopAppBar from "components/common/top_app_bar";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { styles } from "theme-config";
import api from "api";

function GameInfo() {
  const location = useLocation();

  const [detail, setDetail] = useState();
  useEffect(() => {
    getGameDescription();
  }, []);

  const getGameDescription = () => {
    api.post("guest/getgamedescription", { game_id: location.state.game_id }).then((res) => {
      setDetail(res);
    });
  };
  return (
    <div style={{ height: "100vh", ...styles.mainBackground }}>
      <TopAppBar mode="back" title="Thể lệ game" />
      {/* <div className="container-fluid"><h1 style={styles.texHeaderExplore}>{detail?.title}</h1></div> */}
      {detail && detail.image && (
        <div style={{ width: "100vw", height: "calc(100vw / 16 * 9)", backgroundImage: `url(${detail?.image})`, backgroundSize: "cover" }}></div>
      )}
      <div
        // id="blog-content"
        className="container-fluid pt-2 pb-5"
        dangerouslySetInnerHTML={{ __html: detail?.description.replace(/<a\b[^>]*>(.*?)<\/a>/gi, "<span>$1</span>") }}
        style={{ ...styles.textBlack, fontSize: 14, overflowY: "auto" }}
      ></div>
    </div>
  );
}

export default GameInfo;
