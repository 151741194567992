import React, { useState } from "react";
import TopAppBar from "../top_app_bar";
import api from "api";
import { useEffect } from "react";
import { Box, Text } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import AddressPicker from "../address_picker";
import { colors } from "theme-config";
import { useContext } from "react";
import CartContext from "context/cart/CartContext";

function AddressList() {
  const [addressList, setAddressList] = useState([]);
  const context = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    getAddressList();
  }, []);

  const getAddressList = () => {
    api
      .post("guest/getlistaddress")
      .then((res) => {
        setAddressList(res);
      })
      .catch((err) => {
        setAddressList([]);
      });
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <TopAppBar mode="back" title="Địa chỉ của bạn" />
      <Box flex flexDirection="column" pb={2} pt={2} style={{ gap: 8 }}>
        <AddressPicker />
        {addressList.length > 0 &&
          addressList.map((item) => {
            // console.log(item);
            return (
              <Box flex flexDirection="row" p={2} style={{ background: "white" }} onClick={() => {}} alignItems="center">
                <Box
                  flex
                  flexDirection="column"
                  style={{ width: "calc(100% - 64px)" }}
                  onClick={() => {
                    context.addShippingAddress(item);
                    navigate(-1);
                  }}
                >
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>{item.name}</Text>
                  <Text style={{ fontSize: 12, color: colors.outline }}>{item.phone}</Text>
                  <Text style={{ fontSize: 12, color: colors.outline }}>
                    {item.address}, {item.ward}, {item.district}, {item.province}
                  </Text>
                  {item.is_default && (
                    <Text
                      style={{
                        fontSize: 11,
                        color: colors.primary,
                        fontWeight: 500,
                        // background: colors.primaryContainer,
                        border: `1px solid ${colors.primary}`,
                        width: "fit-content",
                        padding: "0px 4px",
                        marginTop: 4,
                      }}
                    >
                      Mặc định
                    </Text>
                  )}
                </Box>
                <Box
                  flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ width: 64, height: 32 }}
                  onClick={() => {
                    navigate(`/${window.shop_sub_path}/AddressForm`, { state: { mode: "UPDATE", addressSelected: item } });
                  }}
                >
                  <Text style={{ fontSize: 11, fontWeight: 500, color: colors.primary }}>Chỉnh sửa</Text>
                </Box>
              </Box>
            );
          })}
      </Box>
    </div>
  );
}

export default AddressList;
