import { REMOVE_ITEM, ADD_TO_CART, INCREASE, DECREASE, CHECKOUT, CLEAR, UPDATE_TO_CART, ADD_SHIPPING_ADDRESS, ADD_VOUCHER } from "./CartTypes.js";
import { setStorage } from "common/zalo_storage/ZaloStorage.js";
// Save the cartItems to local storage
const Storage = async (cartItems) => {
  //   localStorage.setItem("cartItems", JSON.stringify(cartItems.length > 0 ? cartItems : []));
  var data = { cartItems: JSON.stringify(cartItems.length > 0 ? cartItems : []) };
  var { errorKeys } = await setStorage({ data });
};

const setAddress = async (shippingAddress) => {
  var data = { shippingAddress: JSON.stringify(shippingAddress) ? JSON.stringify(shippingAddress) : {} };
  var { errorKeys } = await setStorage({ data });
};

const setVoucher = async (voucher) => {
  var data = { voucher: JSON.stringify(voucher) ? JSON.stringify(voucher) : {} };
  var { errorKeys } = await setStorage({ data });
};

// Export function to calculate the total price of the cart and the total quantity of the cart
export const sumItems = (cartItems) => {
  Storage(cartItems);
  let itemCount = cartItems.length > 0 ? cartItems.reduce((total, product) => total + product.quantity, 0) : 0;
  let total = cartItems.reduce((total, product) => total + product.sale_price * product.quantity, 0).toFixed(2);
  return { itemCount, total };
};

// The reducer is listening for an action, which is the type that we defined in the CartTypes.js file
const CartReducer = (state, action) => {
  // The switch statement is checking the type of action that is being passed in
  switch (action.type) {
    // If the action type is ADD_TO_CART, we want to add the item to the cartItems array
    case ADD_TO_CART:
      if (!state.cartItems.find((item) => item.variant_id === action.payload.variant_id)) {
        state.cartItems.push({
          ...action.payload,
          //   quantity: 1,
        });
      } else {
        var cartItem = state.cartItems.find((item) => {
          return item.variant_id === action.payload.variant_id;
        });
        state.cartItems[state.cartItems.findIndex((item) => item.variant_id === action.payload.variant_id)] = {
          ...cartItem,
          quantity: cartItem.quantity + action.payload.quantity,
        };
      }
      setVoucher({});
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
        voucher: { ...state.voucher },
      };

    case UPDATE_TO_CART:
      if (!state.cartItems.find((item) => item.variant_id === action.payload.variant_id)) {
        state.cartItems.push({
          ...action.payload,
          //   quantity: 1,
        });
      } else {
        state.cartItems.forEach((item) => {
          if (item.variant_id === action.payload.variant_id) {
            item.quantity = action.payload.quantity;
          }
        });
      }
      setVoucher({});
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
        voucher: { ...state.voucher },
      };
    // If the action type is REMOVE_ITEM, we want to remove the item from the cartItems array
    case REMOVE_ITEM:
      if (state.cartItems.filter((item) => item.variant_id !== action.payload.variant_id).length === 0) {
        setAddress({});
        setVoucher({});
      }
      return {
        ...state,
        ...sumItems(state.cartItems.filter((item) => item.variant_id !== action.payload.variant_id)),
        cartItems: [...state.cartItems.filter((item) => item.variant_id !== action.payload.variant_id)],
        shippingAddress: { ...state.shippingAddress },
        voucher: { ...state.voucher },
      };

    // If the action type is INCREASE, we want to increase the quantity of the particular item in the cartItems array
    case INCREASE:
      state.cartItems[state.cartItems.findIndex((item) => item.id === action.payload.id)].quantity++;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };

    // If the action type is DECREASE, we want to decrease the quantity of the particular item in the cartItems array
    case DECREASE:
      state.cartItems[state.cartItems.findIndex((item) => item.id === action.payload.id)].quantity--;
      return {
        ...state,
        ...sumItems(state.cartItems),
        cartItems: [...state.cartItems],
      };

    // If the action type is CHECKOUT, we want to clear the cartItems array and set the checkout to true
    case CHECKOUT:
      return {
        cartItems: [],
        checkout: true,
        ...sumItems([]),
      };

    //If the action type is CLEAR, we want to clear the cartItems array
    case CLEAR:
      setVoucher({});
      return {
        cartItems: [],
        shippingAddress: {},
        ...sumItems([]),
        voucher: { ...state.voucher },
      };

    case ADD_SHIPPING_ADDRESS:
      setAddress(action.payload);
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case ADD_VOUCHER:
      setVoucher(action.payload);
      return {
        ...state,
        voucher: action.payload,
      };

    //Return the state if the action type is not found
    default:
      return state;
  }
};

export default CartReducer;
