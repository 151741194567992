import React from "react";
import { Box, Text } from "zmp-ui";
import TopAppBar from "../top_app_bar";
import { useState } from "react";
import api from "api";
import { useEffect } from "react";
import { assets, colors } from "theme-config";
import { useContext } from "react";
import CartContext from "context/cart/CartContext";
import numeral from "numeral";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function VoucherPickerList() {
  const navigate = useNavigate();
  const context = useContext(CartContext);
  const [voucherList, setVoucherList] = useState([]);

  useEffect(() => {
    getVoucherList();
  }, []);

  const getVoucherList = () => {
    api
      .post("guest/voucherpickerlist")
      .then((res) => {
        setVoucherList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderVoucherRequired = (record) => {
    if (record.program?.voucher_tmp?.min_order_total_price?.is_min_order_total_price) {
      if (parseInt(context.total) < record.program.voucher_tmp.min_order_total_price.value) {
        var length = (record.program.voucher_tmp.min_order_total_price.value - parseInt(context.total)).toString().length;
        return `Đặt thêm ${(record.program.voucher_tmp.min_order_total_price.value - parseInt(context.total))
          .toString()
          .slice(0, length - 3)}k để dùng voucher này`;
      } else {
        return `Thanh toán ít nhất ${numeral(record.program.voucher_tmp.min_order_total_price.value).format("0,0")}`;
      }
    } else {
      return "";
    }
  };

  const renderTimeLimited = (record) => {
    if (record.program.voucher_tmp?.time_end?.is_time_end) {
      var end = moment(record.program.voucher_tmp.time_end.value).valueOf();
      var now = moment(new Date()).valueOf();
      if (numeral((end - now) / (1000 * 60 * 60 * 24)).format("0") > 7) {
        return `Hết hạn ${moment(record.program.voucher_tmp.time_end.value).format("DD/MM/YYYY")}`;
      } else if (numeral((end - now) / (1000 * 60 * 60)).format("0") > 24) {
        return `Hết hạn trong ${numeral((end - now) / (1000 * 60 * 60 * 24)).format("0")} ngày`;
      } else {
        return `Hết hạn trong ${numeral((end - now) / (1000 * 60 * 60)).format("0")} giờ`;
      }
    } else if (record.program.voucher_tmp?.voucher_limit?.is_voucher_limit) {
      if (record.program.voucher_tmp.voucher_limit.value > 7) {
        return `Hêt hạn ${moment().add(record.program.voucher_tmp.voucher_limit.value, "days").format("DD/MM/YYYY")}`;
      } else {
        return `Hết hạn trong ${record.program.voucher_tmp.voucher_limit.value} ngày`;
      }

      //   return `Hết hạn ${record.program.voucher_tmp.voucher_limit} ngày`;
    } else {
      return `Không hết hạn`;
    }
  };

  const addVoucher = (record) => {
    context.addVoucher(record);
    setTimeout(() => {
      navigate(-1);
    }, 100);
  };

  return (
    <Box style={{ minHeight: "100vh" }}>
      <TopAppBar mode="back" title="Ưu đãi" />
      {voucherList.length > 0 ? (
        <Box flex flexDirection="column">
          {voucherList.map((item) => {
            var avalable = renderVoucherRequired(item) && renderVoucherRequired(item).slice(0, 8) === "Đặt thêm" ? false : true;
            // console.log(avalable);
            return (
              <Box
                flex
                flexDirection="row"
                style={{ background: "white", padding: "8px 24px 8px 16px", gap: 16, borderBottom: `1px solid ${colors.outlineVariant}` }}
              >
                <img src={item.program.logo} style={{ width: 56, height: 56, opacity: avalable ? 1 : 0.3 }} alt="" />
                <Box flex flexDirection="column">
                  <Text style={{ fontSize: 16, fontWeight: 500, lineHeight: "24px", opacity: avalable ? 1 : 0.3 }}>
                    {item.program.voucher ? item.program.voucher.name : item.program.name}
                  </Text>
                  <Text style={{ fontSize: 12, fontWeight: 400 }}>{renderVoucherRequired(item)}</Text>
                  <Text style={{ fontSize: 12, fontWeight: 400, color: renderTimeLimited(item).slice(3) === "giờ" ? colors.error : "black" }}>
                    {renderTimeLimited(item)}
                  </Text>
                </Box>
                {avalable && (
                  <Box
                    flex
                    flexDirection="row"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      addVoucher(item);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      style={{ background: colors.primaryContainer, marginLeft: "auto", marginTop: "auto", marginBottom: "auto", borderRadius: "50%" }}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="ic:baseline-plus">
                        <path id="Vector" d="M19 12.998H13V18.998H11V12.998H5V10.998H11V4.99805H13V10.998H19V12.998Z" fill={colors.primary} />
                      </g>
                    </svg>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box flex flexDirection="row" alignItems="center" justifyContent="center" style={{ height: "calc(100vh - 50.5px)" }}>
          <Box flex flexDirection="column">
            <img style={{ width: 248, height: 248 }} src={assets.empty_bg.img} className="rounded mx-auto d-block" alt="..." />
            <Text className="text-center" style={{ fontSize: 16, fontWeight: 500 }}>
              Bạn chưa có ưu đãi nào
            </Text>
            {/* <Text className="text-center" style={{ fontWeight: 500, color: colors.secondary }}>
              Hãy đổi điểm để nhận ưu đãi ngay nhé !!!
            </Text> */}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default VoucherPickerList;
