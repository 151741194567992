import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import TopAppBar from "components/common/top_app_bar";
import { colors, assets, styles } from "theme-config";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";
import { Tabs, List, Icon, Box, Button } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import api from "api";
import CartContext from "context/cart/CartContext";
import ProductCard from "./ProductCard";
import InfiniteScroll from "react-infinite-scroll-component";

const { Item } = List;
function ProductList() {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState();
  const [tabsList, setTabsList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const ref = useRef();
  const context = useContext(CartContext);
  const containerRef = React.useRef();
  useEffect(() => {
    // console.log(context);
    getDataFromStorage();
    getCatagory();
  }, []);

  const getDataFromStorage = async () => {
    var keys = ["currentKeyProduct"];
    var { currentKeyProduct } = await getStorage({ keys: keys });
    if (currentKeyProduct) {
      setActiveKey(currentKeyProduct);
    } else {
      setActiveKey("all");
    }
  };

  useEffect(() => {
    if (activeKey) {
      getProduct(activeKey, 0);
      // var activeLine = document.querySelector(".zaui-tabs-tabbar-active-line").style;
      // activeLine.setProperty("background", colors.primary);

      // debugger;
    }
  }, [activeKey]);

  const getCatagory = () => {
    api
      .post("guestlogin/getproductcategory", { shop_name: window.shop_id })
      .then((res) => {
        if (res.length > 0) {
          var tabs = res.map((item) => {
            return { key: item.id, name: item.name };
          });
          tabs.unshift({ key: "all", name: "Tất cả" });
          setTabsList(tabs);
        } else {
          var tabs = [{ key: "all", name: "Tất cả" }];
          setTabsList(tabs);
        }
      })
      .catch((err) => {
        var tabs = [{ key: "all", name: "Tất cả" }];
        setTabsList(tabs);
      });
  };
  const getProduct = () => {
    api
      .post("guestlogin/getlistproduct", { shop_name: window.shop_id, category_id: activeKey, skip: productList.length })
      .then((res) => {
        if (productList.length === 0) {
          setProductList(res);
          setLoading(false);
          if (res.length < 10) {
            setIsEnd(true);
          } else {
            setIsEnd(false);
          }
        } else {
          if (res.length > 0) {
            setTimeout(() => {
              setProductList(productList.concat(res));
              setLoading(false);
            }, 500);
            if (res.length < 10) {
              setIsEnd(true);
            } else {
              setIsEnd(false);
            }
          } else {
            setLoading(false);
            setIsEnd(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const discountNumber = (originalPrice, salePrice) => {
    var discount = ((originalPrice - salePrice) * 100) / originalPrice;
    var split = discount.toString().split(".");

    if (split[0] > 0) {
      return <>{numeral(discount).format("0")}%</>;
    } else {
      return <>{numeral(discount).format("0.0")}%</>;
    }
  };

  // const lastProductElementRef = (node) => {
  //   ref.current = new IntersectionObserver((entries) => {
  //     if (entries[0].isIntersecting && !isEnd) {
  //       setLoading(true);

  //       setTimeout(() => {
  //         getProduct(activeKey, productList.length);
  //       }, 1000);
  //     }
  //   });
  //   if (node) ref.current.observe(node);
  // };

  const renderContent = () => {
    return (
      <div className="pt-1 pe-1 ps-1">
        <InfiniteScroll
          dataLength={productList.length} //This is important field to render the next data
          next={getProduct}
          hasMore={!isEnd}
          height={"calc(100vh - 100px)"}
          style={{ paddingBottom: 56 }}
          loader={
            <div className="d-flex justify-content-center m-2">
              <div className="spinner-border" style={{ color: colors.primary, width: 24, height: 24 }} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          }
          // endMessage={
          // }
          // below props only if you need pull down functionality
        >
          <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            {productList.map((item, index) => {
              // if (productList.length === index + 1) {
              //   return <ProductCard key={item.id} item={item} position={lastProductElementRef} />;
              // } else {
              return <ProductCard key={item.id} item={item} />;
              // }
            })}
          </div>
          {/* {items.map((item, index) => {
            return (
              <div
                style={{
                  height: 30,
                  border: "1px solid green",
                  margin: 6,
                  padding: 8,
                }}
                key={index}
              >
                div - #{index}
              </div>
            );
          })} */}
        </InfiniteScroll>
        {/* <div className="row p-1">
          {productList.map((item, index) => {
            if (productList.length === index + 1) {
              return <ProductCard key={item.id} item={item} position={lastProductElementRef} />;
            } else {
              return <ProductCard key={item.id} item={item} />;
            }
          })}
        </div>
        {loading && (
          <div class="d-flex justify-content-center m-2">
            <div class="spinner-border" style={{ color: colors.primary, width: 24, height: 24 }} role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )} */}
      </div>
    );
  };

  const handleMouseDown = React.useCallback((e) => {
    const ele = containerRef.current;
    if (!ele) {
      return;
    }
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    const handleMouseMove = (e) => {
      const dx = e.clientX - startPos.x;
      const dy = e.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      resetCursor(ele);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }, []);

  const handleTouchStart = React.useCallback((e) => {
    const ele = containerRef.current;
    if (!ele) {
      return;
    }
    const touch = e.touches[0];
    const startPos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: touch.clientX,
      y: touch.clientY,
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const dx = touch.clientX - startPos.x;
      const dy = touch.clientY - startPos.y;
      ele.scrollTop = startPos.top - dy;
      ele.scrollLeft = startPos.left - dx;
      updateCursor(ele);
    };

    const handleTouchEnd = () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
      resetCursor(ele);
    };

    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchend", handleTouchEnd);
  }, []);

  const updateCursor = (ele) => {
    ele.style.cursor = "grabbing";
    ele.style.userSelect = "none";
  };

  const resetCursor = (ele) => {
    ele.style.cursor = "grab";
    ele.style.removeProperty("user-select");
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <nav className="navbar sticky-top" style={{ background: colors.primary, padding: "12px 8px", zIndex: 999 }}>
        <button
          onClick={() => {
            navigate(-1, { animate: false });
          }}
          className="btn"
          style={{ padding: 0, background: colors.primary }}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons/left">
              <path id="Vector" d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z" fill={colors.onPrimary} />
            </g>
          </svg>
        </button>
        <div className="d-flex flex-fill flex-row align-item-center justify-content-center" style={{ marginRight: 24 }}>
          <span style={{ color: colors.onPrimary, fontSize: 16 }}>Danh mục</span>
        </div>
      </nav>
      <></>

      {activeKey && (
        <div className="mobile">
          <Tabs
            className="categoryTabs"
            //id="categoryTabs"
            onChange={async (e) => {
              setProductList([]);
              setLoading(true);
              setActiveKey(e);
              // setIsEnd(false);
              var data = {
                currentKeyProduct: e,
              };
              await setStorage({ data });
            }}
            activeKey={activeKey}
          >
            {tabsList.map((item) => {
              return (
                <Tabs.Tab
                  style={{ paddingTop: 45 }}
                  key={item.key}
                  label={
                    <span style={{ color: item.key === activeKey ? colors.primary : colors.onSurfaceVariant, fontSize: 14, fontWeight: 500 }}>{item.name}</span>
                  }
                >
                  {productList && productList.length > 0 ? (
                    renderContent(item.key, item.name)
                  ) : !loading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 94.5px)" }}>
                      <img src={assets.empty_bg.img} alt="" />
                      <div className="fw-bold text-center" style={{ ...styles.textSecondary, fontSize: 20 }}>
                        Chưa có sản phẩm
                      </div>
                    </div>
                  ) : (
                    <div class="d-flex justify-content-center align-items-center m-2" style={{ height: "calc(100vh - 96px - 16px)" }}>
                      <div class="spinner-border" style={{ color: colors.primary, width: 24, height: 24 }} role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </Tabs.Tab>
              );
            })}
          </Tabs>
        </div>
      )}
      {activeKey && (
        <>
          <div
            ref={containerRef}
            onMouseDown={handleMouseDown}
            // onTouchStart={handleTouchStart}
            className="row gx-0 gy-0 align-items-center desktop"
            style={{ width: 430, overflowX: "auto", height: 50, flexWrap: "unset", cursor: "grab", touchAction: "none" }}
          >
            {tabsList.map((item) => {
              return (
                <button
                  style={{
                    width: "fit-content",
                    padding: "8px 16px",
                    border: "none",
                  }}
                >
                  <span
                    style={{
                      // fontSize: 14,
                      color: item.key === activeKey ? colors.primary : colors.onSurfaceVariant,
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                    onClick={async () => {
                      setProductList([]);
                      setLoading(true);
                      setActiveKey(item.key);
                      // setIsEnd(false);
                      var data = {
                        currentKeyProduct: item.key,
                      };
                      await setStorage({ data });
                    }}
                  >
                    {item.name}
                  </span>
                </button>
              );
            })}
          </div>
          <div className="desktop">{renderContent()}</div>
        </>
      )}
      {context.itemCount > 0 && (
        <Box flex flexDirection="row" className="fixed-bottom px-2 py-3 bottom-navigation">
          <div
            className="d-flex px-3 py-2 justify-content-between"
            style={{ background: colors.primary, color: "white", width: "100%", borderRadius: 8, fontSize: 14, fontWeight: 500 }}
            onClick={() => {
              navigate(`/${window.shop_sub_path}/Cart`);
            }}
          >
            <span>Đơn hàng</span>
            <span>
              {context.itemCount} món <Icon icon="zi-bullet-solid" size={14} /> {numeral(parseInt(context.total)).format("0,0")}
            </span>
          </div>
        </Box>
      )}
    </div>
  );
}

export default ProductList;
