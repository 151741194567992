import React, { useEffect, useState, useRef } from "react";
import TopAppBar from "../common/top_app_bar";
import api from "api";
import { colors, assets, styles } from "theme-config";
import moment from "moment";
import BlogItem from "./BlogItem";
import ListCategory from "./ListCategory";

const ExploreList = () => {
  const [select, setSelect] = useState("all");
  return (
    <div style={{ height: "100vh", ...styles.mainBackground }}>
      <TopAppBar mode="back" title="Khám phá" />
      <div className="container-fluid p-2">
        <ListCategory select={select} setSelect={setSelect}></ListCategory>
      </div>
      <BlogItem select={select} stylesBg={styles.bgWhite} />
    </div>
  );
};

export default ExploreList;
