import React, { useState, useEffect } from "react";
import { BottomNavigation, Icon, Page } from "zmp-ui";
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import { colors, assets, styles } from "theme-config/index";
import { useParams } from "react-router-dom";
import api from "api";
import moment from "moment";
import TopAppBar from "../common/top_app_bar";
import { openChat, openShareSheet, login } from "zmp-sdk/apis";
import { getStorage } from "common/zalo_storage/ZaloStorage";

const ExploreDetail = () => {
  const param = useParams();
  const [detail, setDetail] = useState();
  const bottomActions = [
    {
      key: "contact",
      label: "Liên hệ",
      icon: <Icon size={16} icon="zi-call-solid" />,
      background: "white",
      color: colors.outline,
    },
    { key: "chat", label: "Chat", icon: <Icon size={16} icon="zi-chat-solid" />, background: "white", color: colors.outline },
    { key: "share", label: "Chia sẻ", icon: <Icon size={16} icon="zi-share-external-2" />, background: colors.primary, color: "white" },
  ];
  const getDetail = async () => {
    await api
      .post("/guestlogin/getblogdetail", { id: param.id, shop_name: window.shop_id })
      .then((data) => {
        setDetail(data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getDetail();
  }, []);
  useEffect(() => {
    if (detail) {
      var contents = document.querySelectorAll("#blog-content img");
      contents.forEach((item) => {
        item.style.width = "100%";
        item.style.height = "auto";
      });
    }
  }, [detail]);

  const openChatScreen = async () => {
    // getStorage({
    //   keys: ["oa_id"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { oa_id } = data;

    //     //mở chat zalo
    //     openChat({
    //       type: "oa",
    //       id: oa_id,
    //       message: "Xin chào, có ai hỗ trợ tôi không.",
    //     });
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    try {
      var { oaId } = await getStorage({ keys: ["oaId"] });
      if (oaId) {
        openChat({
          type: "oa",
          id: oaId,
          message: "Xin chào, có ai hỗ trợ tôi không.",
        });
      }
    } catch (error) {}
  };

  const shareLink = () => {
    login({
      success: async () => {
        // login thành công
        try {
          var keys = ["shopName", "queryParam"];
          var { shopName, queryParam } = await getStorage({ keys: keys });
        } catch (error) {}

        // var { shop_name, queryParam } = await getStorage({ keys: ["shop_name", "queryParam"] });

        openShareSheet({
          // type: "link",
          // data: {
          //   link: "https://zalo.me/s/1341096052640481341/",
          //   chatOnly: false,
          // },
          type: "zmp",
          data: {
            title: shopName,
            description: detail.title,
            thumbnail: detail.cover_image,
            path: `/ExploreDetail/${detail.id}` + queryParam,
          },
          success: (data) => {
            // console.log("openShareSheetSuccess:", data);
          },
          fail: (err) => {
            console.log("openShareSheetError:", err);
          },
        });
      },
      fail: (error) => {
        // login thất bại
        console.log(error);
      },
    });
  };

  const onClickAction = async (key) => {
    switch (key) {
      case "contact":
        // const { shop_phone } = await getStorage({ keys: ["shop_phone"] });
        var { shopPhone } = await getStorage({ keys: ["shopPhone"] });
        // console.log("shop_phone", shop_phone);
        if (shopPhone) {
          return (window.location.href = `tel:${shopPhone}`);
        }
        break;
      case "chat":
        return openChatScreen();
      case "share":
        return shareLink();
      default:
        break;
    }
  };

  return (
    <div style={{ height: "100vh", ...styles.mainBackground }}>
      <TopAppBar mode="back" title="Khám Phá" />
      <div className="container-fluid pt-2">
        <h1 style={styles.texHeaderExplore}>{detail?.title}</h1>
      </div>
      <div
        id="blog-content"
        className="container-fluid pb-5"
        dangerouslySetInnerHTML={{ __html: detail?.content.replace(/<a\b[^>]*>(.*?)<\/a>/gi, "<span>$1</span>") }}
        style={{ ...styles.textBlack, fontSize: 14, overflowY: "auto" }}
      ></div>
      <BottomNavigation fixed className="customBottomAction bottom-navigation">
        {bottomActions.map((item) => {
          return (
            <BottomNavigation.Item
              onClick={() => {
                onClickAction(item.key);
              }}
              className="justify-content-center align-items-center d-flex flex-column"
              style={{ color: item.color, background: item.background, fontSize: 12, height: "100%" }}
              key={item.key}
              label={item.label}
              icon={item.icon}
            />
          );
        })}

        {/* <BottomNavigation.Item style={{ color: colors.outline }} label="Danh bạ" key="contact" icon={<Icon icon="zi-call" />} />
        <BottomNavigation.Item style={{ color: colors.outline }} label="Khám phá" key="discovery" icon={<Icon icon="zi-more-grid" />} /> */}
      </BottomNavigation>
    </div>
  );
};
export default ExploreDetail;
