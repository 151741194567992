import React, { useEffect, useRef, useState } from "react";
import * as PIXI from "pixi.js";
import gsap from "gsap";
import ModalZaUI from "components/common/modal/ModalZaUI";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import { sound } from "@pixi/sound";
import { getStorage } from "common/zalo_storage/ZaloStorage";
import api from "api";
import { getUserInfo } from "zmp-sdk/apis";
import { useParams } from "react-router-dom";

const OpenGiftBox = ({ data }) => {
  console.log(data);
  const { id } = useParams();
  let isSound = true;
  const navigate = useNavigate();
  const app = useRef(null);
  const giftBox = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    // description: "hehe",
  });
  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState();
  const [token, setToken] = useState();
  const [turnNumber, setTurnNumber] = useState(0);
  const [userInfo, setUserInfo] = useState();
  const [logo, setLogo] = useState();
  const [mode, setMode] = useState();
  const init = async (width, height) => {
    PIXI.Assets.reset();
    const manifestExample = {
      bundles: [
        //   {
        //     name: 'load-screen',
        //     assets: [
        //         {
        //             name: 'flowerTop',
        //             srcs: 'https://pixijs.com/assets/flowerTop.png',
        //         },
        //     ],
        // },
        {
          name: "game-screen",
          assets: [
            {
              name: "background",
              srcs: data.background.image_url,
            },
            {
              name: "closeButton",
              srcs: data.themes ? data.themes.themes_data.close_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/close_button.svg",
            },
            {
              name: "addRollButton",
              srcs: data.themes
                ? data.themes.themes_data.add_turn_btn
                : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/add_roll_button%20(1).png",
            },
            {
              name: "soundOnTextTure",
              srcs: data.themes ? data.themes.themes_data.sound_on_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/sound_on_button.svg",
            },
            {
              name: "soundOffTextTure",
              srcs: data.themes ? data.themes.themes_data.sound_off_btn : "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/sound_off_button.svg",
            },
            {
              name: "giftbox",
              srcs: data.themes ? data.themes.themes_data.giftbox : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/giftbox.png",
            },
            {
              name: "giftboxOpen",
              srcs: data.themes ? data.themes.themes_data.giftbox_open : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/giftbox_open.png",
            },
            {
              name: "openButton",
              srcs: data.themes ? data.themes.themes_data.start_btn : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/open_button.png",
            },
            {
              name: "historyButton",
              srcs: data.themes ? data.themes.themes_data.history_btn : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/history_button.png",
            },
            {
              name: "infoButton",
              srcs: data.themes ? data.themes.themes_data.info_btn : "https://storage.googleapis.com/cubeloyalty_asia/open_giftbox/info_button.png",
            },
            { name: "backgroundMusic", srcs: data.themes ? data.themes.themes_data.background_music : "" },
          ],
        },
      ],
    };

    await PIXI.Assets.init({ manifest: manifestExample });
    PIXI.Assets.backgroundLoadBundle(["load-screen", "game-screen"]);

    makeGameScreen(width, height);
  };

  const makeGameScreen = async (width, height) => {
    const loadScreenAssets = await PIXI.Assets.loadBundle("game-screen");

    const background = PIXI.Sprite.from(loadScreenAssets.background);
    background.width = width;
    background.height = height;
    app.current.stage.addChild(background);

    const closeButton = PIXI.Sprite.from(loadScreenAssets.closeButton);
    closeButton.width = 40;
    closeButton.height = 40;
    closeButton.x = 16;
    closeButton.y = 48;
    closeButton.interactive = true;
    closeButton.buttonMode = true;
    closeButton.on("pointerdown", back);
    app.current.stage.addChild(closeButton);

    const addRollButton = PIXI.Sprite.from(loadScreenAssets.addRollButton);
    addRollButton.width = 200;
    addRollButton.height = 40;
    addRollButton.x = width / 2 - 100;
    addRollButton.y = 48;
    addRollButton.interactive = true;
    addRollButton.buttonMode = true;
    addRollButton.on("pointerdown", () => handleOnClickButtonWhenUserNotLoggedIn("add"));
    app.current.stage.addChild(addRollButton);

    // let isSound = true;
    // const soundOnTextTure = PIXI.Texture.from(textures.soundOnTextTure);
    // const soundOffTextTure = PIXI.Texture.from(textures.soundOffTextTure);
    const soundButton = PIXI.Sprite.from(loadScreenAssets.soundOnTextTure);
    soundButton.width = 40;
    soundButton.height = 40;
    soundButton.x = width - 16 - 40;
    soundButton.y = 48;
    soundButton.interactive = true;
    soundButton.buttonMode = true;
    soundButton.on("pointerdown", () => onSound());
    app.current.stage.addChild(soundButton);

    // giftBox.current = new PIXI.Container();
    // giftBox.current.x = width / 2;
    // giftBox.current.y = height / 2 + 50;
    // app.current.stage.addChild(giftBox.current);

    // const len = sections.length / 2;
    // sections.forEach((section, index) => {
    //   if (!section.reward.text) {
    //     section.reward.text = section.text;
    //   }
    //   const arc = new PIXI.Graphics();
    //   arc.beginFill(section.backgroundColor);
    //   arc.moveTo(0, 0);
    //   arc.arc(0, 0, 150, index * (Math.PI / len), (index + 1) * (Math.PI / len));
    //   arc.endFill();

    //   const text = new PIXI.Text(section.text, {
    //     fontFamily: "Arial",
    //     fontSize: 14,
    //     fill: section.color,
    //     wordWrap: true,
    //     fontWeight: "bold",
    //   });
    //   text.anchor.set(0.5);

    //   // Tính toán góc quay cho văn bản
    //   const angle = ((2 * index + 1) * Math.PI) / sections.length;
    //   text.position.set(90 * Math.cos(angle), 90 * Math.sin(angle));

    //   // Đặt thuộc tính quay cho văn bản để hướng về trung tâm của bánh xe
    //   text.rotation = angle;

    //   arc.addChild(text);

    //   giftBox.current.addChild(arc);
    // });

    const giftbox = PIXI.Sprite.from(loadScreenAssets.giftbox);
    giftbox.anchor.set(0.5);
    giftbox.width = 240;
    giftbox.height = 240;
    giftbox.x = width / 2;
    giftbox.y = height / 2 + 50;

    // giftbox.addChild(wheelDecor);
    app.current.stage.addChild(giftbox);

    const giftboxOpen = PIXI.Sprite.from(loadScreenAssets.giftboxOpen);
    giftboxOpen.anchor.set(0.5);
    giftboxOpen.width = width;
    giftboxOpen.height = width;
    giftboxOpen.x = width / 2;
    giftboxOpen.y = height / 2 + 50;

    // giftbox.addChild(wheelDecor);
    // app.current.stage.addChild(giftbox);

    const openButton = PIXI.Sprite.from(loadScreenAssets.openButton);
    openButton.anchor.set(0.5);
    openButton.width = 200;
    openButton.height = 56;
    openButton.x = width / 2;
    openButton.y = height / 2 + 270;
    openButton.interactive = true;
    openButton.buttonMode = true;
    openButton.on("pointerdown", () => {
      handleOnClickButtonWhenUserNotLoggedIn("spin");
    });
    app.current.stage.addChild(openButton);

    const historyButton = PIXI.Sprite.from(loadScreenAssets.historyButton);
    historyButton.width = 70;
    historyButton.height = 70;
    historyButton.x = 16;
    historyButton.y = height - 88;
    historyButton.interactive = true;
    historyButton.buttonMode = true;
    historyButton.on("pointerdown", () => handleOnClickButtonWhenUserNotLoggedIn("history"));
    // const historyText = new PIXI.Text("Lịch sử", {
    //   fontFamily: "Arial",
    //   fontSize: 20,
    //   fontWeight: 700,
    //   fill: 0xffffff,
    // });
    // historyText.x = 0;
    // historyText.y = 70;
    // historyButton.addChild(historyText);
    app.current.stage.addChild(historyButton);

    const infoButton = PIXI.Sprite.from(loadScreenAssets.infoButton);
    infoButton.width = 70;
    infoButton.height = 70;
    infoButton.x = width - 86;
    infoButton.y = height - 88;
    infoButton.interactive = true;
    infoButton.buttonMode = true;
    infoButton.on("pointerdown", () => handleOnClickButtonWhenUserNotLoggedIn("info"));
    // const infoText = new PIXI.Text("Thể lệ", {
    //   fontFamily: "Arial",
    //   fontSize: 20,
    //   fontWeight: 700,
    //   fill: 0xffffff,
    // });
    // infoText.x = 0;
    // infoText.y = 70;
    // infoButton.addChild(infoText);
    app.current.stage.addChild(infoButton);

    // if ()
    // }

    if (isSound && data.themes?.themes_data.background_music) {
      sound.add("background-music", loadScreenAssets.backgroundMusic);
      // setTimeout(() => {
      sound.play("background-music", { volume: 0.1, autoPlay: true, loop: true });
      // }, 100);
    }

    const renderTurn = async () => {
      var { token } = await getStorage({ keys: ["token"] });
      if (token) {
        checkTurn();
        // setTurnNumber(1);
      } else {
        setTurnNumber(1);
      }
    };

    renderTurn();

    const onSound = () => {
      isSound = !isSound;
      soundButton.texture = isSound ? loadScreenAssets.soundOnTextTure : loadScreenAssets.soundOffTextTure;
      if (!isSound) {
        sound.stop("background-music");
      } else {
        sound.play("background-music", { volume: 0.1, loop: true });
      }
    };

    const showModalConfirmZalo = () => {
      setModalConfig({
        type: "login",
        reload: false,
        onOk() {
          checkTurn();
          renderTurn();
        },
      });
      setIsModalOpen(true);
    };

    const handleOnClickButtonWhenUserNotLoggedIn = async (key) => {
      var { token } = await getStorage({ keys: ["token"] });
      if (token) {
        // console.log(openButton);
        switch (key) {
          case "spin":
            checkTurn("spin");

            // spinToSection();
            break;
          case "add":
            openAddTurn();
            break;
          case "history":
            openGameHistory();
            break;
          case "info":
            openGameInfo();
            break;
          default:
            break;
        }
      } else {
        showModalConfirmZalo();
      }
    };

    const checkTurn = (key) => {
      api
        .post("guest/checkcustomerturn", { game_id: id })
        .then((res) => {
          setTurnNumber(res);
          if (key === "spin") {
            if (res < 1) {
              showError("Bạn đã hết lượt quay , hãy kiếm thêm lượt nhé");
            } else {
              spinToSection();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.code === "ERR_NETWORK") {
            showError("Vui lòng kết nối internet để tiến hành quay thưởng");
          }
        });
    };

    const spinToSection = () => {
      // setIsDisable(true);
      // console.log(openButton);
      if (isSound) {
        // sound.play("my-sound", { volume: 0.1, start: 0, end: 2 });
      }
      openButton.interactive = false;
      //   wheelSpin.interactive = false;

      var sectionId = getRandomSectionId(sections);
      // Find the section with the specified ID
      const targetSection = sections.find((section) => section.id === sectionId);
      saveReward(targetSection.reward);
      app.current.stage.removeChild(giftbox);
      app.current.stage.addChild(giftboxOpen);
      setTimeout(() => {
        showInfo(targetSection.text, targetSection.reward.type === "voucher" ? true : false, isSound);
        checkTurn();
      }, 1000);

      //   if (targetSection) {
      //     const numSections = sections.length;
      //     const sectionIndex = sections.indexOf(targetSection);
      //     const sectionAngle = (2 * Math.PI) / numSections;
      //     // const targetRotation = -(sectionIndex * sectionAngle + Math.PI / 2) - 0.3; // Negative to spin clockwise
      //     const targetRotation = -(Math.PI / 2 + Math.PI / 6 + sectionIndex * sectionAngle);
      //     // setSpinning(true);

      //     const onComplete = () => {
      //       // setSpinning(false);

      //       // console.log(buttonDisabled);
      //       giftBox.current.rotation = targetRotation;
      //       // setButtonDisabled(false);
      //       openButton.interactive = true;
      //       //   wheelSpin.interactive = true;
      //     };

      //     gsap.to(giftBox.current, {
      //       rotation: targetRotation + Math.PI * 12, // Adjust the number to control the spin
      //       duration: 3, // Duration of the spin animation
      //       ease: "power3.out",
      //       onComplete: onComplete,
      //     });
      //   }
      // }
    };

    const showInfo = (message, isCancel) => {
      var modal = {
        type: "reward_giftbox",
        description: message,
        logo: true,
        isCancel: isCancel,
        cancelText: "Xem quà",
        okText: "Tiếp tục",
        onCancel() {
          if (data.themes && data.themes.themes_data.background_music) {
            sound.stop("background-music");
          }
          navigate(`/${window.shop_sub_path}/VoucherList`);
        },
        onOk() {
          setIsRewardModalOpen(false);
          app.current.stage.removeChild(giftboxOpen);
          app.current.stage.addChild(giftbox);
          openButton.interactive = true;
        },
      };
      if (isSound) {
        sound.play("winning-sound", { volume: 0.1 });
      }
      setModalConfig(modal);
      setIsModalOpen(true);
    };

    // create a new Sprite from the resolved loaded texture
  };

  const sections = data.slices.map((slice, index) => {
    return {
      id: index,
      backgroundColor: slice.backgroundColor,
      text: slice.display.text.value,
      percent: slice.ratio,
      color: slice.display.text.color,
      reward: slice.reward,
    };
  });

  const getRandomSectionId = (sections) => {
    // Calculate the total percentage
    const totalPercent = sections.reduce((total, section) => total + section.percent, 0);

    // Generate a random number between 0 and the total percentage
    const randomValue = Math.random() * totalPercent;

    // Find the section whose range includes the random value
    let cumulativePercent = 0;
    for (const section of sections) {
      cumulativePercent += section.percent;
      if (randomValue <= cumulativePercent) {
        return section.id;
      }
    }

    // This should not happen, but just in case
    return null;
  };

  const context = sound.context.audioContext;

  context.onstatechange = () => {
    if ((context.state === "suspended" || context.state === "interrupted") && document.hasFocus()) {
      context.resume();
    }
  };

  const back = () => {
    if (data.themes && data.themes.themes_data.background_music) {
      sound.stop("background-music");
    }
    console.log("history", window.history.length);
    // if (window.history.length === 1) {
    //neu truoc do ko co lich su thi tra ve home
    navigate(`/${window.shop_sub_path}/Home`);
    // } else {
    //   navigate(-1);
    // }
  };

  const openAddTurn = () => {
    if (data.themes && data.themes.themes_data.background_music) {
      sound.stop("background-music");
    }
    navigate(`/${window.shop_sub_path}/Game/ExchangeTurn`, {
      state: { game_id: id, game_img: data.image, game_title: data.name, game_type: data.type === "open_giftbox" ? "OpenGiftBox" : "" },
    });
  };

  useEffect(() => {
    getLogo();
    getDataUserInfo();
    getToken();
    // console.log(getToken());
    var width = window.screen.width < 500 ? window.screen.width : 400;
    var height = window.screen.height < 845 ? window.screen.height - 64 : 844;

    sound.add("my-sound", "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/wheel_spinning_sound.mp3");
    sound.add("winning-sound", "https://storage.googleapis.com/cubeloyalty_asia/wheel_fortune/winning_sound.mp3");
    app.current = new PIXI.Application({
      width,
      height,
      backgroundColor: 0xffffff,
      autoDensity: true,
      resolution: window.devicePixelRatio,
    });

    init(width, height);

    //render background

    document.getElementById("giftBox-container").appendChild(app.current.view);

    return () => {
      app.current.destroy();
    };
  }, []);

  const getDataUserInfo = async () => {
    var { userInfo } = await getUserInfo();
    if (userInfo) {
      setUserInfo(userInfo);
    }
  };

  const getLogo = async () => {
    var { logo } = await getStorage({ keys: ["logo"] });
    if (logo) {
      setLogo(logo);
    }
  };

  const getToken = async () => {
    var { token } = await getStorage({ keys: ["token"] });
    if (token) {
      setToken(token);
      setMode("logged");
    } else {
      setMode("notlog");
    }
  };

  const saveReward = (reward) => {
    updateTurn();
    api
      .post("guest/gamerewardprocess", { id: id, reward: reward })
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTurn = () => {
    api
      .post("guest/subtrachcustomerturn", { game_id: id })
      .then((res) => {
        setTurnNumber(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openGameHistory = () => {
    if (data.themes && data.themes.themes_data.background_music) {
      sound.stop("background-music");
    }
    navigate(`/${window.shop_sub_path}/Game/History`, { state: { game_id: id } });
  };

  const openGameInfo = () => {
    if (data.themes && data.themes.themes_data.background_music) {
      sound.stop("background-music");
    }
    navigate(`/${window.shop_sub_path}/Game/Info`, { state: { game_id: id } });
  };

  useEffect(() => {
    if (!isModalOpen && modalConfig.type === "login") {
      // checkTurn();
    }
  }, [isModalOpen]);

  const showError = (message) => {
    var modal = {
      type: "error",
      description: message,
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  const positionX = () => {
    switch (turnNumber) {
      case turnNumber > 99:
        return "calc(100vw / 2 - 72px)";
      case turnNumber > 9:
        return "calc(100vw / 2 - 64px)";
      default:
        return "calc(100vw / 2 - 60px)";
    }
  };
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {/* <h1>Wheel of Fortune</h1> */}
      <div id="giftBox-container" style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center" }}></div>
      <div style={{ position: "absolute", top: 57, left: positionX() }}>
        Có <span style={{ fontWeight: 700 }}>{turnNumber}</span> lượt
      </div>
      {isModalOpen && <ModalZaUI isModalOpen={isModalOpen} modalConfig={modalConfig} setIsModalOpen={setIsModalOpen} />}
      {/* {isRewardModalOpen && <RewardModal popupVisible={isRewardModalOpen} setPopupVisible={setIsRewardModalOpen} giftName={selectedReward} />} */}
      {/* {finishPosition !== null && <p>Finish position: {finishPosition + 1}</p>}
      <button onClick={resetWheel}>Reset</button> */}
    </div>
  );
};

export default OpenGiftBox;
