var server = process.env.REACT_APP_SERVER;
// server = "https://api.cubeloyalty.vn";
// server = "http://localhost:4001";
server = "https://testapi.cubeloyalty.vn";
const api_url = `${server}/api`;
const wss_url = server;
const customer_link = "customer.cubeloyalty.vn";
const full_customer_link = "https://customer.cubeloyalty.vn";
const media_url = server;
const GOOGLE_CLIENT_ID = "411893161294-71p6ajdu58ut5h52r0tf4957r46rkqfm.apps.googleusercontent.com";
const FACEBOOK_CLIENT_ID = "794789890681037";
//const FACEBOOK_CLIENT_ID = "794789890681037";

export { api_url, media_url, wss_url, GOOGLE_CLIENT_ID, FACEBOOK_CLIENT_ID, customer_link, full_customer_link };
