import React, { useEffect, useState, useRef } from "react";
import TopAppBar from "../common/top_app_bar";
import api from "api";
import { colors, assets, styles } from "theme-config";
import moment from "moment";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";

/**
 *
 * @param {string} select id Category
 * @param {number} sizePage số lượng cần lấy trong 1 page
 * @param {object} stylesBg background input chuyền theo styles của config theme
 * @param {boolean} autoNextPage có tự động next page khi scroll xuống cuối trang ko
 * @returns
 */
const BlogItem = ({ select, sizePage = 20, stylesBg = styles.mainBackground, autoNextPage = true, showEmtyCenterBig = true }) => {
  const navigate = useNavigate();
  const [pagging, setPagging] = useState({ pageSize: sizePage, page: 1 });
  const [listBlog, setListBlog] = useState();
  const [loading, setLoading] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const ref = useRef();

  const getListBlog = async (categoryId, skip) => {
    var query = {
      pagging: { skip: skip, limit: sizePage },
      category: categoryId,
    };
    await api
      .post("/guestlogin/getpublishblog", { query, shop_name: window.shop_id })
      .then((data) => {
        if (skip === 0) {
          setIsEnd(false);
          setListBlog(data);
          setLoading(false);
        } else {
          if (data.length > 0) {
            var list = listBlog.concat(data);
            setListBlog(list);
            setLoading(false);
            if (data.length < 20) {
              setIsEnd(true);
            }
          } else {
            setLoading(false);
            setIsEnd(true);
          }
        }

        // if (data.length === pageSize && autoNextPage) {
        //   window.addEventListener("scroll", scrollEndPage);
        // }
      })
      .catch((err) => {
        setListBlog([]);
        setLoading("err");
      });
  };
  // const scrollEndPage = () => {
  //   const endOfPage = window.innerHeight + window.pageYOffset >= document.body.scrollHeight;
  //   var paggingNew = { ...pagging };
  //   if (endOfPage) {
  //     paggingNew.page += 1;
  //     setPagging(paggingNew);
  //     setLoading(true);
  //     window.removeEventListener("scroll", scrollEndPage);
  //   }
  // };
  const showExploreDetail = (id) => {
    navigate(`/${window.shop_sub_path}/ExploreDetail/${id}`);
  };
  // useEffect(() => {
  //   if (select) {
  //     setListBlog();
  //     setPagging({ pageSize: sizePage, page: 1 });
  //   }
  // }, [select]);
  // useEffect(() => {
  //   if (select) {
  //     getListBlog(select);
  //   }
  // }, [pagging]);
  useEffect(() => {
    if (select) {
      getListBlog(select, 0);
    }
  }, [select]);

  const lastProductElementRef = (node) => {
    ref.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isEnd) {
        setLoading(true);
        // setTimeout(() => {
        getListBlog(select, listBlog.length);
        // }, 1000);
      }
    });
    if (node) ref.current.observe(node);
  };
  return (
    <>
      <div className="container-fluid p-0 pb-3" style={stylesBg}>
        {!listBlog || listBlog?.length == 0 ? (
          <>
            <div className="container-fluid d-flex flex-column justify-content-center align-items-center" style={showEmtyCenterBig ? { height: "80vh" } : {}}>
              <div className="d-flex justify-content-center p-0">
                <img src={assets.empty_bg.img} alt="Logo" style={{ width: 190, height: 170 }} />
              </div>
              {!showEmtyCenterBig ? (
                <div className="fw-bold text-center" style={{ ...styles.textSecondary, fontSize: 22 }}>
                  Chưa có bài viết nào
                </div>
              ) : (
                <>
                  <div className="fw-bold text-center" style={{ ...styles.textOutline, fontSize: 22 }}>
                    Chưa có bài viết nào
                  </div>
                  <div className="fw-bold text-center" style={{ ...styles.textNeutral, fontSize: 14 }}>
                    Bài viết sẽ được cập nhật sớm nhất
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="row m-0">
            {listBlog.map((item, key) => {
              if (listBlog.length === key + 1 && sizePage === 20) {
                return (
                  <React.Fragment key={key}>
                    <div className="card border-0 col-6 p-2" ref={lastProductElementRef} onClick={() => showExploreDetail(item.id)} style={stylesBg}>
                      <div className="rounded news-card" style={styles.bgWhite}>
                        <img
                          className="card-img-top"
                          src={item.cover_image}
                          style={{ width: "100%", height: "calc(50vw - 16px)", borderRadius: 12 }}
                          alt="Card image"
                        />
                        <div className="card-body py-2 px-0">
                          <h4 className="card-title text-uppercase m-0" style={{ ...styles.textCardEllipsis, ...styles.textOnSurface, lineHeight: "20px" }}>
                            {item.title}
                          </h4>
                          {/* <h4
              className="card-title text-uppercase m-0"
              style={{ ...styles.textCardEllipsis, ...styles.textSecondary, marginTop: 4 }}
              // dangerouslySetInnerHTML={{ __html: item.content }}
            >
              {item.cover_image}
            </h4> */}
                          <p className="m-0" style={{ ...styles.textSecondary, fontSize: 11 }}>
                            {moment(item.publish_date).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={key}>
                    <div className="card border-0 col-6 p-2" onClick={() => showExploreDetail(item.id)} style={stylesBg}>
                      <div className="rounded news-card" style={styles.bgWhite}>
                        <img
                          className="card-img-top"
                          src={item.cover_image}
                          style={{ width: "100%", height: "calc(50vw - 16px)", borderRadius: 12 }}
                          alt="Card image"
                        />
                        <div className="card-body py-2 px-0">
                          <h4 className="card-title text-uppercase m-0" style={{ ...styles.textCardEllipsis, ...styles.textOnSurface, lineHeight: "20px" }}>
                            {item.title}
                          </h4>
                          {/* <h4
              className="card-title text-uppercase m-0"
              style={{ ...styles.textCardEllipsis, ...styles.textSecondary, marginTop: 4 }}
              // dangerouslySetInnerHTML={{ __html: item.content }}
            >
              {item.cover_image}
            </h4> */}
                          <p className="m-0" style={{ ...styles.textSecondary, fontSize: 11 }}>
                            {moment(item.publish_date).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              // console.log(item.content);
            })}
          </div>
        )}
      </div>
      {loading && loading !== "err" ? (
        <div className="container-fluid d-flex justify-content-center align-items-center my-2" style={{ height: 100 }}>
          <div class="spinner-border" style={{ width: 36, height: 36, ...styles.textPrimary }}></div>
        </div>
      ) : (
        <>
          {loading === "err" ? (
            <div className="container-fluid d-flex flex-column justify-content-center align-items-center">
              <svg width="39" height="36" viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M38.1004 31.4137L22.1226 1.74187C20.9901 -0.361875 17.9732 -0.361875 16.8398 1.74187L0.862923 31.4137C0.617028 31.8705 0.493748 32.3831 0.505115 32.9017C0.516482 33.4203 0.662109 33.927 0.92778 34.3725C1.19345 34.818 1.57009 35.187 2.02093 35.4435C2.47177 35.7 2.98142 35.8352 3.50011 35.8359H35.4585C35.9776 35.836 36.4879 35.7014 36.9394 35.4452C37.3908 35.1891 37.7681 34.8201 38.0344 34.3745C38.3006 33.9289 38.4466 33.4218 38.4582 32.9029C38.4698 32.3839 38.3465 31.8708 38.1004 31.4137ZM19.4817 31.2422C19.1108 31.2422 18.7483 31.1322 18.44 30.9262C18.1316 30.7202 17.8913 30.4273 17.7494 30.0847C17.6075 29.7421 17.5704 29.3651 17.6427 29.0014C17.715 28.6377 17.8936 28.3036 18.1558 28.0414C18.4181 27.7791 18.7522 27.6006 19.1159 27.5282C19.4796 27.4559 19.8566 27.493 20.1992 27.6349C20.5418 27.7768 20.8347 28.0171 21.0407 28.3255C21.2467 28.6338 21.3567 28.9963 21.3567 29.3672C21.3567 29.8645 21.1591 30.3414 20.8075 30.693C20.4559 31.0446 19.979 31.2422 19.4817 31.2422ZM21.5179 12.3844L20.9798 23.8219C20.9798 24.2197 20.8218 24.6012 20.5405 24.8825C20.2592 25.1638 19.8776 25.3219 19.4798 25.3219C19.082 25.3219 18.7004 25.1638 18.4191 24.8825C18.1378 24.6012 17.9798 24.2197 17.9798 23.8219L17.4417 12.3891C17.4296 12.1159 17.4726 11.843 17.5682 11.5868C17.6638 11.3306 17.81 11.0963 17.9981 10.8978C18.1862 10.6993 18.4123 10.5407 18.663 10.4314C18.9137 10.3222 19.1838 10.2645 19.4573 10.2619H19.477C19.7523 10.2617 20.0248 10.3174 20.278 10.4254C20.5312 10.5335 20.7599 10.6917 20.9503 10.8906C21.1407 11.0895 21.2888 11.3249 21.3857 11.5826C21.4826 11.8403 21.5263 12.115 21.5142 12.39L21.5179 12.3844Z"
                  fill={colors.error}
                />
              </svg>
              <p className="pt-2 m-0" style={{ ...styles.textOutline, fontSize: 14 }}>
                Có lỗi xảy ra
              </p>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default BlogItem;
