import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./custom.scss";
import "bootstrap/dist/js/bootstrap.bundle";
import "theme-config/config.css";

import "zmp-ui/zaui.css";
import "./custom.zalo.scss";
import CartState from "./context/cart/CartState.jsx";

//css skeleton library
import 'react-loading-skeleton/dist/skeleton.css'

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
  <CartState>
    <App />
  </CartState>
);

// const root = ReactDOM.createRoot(document.getElementById("app"));
// root.render(React.createElement(App));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
