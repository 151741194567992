import TopAppBar from "components/common/top_app_bar";
import React, { useState, useEffect } from "react";
import * as bootstrap from "bootstrap";
import { colors, styles } from "theme-config";
import QRCode from "react-qr-code";
import moment from "moment";
import { showToast } from "zmp-sdk/apis";
import VoucherDivider from "components/common/VoucherDivider";
import api from "api";
import { getStorage } from "common/zalo_storage/ZaloStorage";

function VoucherDetailModal({ isToggle, modalConfig, setIsToggle }) {
  const [modal, setModal] = useState();
  const [voucherDetail, setVoucherDetail] = useState();
  const [shopName, setShopName] = useState();
  useEffect(() => {
    const modal = new bootstrap.Modal(document.getElementById("voucherDetailModal"));
    setModal(modal);
    // getStorage({
    //   keys: ["shop_name"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { shop_name } = data;
    //     if (shop_name) {
    //       setShopName(shop_name);
    //     } else {
    //       setShopName(window.shop_id);
    //     }
    //     // setLogState("logged");
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    getShopName();
  }, []);

  const getShopName = async () => {
    var { shopName } = await getStorage({ keys: ["shopName"] });
    if (shopName) {
      setShopName(shopName);
    } else {
      setShopName(window.shop_id);
    }
  };

  useEffect(() => {
    if (modal) {
      if (modalConfig?.type === "voucherDetail") {
        modal.toggle();
        getVoucherById();
      }
    }
  }, [isToggle, modal]);

  const getVoucherById = () => {
    api.post("guest/getvoucher", { voucher_id: modalConfig.voucherId }).then((res) => {
      setVoucherDetail(res);
      // console.log(res)
    });
  };

  const openToast = () => {
    showToast({
      message: "Sao chép thành công",
      success: (data) => {
        // xử lý khi gọi api thành công
      },
      fail: (error) => {
        // xử lý khi gọi api thất bại
        console.log(error);
      },
    });
  };

  return (
    <div className="modal fade-scale" id="voucherDetailModal" tabIndex="-1" role="dialog" aria-labelledby="voucherDetailModalLabel" aria-hidden="true">
      <div className="modal-dialog" style={{ margin: 0, minHeight: "100%", display: "flex", alignItems: "end", maxWidth: "100vw" }}>
        <div
          className="modal-content"
          style={{ background: colors.onPrimaryContainer, width: "100vw", margin: 0, marginTop: 64, borderRadius: 0, padding: "12px 20px" }}
        >
          {voucherDetail && (
            <div className="modal-body" style={{ padding: 12, background: colors.onPrimary, borderRadius: 12 }}>
              <div
                className="d-flex flex-column"
                style={{
                  gap: 8,
                  background: colors.onPrimary,
                  position: "relative",
                }}
              >
                <div className="align-self-center">
                  <h6 style={{ fontSize: 11, margin: 0 }}>{shopName}</h6>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div style={{ width: 24 }}></div>
                  <h5 style={{ fontSize: 16, margin: 0 }}>{voucherDetail.program.name}</h5>
                  <button
                    className="btn p-0"
                    onClick={() => {
                      setIsToggle(!isToggle);
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={colors.onPrimary}>
                      <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill={colors.onSurfaceVariant}
                      />
                    </svg>
                  </button>
                </div>
                <div style={{ position: "absolute", left: -13, bottom: -32 }}>
                  <VoucherDivider width={modalConfig.width - 40} height={24} background={colors.onPrimaryContainer} horizontal={true} />
                </div>
              </div>
              <div
                className="d-flex flex-column"
                style={{ gap: 8, background: "white", padding: "56px 0px 0px 0px", borderBottomLeftRadius: 12, borderBottomRightRadius: 12 }}
              >
                <div className="align-self-center">
                  {modalConfig?.voucherId && <QRCode title="GeeksForGeeks" value={modalConfig.voucherId} bgColor="#FFFFFF" fgColor="#000000" size={180} />}
                </div>

                <div style={{ padding: "16px 0" }}>
                  <h5 className="text-center" style={{ fontSize: 16, margin: 0, lineHeight: "24px" }}>
                    {modalConfig.voucherId}
                  </h5>
                  <h5
                    className="text-center"
                    style={{ fontSize: 16, margin: 0, color: colors.primary, lineHeight: "24px" }}
                    onClick={() => {
                      navigator.clipboard.writeText(modalConfig.voucherId);
                      openToast();
                    }}
                  >
                    SAO CHÉP
                  </h5>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn"
                    style={{ ...styles.btnRoundedPill, ...styles.btnPrimary }}
                    onClick={() => {
                      if (voucherDetail.is_phone_card) {
                        window.location.href = `tel:*100*${modalConfig.voucherId}#`;
                      } else {
                        navigator.clipboard.writeText(modalConfig.voucherId);
                        openToast();
                      }
                    }}
                  >
                    Sử dụng ngay
                  </button>
                </div>
                <div className="hr" style={{ borderTop: `0.3px solid ${colors.outlineVariant}`, margin: "8px 0" }}></div>

                <div className="d-flex justify-content-between align-items-center">
                  <h6 style={{ margin: 0 }}>Ngày hết hạn:</h6>
                  {/* {voucherDetail?.program?.voucher?.time_end?.is_time_end ? (
                    <h6 style={{ margin: 0 }}>{moment(voucherDetail.program.voucher.time_end.value).format("DD/MM/YYYY")}</h6>
                  ) : (
                    <h6 style={{ margin: 0 }}>Không hết hạn</h6>
                  )} */}
                  {voucherDetail?.program?.voucher?.voucher_limit?.is_voucher_limit ? (
                    <h6 style={{ margin: 0 }}>
                      {moment(voucherDetail.create_on)
                        .add(voucherDetail.program.voucher.voucher_limit?.value || 0, "days")
                        .format("DD/MM/YYYY")}
                    </h6>
                  ) : (
                    <h6 style={{ margin: 0 }}>Không hết hạn</h6>
                  )}
                </div>
                <div className="hr" style={{ borderTop: `0.3px solid ${colors.outlineVariant}`, margin: "8px 0" }}></div>
                {(voucherDetail.program.type === "lucky_spin" || voucherDetail.program.type === "open_giftbox") && (
                  <div>
                    <p>Voucher: {voucherDetail.voucher_name}</p>
                  </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: voucherDetail.program.description }} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VoucherDetailModal;
