import React, { createRef, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
// import required modules
import { FreeMode, Pagination, Navigation } from "swiper/modules";
import { Page, Button, Text, Modal, Box, Icon } from "zmp-ui";
// import ReactPlayer from "react-player";

function AttachmentSlider({ attachmentList, attachmentIndex, visible, setVisible }) {
  console.log(attachmentIndex);
  const [isPlay, setIsPlay] = useState(true);
  const vidRef = createRef();
  return (
    <>
      {visible && (
        <Modal
          className="attachment-preview"
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
        >
          <div className="d-flex w-100 justify-content-end">
            <div
              className="p-2"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icon icon="zi-close" size={24} style={{ color: "white" }} />
            </div>
          </div>
          <Swiper
            className="mySwiper"
            slidesPerView={1}
            spaceBetween={16}
            initialSlide={attachmentIndex}
            pagination={true}
            modules={[Pagination]}
            style={{ height: "75vh" }}
            onSlideChange={(e) => {
              var item = attachmentList[e.activeIndex];
              if (item.slice(item.length - 3) === "mp4") {
                // vidRef.current.play();
              } else {
                vidRef.current.pause();
              }
            }}
          >
            {attachmentList.map((item) => {
              return (
                <SwiperSlide style={{ display: "flex", alignItems: "center" }}>
                  {item.slice(item.length - 3) === "mp4" ? (
                    <video ref={vidRef} src={item} controls={true} style={{ width: "100vw" }} autoPlay={true}></video>
                  ) : (
                    <img
                      style={{ width: "100%" }}
                      // role="presentation"
                      // onClick={() => {
                      //   setActiveIndex(index);
                      //   setVisible(true);
                      // }}
                      src={item}
                      // alt={img.alt}
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Modal>
      )}
    </>
  );
}

export default AttachmentSlider;
