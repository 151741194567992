import CartContext from "context/cart/CartContext";
import React, { useEffect } from "react";
// import { useEffect } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { colors } from "theme-config";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import api from "api";
import { getStorage } from "common/zalo_storage/ZaloStorage";

function ShippingAddress({ data, mode }) {
  // console.log(data);
  const navigate = useNavigate();
  const renderCustomerName = () => {
    var name = "";
    if (data.first_name) {
      name = data.first_name;
    } else if (data.last_name) {
      name += " " + data.last_name;
    } else if (data.name) {
      name = data.name;
    } else {
      name = data.full_name;
    }
    // console.log(name);
    return name?.replace("null", "").trim();
  };

  const context = useContext(CartContext);

  const openAddressList = async () => {
    try {
      var { token } = await getStorage({ keys: ["token"] });
      if (token) {
        navigate(`/${window.shop_sub_path}/AddressList`);
      } else {
        //hiện modal xin phép cấp quyền
        navigate(`/${window.shop_sub_path}/AddressForm`, { state: { mode: mode } });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultData = () => {
    api
      .post("guest/getlistaddress")
      .then((res) => {
        var find = res.find((item) => {
          return item.is_default;
        });
        if (find) {
          context.addShippingAddress(find);
        }
      })
      .catch((error) => {
        context.addShippingAddress({});
      });
  };

  useEffect(() => {
    if (mode === "checkout" && (!context.shippingAddress || (context.shippingAddress && Object.keys(context.shippingAddress).length === 0))) {
      getDefaultData();
    }
  }, [mode]);

  return (
    <div
      className="d-flex p-2 bg-white"
      style={{ gap: 8 }}
      onClick={() => {
        if (mode === "checkout") {
          openAddressList();
        }
      }}
    >
      <svg style={{ marginTop: 2 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M12.5 14H9.40875C9.92803 13.5363 10.4177 13.0406 10.875 12.5156C12.5906 10.5425 13.5 8.4625 13.5 6.5C13.5 5.04131 12.9205 3.64236 11.8891 2.61091C10.8576 1.57946 9.45869 1 8 1C6.54131 1 5.14236 1.57946 4.11091 2.61091C3.07946 3.64236 2.5 5.04131 2.5 6.5C2.5 8.4625 3.40688 10.5425 5.125 12.5156C5.58226 13.0406 6.07197 13.5363 6.59125 14H3.5C3.36739 14 3.24021 14.0527 3.14645 14.1464C3.05268 14.2402 3 14.3674 3 14.5C3 14.6326 3.05268 14.7598 3.14645 14.8536C3.24021 14.9473 3.36739 15 3.5 15H12.5C12.6326 15 12.7598 14.9473 12.8536 14.8536C12.9473 14.7598 13 14.6326 13 14.5C13 14.3674 12.9473 14.2402 12.8536 14.1464C12.7598 14.0527 12.6326 14 12.5 14ZM3.5 6.5C3.5 5.30653 3.97411 4.16193 4.81802 3.31802C5.66193 2.47411 6.80653 2 8 2C9.19347 2 10.3381 2.47411 11.182 3.31802C12.0259 4.16193 12.5 5.30653 12.5 6.5C12.5 10.0769 9.03312 13.0625 8 13.875C6.96688 13.0625 3.5 10.0769 3.5 6.5ZM10.5 6.5C10.5 6.00555 10.3534 5.5222 10.0787 5.11107C9.80397 4.69995 9.41352 4.37952 8.95671 4.1903C8.49989 4.00108 7.99723 3.95157 7.51227 4.04804C7.02732 4.1445 6.58186 4.3826 6.23223 4.73223C5.8826 5.08186 5.6445 5.52732 5.54804 6.01227C5.45157 6.49723 5.50108 6.99989 5.6903 7.45671C5.87952 7.91352 6.19995 8.30397 6.61107 8.57867C7.0222 8.85338 7.50555 9 8 9C8.66304 9 9.29893 8.73661 9.76777 8.26777C10.2366 7.79893 10.5 7.16304 10.5 6.5ZM6.5 6.5C6.5 6.20333 6.58797 5.91332 6.7528 5.66664C6.91762 5.41997 7.15189 5.22771 7.42597 5.11418C7.70006 5.00065 8.00166 4.97094 8.29264 5.02882C8.58361 5.0867 8.85088 5.22956 9.06066 5.43934C9.27044 5.64912 9.4133 5.91639 9.47118 6.20736C9.52906 6.49834 9.49935 6.79994 9.38582 7.07403C9.27229 7.34811 9.08003 7.58238 8.83335 7.7472C8.58668 7.91203 8.29667 8 8 8C7.60218 8 7.22064 7.84196 6.93934 7.56066C6.65804 7.27936 6.5 6.89782 6.5 6.5Z"
          fill={colors.primary}
        />
      </svg>
      <div className="d-flex flex-fill justify-content-between" style={{ fontSize: 14 }}>
        <div className="d-flex flex-column">
          <span style={{ fontWeight: 500 }}>Địa chỉ người nhận:</span>
          {data ? (
            <>
              <span style={{ color: colors.outline }}>{renderCustomerName()}</span>
              <span style={{ color: colors.outline }}>{data.phone}</span>
              <span style={{ color: colors.outline }}>
                {data.address1}
                {data.ward ? `, ${data.ward}` : ""}
                {data.district ? `, ${data.district}` : ""}
                {data.province ? `, ${data.province}` : ""}
              </span>
            </>
          ) : context.shippingAddress && Object.keys(context.shippingAddress).length > 0 ? (
            <>
              <span style={{ color: colors.outline }}>{context.shippingAddress.name}</span>
              <span style={{ color: colors.outline }}>{context.shippingAddress.phone}</span>
              <span style={{ color: colors.outline }}>
                {context.shippingAddress.address}
                {context.shippingAddress.ward ? `, ${context.shippingAddress.ward}` : ""}
                {context.shippingAddress.district ? `, ${context.shippingAddress.district}` : ""}
                {context.shippingAddress.province ? `, ${context.shippingAddress.province}` : ""}
              </span>
            </>
          ) : (
            <span style={{ color: colors.outline, fontSize: 12 }}>Chọn địa chỉ</span>
          )}
        </div>
      </div>
      {mode === "checkout" && (
        <div className="align-self-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_2029_9948)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4712 7.52879C10.5962 7.65381 10.6664 7.82334 10.6664 8.00012C10.6664 8.1769 10.5962 8.34644 10.4712 8.47145L6.6999 12.2428C6.63841 12.3065 6.56484 12.3572 6.48351 12.3922C6.40217 12.4271 6.31469 12.4455 6.22617 12.4463C6.13765 12.4471 6.04986 12.4302 5.96793 12.3967C5.886 12.3631 5.81157 12.3136 5.74897 12.251C5.68638 12.1885 5.63688 12.114 5.60336 12.0321C5.56983 11.9502 5.55297 11.8624 5.55374 11.7739C5.55451 11.6853 5.5729 11.5979 5.60784 11.5165C5.64277 11.4352 5.69356 11.3616 5.75724 11.3001L9.05724 8.00012L5.75724 4.70012C5.6358 4.57439 5.5686 4.40598 5.57012 4.23119C5.57164 4.05639 5.64175 3.88918 5.76536 3.76557C5.88896 3.64197 6.05617 3.57186 6.23097 3.57034C6.40577 3.56882 6.57417 3.63601 6.6999 3.75745L10.4712 7.52879Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_2029_9948">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
}

export default ShippingAddress;
