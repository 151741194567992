import React from "react";
import { Box, Text } from "zmp-ui";
import { useNavigate } from "react-router-dom";

function AddressPicker() {
  const navigate = useNavigate();
  const openAddressForm = (mode) => {
    navigate(`/${window.shop_sub_path}/AddressForm`, { state: { mode: mode } });
  };
  return (
    <Box
      flex
      flexDirection="row"
      alignItems="center"
      p={2}
      style={{ background: "white", gap: 8 }}
      onClick={() => {
        openAddressForm("ADD");
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6668 8.66536H8.66683V12.6654H7.3335V8.66536H3.3335V7.33203H7.3335V3.33203H8.66683V7.33203H12.6668V8.66536Z" fill="black" />
      </svg>
      <Text style={{ fontSize: 14, fontWeight: 500 }}>Địa chỉ người nhận</Text>
      <svg className="ms-auto" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clip-path="url(#clip0_2010_6464)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4712 7.52879C10.5962 7.65381 10.6664 7.82334 10.6664 8.00012C10.6664 8.1769 10.5962 8.34644 10.4712 8.47145L6.6999 12.2428C6.63841 12.3065 6.56484 12.3572 6.48351 12.3922C6.40217 12.4271 6.31469 12.4455 6.22617 12.4463C6.13765 12.4471 6.04986 12.4302 5.96793 12.3967C5.886 12.3631 5.81157 12.3136 5.74897 12.251C5.68638 12.1885 5.63688 12.114 5.60336 12.0321C5.56983 11.9502 5.55297 11.8624 5.55374 11.7739C5.55451 11.6853 5.5729 11.5979 5.60784 11.5165C5.64277 11.4352 5.69356 11.3616 5.75724 11.3001L9.05724 8.00012L5.75724 4.70012C5.6358 4.57439 5.5686 4.40598 5.57012 4.23119C5.57164 4.05639 5.64175 3.88918 5.76536 3.76557C5.88896 3.64197 6.05617 3.57186 6.23097 3.57034C6.40577 3.56882 6.57417 3.63601 6.6999 3.75745L10.4712 7.52879Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_2010_6464">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}

export default AddressPicker;
