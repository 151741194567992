import TopAppBar from "components/common/top_app_bar";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "api";
import { assets, colors, styles } from "theme-config";
import ReactStars from "react-rating-stars-component";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import SelectStarSheet from "./SelectStarSheet";
// import ReactPlayer from "react-player";
import AttachmentSlider from "./AttachmentSlider";

function RatingProductList() {
  const { product_id } = useParams();
  const [avgReview, setAvgReview] = useState(null);
  const [filterReview, setFilterReview] = useState({ type: "all" });
  const [reviewList, setReviewList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [sheetVisible, setSheetVisible] = useState(false);
  const [attachmentList, setAttachmentList] = useState([]);
  const [attachmentIndex, setAttachmentIndex] = useState();
  const [isAttachmentPreview, setIsAttachmentReview] = useState(false);

  useEffect(() => {
    getAvgReview();
  }, []);

  const getAvgReview = () => {
    api
      .post("guestlogin/getaverageproductreview", { shop_name: window.shop_id, product_id: product_id })
      .then((res) => {
        if (res.avgReview.toString().length > 1) {
          setAvgReview(res.avgReview.toString().slice(0, 3));
        } else {
          setAvgReview(res.avgReview);
        }
      })
      .catch((error) => {
        setAvgReview(0);
      });
  };

  const getReviewList = () => {
    api
      .post("guestlogin/getproductreviewlist", { shop_name: window.shop_id, product_id: product_id, filter: filterReview, skip: reviewList.length, limit: 20 })
      .then((res) => {
        if (reviewList.length === 0) {
          setReviewList(res);
          setLoading(false);
          if (res.length < 20) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        } else {
          if (res.length > 0) {
            setTimeout(() => {
              setReviewList(reviewList.concat(res));
              setLoading(false);
            }, 500);
            if (res.length < 20) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setLoading(false);
            setHasMore(false);
          }
        }
        // setAvgReview(res.avgReview);
      })
      .catch((error) => {
        setReviewList([]);
        setHasMore(false);
        // setAvgReview(0);
      });
  };

  const openAttachmentPreview = (videos, images, index) => {
    console.log("index", index);
    var list = videos.concat(images);

    setAttachmentList(list);
    setAttachmentIndex(index);
    setIsAttachmentReview(true);
  };

  const renderContent = () => {
    return (
      <div>
        <InfiniteScroll
          dataLength={reviewList.length} //This is important field to render the next data
          next={getReviewList}
          hasMore={hasMore}
          height={"calc(100vh - 115px - 100vw/3 - 10px)"}
          //   height={600}
          //   style={{ paddingBottom: 56 }}
          loader={
            <div className="d-flex justify-content-center m-2">
              <div className="spinner-border" style={{ color: colors.primary, width: 24, height: 24 }} role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          }
        >
          <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            {reviewList.map((item, index) => {
              return (
                <>
                  <hr className="mt-0 mb-0 w-100" style={{ color: colors.outlineVariant, opacity: 1, minWidth: 16, borderTop: "1px solid" }}></hr>
                  <div className="d-flex flex-column justify-content-center p-2" style={{ gap: 16 }}>
                    <div className="d-flex flex-row align-items-center" style={{ gap: 8 }}>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          background: colors.primary,
                          backgroundImage: item.customer_avatar ? `url(${item.customer_avatar})` : "unset",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          height: 32,
                          width: 32,
                          borderRadius: "50%",
                        }}
                      >
                        {!item.customer_avatar && (
                          <span style={{ color: colors.onPrimary }}>{item.customer_name?.replace("null", "").trim()?.slice(0, 1).toUpperCase()}</span>
                        )}
                      </div>
                      <div className="d-flex flex-column">
                        <span>{item.customer_name?.replace("null", "").trim()}</span>
                        <ReactStars count={5} value={item.star} edit={false} size={12} activeColor={"#FFBB33"} />
                      </div>
                    </div>
                    <div className="d-flex flex-column" style={{ gap: 8 }}>
                      {item.is_full_comment || item.comment.length < 300 ? (
                        <span>{item.comment}</span>
                      ) : (
                        <span>
                          <span style={{ marginRight: 4 }}>{item.comment.slice(0, 300)}...</span>
                          <span
                            style={{ color: colors.primary }}
                            onClick={() => {
                              //   item.is_full_comment = true;
                              var newReviewList = [...reviewList];
                              newReviewList[index].is_full_comment = true;
                              setReviewList(newReviewList);
                            }}
                          >
                            Xem thêm
                          </span>
                        </span>
                      )}

                      {(item.images.length > 0 || item.videos.length > 0) && (
                        <div className="row gx-2 gy-2" style={{ width: "calc(100vw - 8px)" }}>
                          {item.videos.length > 0 &&
                            item.videos.map((video, indexVideo) => {
                              return (
                                <div className="col-4">
                                  <div
                                    class="card"
                                    style={{
                                      width: "100%",
                                      fontSize: 14,
                                      borderRadius: 0,
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                      // opacity: is_render ? 1 : 0.5,
                                    }}
                                  >
                                    {/* <ReactPlayer
                                      //   className="mini-video"
                                      width={"calc(100vw / 3 - 12px)"}
                                      height={"calc(100vw / 3 - 12px)"}
                                      url={video}
                                      //   playing={true}
                                      //   controls
                                      playing={true}
                                      loop={true}
                                      //   playIcon={true}
                                      onClick={() => {
                                        openAttachmentPreview(item.videos, item.images ? item.images : [], index);
                                      }}
                                    ></ReactPlayer> */}

                                    <video
                                      src={video}
                                      // preload="auto"
                                      autoplay={true}
                                      loop={true}
                                      controls={false}
                                      style={{ width: "calc(100vw / 3 - 12px)", height: "calc(100vw / 3 - 12px)" }}
                                      onClick={() => {
                                        openAttachmentPreview(item.videos, item.images ? item.images : [], indexVideo);
                                      }}
                                    ></video>
                                    <div
                                      style={{
                                        position: "absolute",
                                        bottom: 0,
                                        width: "calc(100vw / 3 - 12px)",
                                        // height: 32,
                                        backgroundColor: "rgba(0, 0, 0, 0.5)",

                                        padding: 4,
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        class="bi bi-camera-video-fill"
                                        viewBox="0 0 16 16"
                                        style={{ color: "rgba(255,255,255, 0.75)" }}
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              );
                              // }
                            })}
                          {item.images.map((img, indexImage) => {
                            return (
                              <div className="col-4">
                                <div
                                  class="card"
                                  style={{
                                    width: "100%",

                                    fontSize: 14,
                                    borderRadius: 0,
                                  }}
                                  onClick={() => {
                                    openAttachmentPreview(
                                      item.videos ? item.videos : [],
                                      item.images,
                                      item.videos ? indexImage + item.videos.length : indexImage
                                    );
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "calc(100vw / 3 - 12px)",
                                      backgroundImage: `url(${img})`,
                                      backgroundSize: "cover",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            );
                            // }
                          })}
                        </div>
                      )}
                    </div>
                    <span style={{ fontSize: 12, color: colors.outlineVariant }}>{moment(item.created_date).format("DD-MM-YYYY hh:mm")}</span>
                  </div>
                </>
              );
              // }
            })}
          </div>
        </InfiniteScroll>
      </div>
    );
  };

  const changeFilter = (type) => {
    if (type === "star") {
      setSheetVisible(true);
    } else {
      setLoading(true);
      setFilterReview({ type: type });
    }
  };

  useEffect(() => {
    if (filterReview.type) {
      getReviewList();
    }
  }, [filterReview]);

  const onChange = (star) => {
    setLoading(true);
    setReviewList([]);
    setFilterReview({});
    setTimeout(() => {
      setFilterReview({ type: "star", star: star });
    }, 1);
  };
  return (
    <div style={{ minHeight: "100vh", background: "white" }}>
      <TopAppBar mode="back" title="Đánh giá" />
      <div className="d-flex flex-column p-2" style={{ gap: 8, fontSize: 14 }}>
        {avgReview !== null && (
          <div className="d-flex flex-row align-items-center" style={{ backgroundColor: "#F2F0F4", borderRadius: 8, gap: 8, padding: "10px 8px" }}>
            <div className="d-flex flex-row align-items-center">
              <span style={{ fontSize: 20, fontWeight: 500 }}>{avgReview}</span>/5
            </div>
            <ReactStars count={5} value={avgReview} edit={false} size={16} activeColor={"#FFBB33"} />
          </div>
        )}

        <div className="d-flex flex-row align-items-center" style={{ gap: 8 }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              height: "calc((100vw - 30px)/3)",
              width: "calc((100vw - 30px)/3)",
              backgroundColor: "#F2F0F4",
              borderRadius: 8,
              border: filterReview.type === "all" ? `1px solid ${colors.primary}` : "unset",
            }}
            onClick={() => {
              setReviewList([]);
              changeFilter("all");
            }}
          >
            <span style={{ color: filterReview.type === "all" && colors.primary }}>Tất cả</span>
          </div>
          <div
            className="d-flex align-items-center justify-content-center text-center p-3"
            style={{
              height: "calc((100vw - 30px)/3)",
              width: "calc((100vw - 30px)/3)",
              backgroundColor: "#F2F0F4",
              borderRadius: 8,
              border: filterReview.type === "attachment" ? `1px solid ${colors.primary}` : "unset",
            }}
            onClick={() => {
              setReviewList([]);
              changeFilter("attachment");
            }}
          >
            <span style={{ color: filterReview.type === "attachment" && colors.primary }}>Có hình ảnh/video</span>
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-center p-3"
            style={{
              height: "calc((100vw - 30px)/3)",
              width: "calc((100vw - 30px)/3)",
              backgroundColor: "#F2F0F4",
              borderRadius: 8,
              border: filterReview.type === "star" ? `1px solid ${colors.primary}` : "unset",
            }}
            onClick={() => {
              //   setReviewList([]);
              changeFilter("star");
            }}
          >
            <span style={{ color: filterReview.type === "star" && colors.primary }}>Sao</span>
            {filterReview.star && <ReactStars count={5} value={filterReview.star} edit={false} size={12} activeColor={"#FFBB33"} />}
          </div>
        </div>
      </div>
      <div>
        {reviewList && reviewList.length > 0 ? (
          renderContent()
        ) : !loading ? (
          <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 175px - 100vw/3 - 10px)" }}>
            <img src={assets.empty_bg.img} alt="" />
            <div className="fw-bold text-center" style={{ ...styles.textSecondary, fontSize: 20 }}>
              Chưa có đánh giá
            </div>
          </div>
        ) : (
          <div class="d-flex justify-content-center align-items-center m-2" style={{ height: "calc(100vh - 96px - 16px)" }}>
            <div class="spinner-border" style={{ color: colors.primary, width: 24, height: 24 }} role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <SelectStarSheet setSheetVisible={setSheetVisible} sheetVisible={sheetVisible} onChange={onChange} />
      <AttachmentSlider
        attachmentList={attachmentList}
        attachmentIndex={attachmentIndex}
        visible={isAttachmentPreview}
        setVisible={setIsAttachmentReview}
      ></AttachmentSlider>
    </div>
  );
}

export default RatingProductList;
