import React, { useCallback, useEffect, useState } from "react";
import { colors, assets, styles } from "theme-config/index";
import { getUserInfo, configAppView } from "zmp-sdk/apis";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "zmp-ui";

/**
 * Component Header Mini App
 * @param {string} mode mode hiển thị header (profile || back || search)
 * @param {string} title
 */
const TopAppBar = ({ mode, title, greeting }) => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({
    name: "Cube Loyalty",
    avatar: "https://storage.googleapis.com/cubeloyalty_asia/logo192.png",
  });
  const hellowork = [
    "Cười to, yêu nhiều! 😄❤️",
    "Tận hưởng, vui sống! 🌟🎉",
    "Hạnh phúc, thành công! 🌈🚀",
    "Yêu thương, bình an! 💕🕊️",
    "Vui vẻ, mạnh khỏe! 😃💪",
    "Niềm vui, tươi sáng! 🌞🌼",
    "Cùng nhau, hãy cười! 👭😄",
    "May mắn, hạnh phúc! 🍀😊",
    "Thành công, tỏa sáng! 🌟💫",
    "Cuộc sống, mơ ước! 🌌🌠",
    "Bình yên, tình bạn! 🕊️👫",
    "Niềm tin, vượt qua! 🙏🚀",
    "Hãy cười, vui vẻ! 😃😄",
    "Hạnh phúc, vui vẻ! 😊🌈",
    "Mỉm cười, hạnh phúc! 😃💖",
    "Đắm say, kỳ diệu! 😍✨",
    "Yêu bạn, cảm ơn! ❤️🙏",
    "Hãy tin, thành công! 🌟🙌",
    "Niềm vui, nắng mới! 🌞🌻",
    "Cùng nhau, bay cao! 🎈🕊️",
    "Năng lượng, mạnh mẽ! 💪⚡",
    "Hãy yêu, vui vẻ! 😍😊",
    "Sáng ngời, tươi cười! ✨😄",
    "Tình bạn, vui vẻ! 👫😃",
    "Vui vẻ, mỉm cười! 😄😊",
    "Niềm tin, thắp sáng! 🌟🕯️",
    "Hãy sống, hãy cười! 🌞😄",
    "Hạnh phúc, may mắn! 💖🍀",
    "Nắng vàng, cuộc sống! 🌞🌼",
    "Yêu thương, hòa bình! ❤️🕊️",
  ];

  const onBack = () => {
    if (window.history.length === 1) {
      //neu truoc do ko co lich su thi tra ve home
      navigate(`/${window.shop_sub_path}/Home`);
    } else {
      navigate(-1);
    }
  };
  /**
   * Hàm lấy thông tin user từ Zalo
   * @author huy
   */
  const getUser = async () => {
    try {
      const { userInfo } = await getUserInfo({});
      // console.log("usinfo", JSON.stringify(userInfo));
      // console.log("info", JSON.stringify(info));
      if (userInfo || JSON.stringify(userInfo) !== JSON.stringify(info)) {
        setInfo(userInfo);
      }
    } catch (error) {
      // xử lý khi gọi api thất bại
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  /**
   * Hàm render top bar dựa theo mode truyền vào
   * @author huy
   */
  const renderItemTopBar = () => {
    switch (mode) {
      case "back":
        return (
          <>
            <button onClick={onBack} className="btn" style={{ padding: 0, background: colors.primary }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Icons/left">
                  <path id="Vector" d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z" fill={colors.onPrimary} />
                </g>
              </svg>
            </button>
            <span className="align-self-center" style={{ color: colors.onPrimary, fontSize: 16 }}>
              {title}
            </span>
          </>
        );
      case "home":
        return (
          <>
            {/* <button onClick={onBack} className="btn" style={{ padding: 0, background: colors.primary }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Icons/left">
                  <path id="Vector" d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z" fill={colors.onPrimary} />
                </g>
              </svg>
            </button> */}
            <span className="align-self-center text-center" style={{ color: colors.onPrimary, fontSize: 16 }}>
              {title}
            </span>
          </>
        );
      case "search":
        break;
      default:
        return (
          <>
            <div className="d-flex align-items-center gap-2">
              <img src={info?.avatar} alt="Logo" style={{ width: 36, height: 36 }} className="rounded-pill" />
              <span className="align-self-center" style={{ color: colors.onPrimary, fontSize: 14 }}>
                {`Chào ${info?.name}`}
                <br />
                <span style={{ fontSize: 12 }}>{greeting || hellowork[(Math.random() * hellowork.length) | 0]}</span>
              </span>
            </div>
          </>
        );
    }
  };

  return (
    <nav className="navbar sticky-top" style={{ background: colors.primary, padding: "12px 8px", zIndex: 999 }}>
      {/* <a className="navbar-brand" href="#"> */}
      <div className="d-flex flex-row align-item-center" style={{ gap: 4, width: "100%" }}>
        {renderItemTopBar()}
      </div>
    </nav>
  );
};
export default TopAppBar;
