import React, { useEffect, useState } from "react";
import api from "api";
import TopAppBar from "components/common/top_app_bar";
import { fomatPoint } from "components/common/converNumber";
import { styles } from "theme-config";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";

const MemberBenefit = () => {
  const navigate = useNavigate();
  const [tier, setTier] = useState();
  const getTier = async () => {
    await api
      .post("/guest/getTier")
      .then((data) => {
        var newData = data?.tiers?.sort((a, b) => {
          return a?.value - b?.value || 0;
        });
        setTier(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const showTierDetail = (id) => {
    navigate(`/${window.shop_sub_path}/TierDetail/${id}`);
  };
  useEffect(() => {
    getTier();
  }, []);
  return (
    <>
      <div className="container-fluid m-0 p-0" style={{ height: "100vh", ...styles.bgWhite }}>
        <TopAppBar mode="back" title="Quyền lợi thành viên" />
        <div className="container-fluid d-flex flex-column m-0 p-2 gap-1">
          <div className="d-flex justify-content-between align-items-center p-2 gap-3 border-bottom border-1" onClick={() => showTierDetail("default")}>
            <div>
              <p style={{ fontSize: 22, ...styles.textOnSurfaceVariant, fontWeight: 400 }}>Mới</p>
              <p className="m-0" style={{ fontSize: 16, ...styles.textPrimary, fontWeight: 500 }}>
                Điểm cần có: 0
              </p>
            </div>
            <div className="d-flex">
              <div
                style={{ minWidth: 40, height: 40, ...styles.bgPrimary, ...styles.textWhite }}
                className="rounded-circle d-flex justify-content-center align-items-center"
              >
                <span>Mới</span>
              </div>
            </div>
          </div>
          {tier && tier?.length > 0
            ? tier.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="d-flex justify-content-between align-items-center p-2 gap-3 border-bottom border-1"
                      onClick={() => showTierDetail(item?.id)}
                    >
                      <div style={{ fontSize: 22, ...styles.textOnSurfaceVariant }}>
                        <p style={{ fontSize: 22, ...styles.textOnSurfaceVariant, fontWeight: 400 }}>{item?.name}</p>
                        <p className="m-0" style={{ fontSize: 16, ...styles.textPrimary, fontWeight: 500 }}>
                          Điểm cần có: {fomatPoint(item?.value || 0)}
                        </p>
                      </div>
                      <img src={item?.logo} style={{ minWidth: 40, height: 40 }} className="rounded-pill" />
                    </div>
                  </React.Fragment>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};
export default MemberBenefit;
