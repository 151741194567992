import VoucherDivider from "components/common/VoucherDivider";
import React, { useEffect } from "react";
import { styles, colors } from "theme-config";
import moment from "moment";

function VoucherItem({ item, showDetail }) {
  const expiryDate = (dateExpiry) => {
    var a = moment(dateExpiry);
    var b = moment();
    var numberDate = a.diff(b, "days");
    if (numberDate > 0) return `Hết hạn trong ${numberDate} ngày`;
    var numberHour = Math.floor(a.diff(b, "hours", true));
    var numberMinute = Math.floor(a.diff(b.add(numberHour,"hours"), "minutes", true));
    if (numberHour > 0&& numberMinute > 0) return `Hết hạn trong ${numberHour} giờ ${numberMinute>0?`${numberMinute} phút`:""}`;
    return "Hết hạn";
  };

  return (
    <div
    className="d-flex flex-row"
      onClick={() => {
        showDetail(item);
      }}
    >
      <div style={{ background: "white", borderTopLeftRadius: 12, borderBottomLeftRadius: 12, padding: 8, position: "relative" }}>
        <img src={item.program.logo} style={{ width: 100, height: 100, borderRadius: 12 }} alt="" />
      </div>
      <div style={{ background: "white" }}>
        <VoucherDivider vertical={true} width={16} height={116} background={styles.mainBackground} />
      </div>
      <div
        className="d-flex flex-column"
        style={{ padding: 8, gap: 8, background: "white", width: "100%", borderTopRightRadius: 12, borderBottomRightRadius: 12 }}
      >
        <div>
          <span style={{ background: colors.primary, padding: "4px 10px", fontSize: 11, color: colors.onPrimary, fontWeight: 500, borderRadius: 2 }}>
            Mã giảm giá
          </span>
        </div>

        {item.program.name.length > 40 ? (
          <text style={{ fontSize: 12 }}>{item.program.name.slice(0, 40) + "..."}</text>
        ) : (
          <text style={{ fontSize: 12 }}>{item.program.name}</text>
        )}
        <text style={{ fontSize: 12, ...styles.textPrimary }}>
          {/* {item?.program?.voucher?.time_end?.is_time_end ? expiryDate(item.program.voucher.time_end.value) : "Không hết hạn"} */}
          {item?.program?.voucher?.voucher_limit?.is_voucher_limit ? expiryDate(moment(item.create_on).add(item.program.voucher.voucher_limit?.value||0,"days").format()) : "Không hết hạn"}
        </text>
      </div>
      {/* </div> */}
    </div>
  );
}

export default VoucherItem;
