import TopAppBar from "components/common/top_app_bar";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "api";
import { Box, Text } from "zmp-ui";
import { assets, colors } from "theme-config";

function GameHistory() {
  const location = useLocation();
  const [listHistory, setListHistory] = useState([]);
  const getGameHistory = () => {
    api
      .post("guest/getgamehistory", { game_id: location.state.game_id })
      .then((res) => {
        if (res.length > 0) {
          var newList = res.sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());
          setListHistory(newList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGameHistory();
  }, []);

  const renderType = (type) => {
    switch (type) {
      case "point":
        return "Điểm";
      case "add_turn":
        return "Thêm lượt";
      case "voucher":
        return "Voucher";
      case "loss_turn":
        return "Mất lượt";
      default:
        break;
    }
  };
  return (
    <div>
      <TopAppBar mode="back" title="Lịch sử chơi game" />
      <Box flex flexDirection="column">
        {listHistory && listHistory.length > 0 ? (
          listHistory.map((item) => {
            return (
              <Box
                flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ borderBottom: `1px solid ${colors.outlineVariant}`, padding: "8px 16px", background: "white" }}
              >
                <Box flex flexDirection="column">
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>{item.title}</Text>
                  <Text style={{ fontSize: 14, color: colors.outline }}>{`Loại quà: ${renderType(item.type)}`}</Text>
                  <Text style={{ fontSize: 14, color: colors.outline }}>{`Giá trị: ${item.value}`}</Text>
                </Box>
                {/* <Box flex flexDirection="column" style={{ minWidth: 96 }}>
                  <Text
                    style={{ fontSize: 14, fontWeight: 500, color: colors.primary, textAlign: "end" }}
                    onClick={() => {
                      addTurn(item);
                    }}
                  >{`+ ${item.turns} lượt chơi`}</Text>
                </Box> */}
              </Box>
            );
          })
        ) : (
          <Box flex flexDirection="row" alignItems="center" justifyContent="center" style={{ height: "calc(100vh - 50.5px)" }}>
            <Box flex flexDirection="column">
              <img style={{ width: 248, height: 248 }} src={assets.empty_bg.img} className="rounded mx-auto d-block" alt="..." />
              <Text className="text-center" style={{ fontSize: 16, fontWeight: 500 }}>
                Chưa có lịch sử
              </Text>
              {/* <Text className="text-center" style={{ fontWeight: 500, color: colors.secondary }}>
              Hãy đổi điểm để nhận ưu đãi ngay nhé !!!
            </Text> */}
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default GameHistory;
