import React, { useState, useEffect } from "react";
import { colors, assets, styles } from "theme-config/index";
import api from "api";
import moment from "moment";
import TopAppBar from "components/common/top_app_bar";
import DropDownHistory from "./DropDownHistory";
import ListHistory from "./ListHistory";

const HistoryPoints = () => {
  const [applyHistoryDate, setApplyHistoryDate] = useState();
  useEffect(() => {
    if (applyHistoryDate) {
      // console.log("applyHistoryDate", applyHistoryDate);
    }
  }, [applyHistoryDate]);
  return (
    <>
      <div className="container-fluid p-0" style={{ minHeight: "calc(100vh - 60px)" }}>
        <TopAppBar mode="back" title="Lịch sử tích điểm" />
        <DropDownHistory applyHistoryDate={applyHistoryDate} setApplyHistoryDate={setApplyHistoryDate} />
        <ListHistory applyHistoryDate={applyHistoryDate} />
      </div>
    </>
  );
};
export default HistoryPoints;
