import api from "api";
window.zalo_mini_app = {
  theme: {
    colors: {
      primary: "#005ac1",
      onPrimary: "#ffffff",
      primaryContainer: "#d8e2ff",
      onPrimaryContainer: "#001a41",
      secondary: "#575e71",
      onSecondary: "#ffffff",
      secondaryContainer: "#dbe2f9",
      onSecondaryContainer: "#141b2c",
      tertiary: "#715573",
      onTertiary: "#ffffff",
      tertiaryContainer: "#fbd7fc",
      onTertiaryContainer: "#29132d",
      error: "#ba1a1a",
      onError: "#ffffff",
      errorContainer: "#ffdad6",
      onErrorContainer: "#410002",
      background: "#fefbff",
      onBackground: "#1b1b1f",
      surface: "#fefbff",
      onSurface: "#1b1b1f",
      surfaceVariant: "#e1e2ec",
      onSurfaceVariant: "#44474f",
      outline: "#75777f",
      outlineVariant: "#c4c6d0",
      shadow: "#000000",
      scrim: "#000000",
      inverseSurface: "#303033",
      inverseOnSurface: "#f2f0f4",
      inversePrimary: "#adc6ff",
    },
    assets: {
      login_bg: {
        img: "https://storage.googleapis.com/cubeloyalty_asia/web/6ead312e-6193-4908-bb12-254aa081e0e9.svg",
      },
      empty_bg: {
        img: "https://storage.googleapis.com/cubeloyalty_asia/web/79cf20cf-28c4-4583-975e-5eb8bdba77be.svg",
      },
      change_point_btn: {
        img: "https://storage.googleapis.com/cubeloyalty_asia/web/f573ad9b-8565-4c6d-92aa-1601ca53e6f2.svg",
      },
      voucher_btn: {
        img: "https://storage.googleapis.com/cubeloyalty_asia/web/d924a918-ef23-47c1-a435-6ca99fb09d58.svg",
      },
      history_btn: {
        img: "https://storage.googleapis.com/cubeloyalty_asia/web/0f732ff1-e7a2-4907-a3e2-401ab96be128.svg",
      },
      contact_btn: {
        img: "https://storage.googleapis.com/cubeloyalty_asia/web/82f424e5-b968-43af-b4d6-63b6e1128215.svg",
      },
    },
  },
};
// console.log(window.)
var pathName = window.location.pathname.split("/")[1];
window.shop_id = pathName.slice(0, pathName.length - 5);
// console.log(window.shop_id);
window.shop_sub_path = pathName;
// console.log(window.shop_sub_path);
//create portal javascript
//zalo ko support thêm thẻ div vào index.html nên phải dùng javascript
// Create a new div element
var divElement = document.createElement("div");
divElement.id = "portal-root";
divElement.textContent = "";
// Append the div to the body
document.body.appendChild(divElement);
//===============================================

await api
  .post("guestlogin/getthemeconfig", { shop_name: window.shop_id })
  .then((res) => {
    if (res) {
      window.zalo_mini_app.theme.colors = res.color;
      //homeblock dynammic render
      window.zalo_mini_app.theme.homeblock = res.homeblock;
      //
      window.zalo_mini_app.theme.config = res.config;

      Object.keys(window.zalo_mini_app.theme.assets).forEach((key) => {
        if (res[key].type === "system") {
          window.zalo_mini_app.theme.assets[key].img = res[key].system_img;
        } else {
          window.zalo_mini_app.theme.assets[key].img = res[key].custom_img;
        }
      });
    }
  })
  .catch((error) => {
    console.log(error);
  });

var { colors, assets, homeblock } = window.zalo_mini_app.theme;
/*
định nghĩa ra các style hay dùng của hệ thống 
ví dụ:
button primary=>btnPrimary
text primary=>textPrimary
*/
var styles = {
  mainBackground: {
    backgroundColor: "#f2f2f2",
  },
  btnPrimary: {
    backgroundColor: colors.primary,
    color: colors.onPrimary,
    fontWeight: 500,
  },

  btnBottomPrimary: {
    backgroundColor: colors.primary,
    color: "white",
    fontWeight: 500,
    fontSize: 14,
    height: 40,
    borderRadius: 12,
  },

  btnBottomError: {
    backgroundColor: colors.error,
    color: "white",
    fontWeight: 500,
    // fontSize: 14,
    // height: 40,
    // borderRadius: 12,
  },

  btnOnPrimary: {
    backgroundColor: colors.onPrimary,
    color: colors.primary,
    border: `1px solid ${colors.outline}`,
    fontWeight: 500,
    fontSize: 16,
    height: 44,
  },

  btnClose: {
    background: colors.secondaryContainer,
    color: colors.secondary,
    fontWeight: 500,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },

  btnConfirm: {
    background: colors.primary,
    color: colors.onPrimary,
    fontWeight: 500,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },

  btnDelete: {
    background: colors.error,
    color: colors.onPrimary,
    fontWeight: 500,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },

  btnDisabled: {
    backgroundColor: colors.onSurface + "1f",
    border: "none",
    color: colors.onSurface,
    fontWeight: 500,
  },
  textOnSecondaryContainer: {
    color: colors.onSecondaryContainer,
  },
  textOnSurfaceVariant: {
    color: colors.onSurfaceVariant,
  },
  textOnPrimary: {
    color: "black",
    fontWeight: 500,
    fontSize: 16,
    height: 24,
  },
  textCardEllipsis: {
    // whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // maxWidth: "18ch",
    fontSize: 14,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    // overflow: "hidden",
  },
  textOutline: {
    color: colors.outline,
  },
  texHeaderExplore: {
    color: "#2F394E",
    fontSize: 30,
  },
  textNeutral: {
    color: "#77777A",
  },
  textBlack: {
    color: "#000000",
  },
  textPrimary: {
    color: colors.primary,
  },
  textSuccess: {
    color: "#00C851",
  },
  textError: {
    color: colors.error,
  },
  textWhite: {
    color: "#fff",
  },
  textSecondary: {
    color: colors.secondary,
  },
  textOnSurfaceNormal: {
    color: colors.onSurface,
    fontWeight: 400,
    fontSize: 14,
  },
  textOnSurfaceTitle: {
    color: colors.onSurface,
    fontWeight: 500,
    fontSize: 16,
  },
  textOnSurface: {
    color: colors.onSurface,
  },
  bgSecondaryContainer: {
    backgroundColor: colors.secondaryContainer,
  },
  bgPrimaryContainer: {
    backgroundColor: colors.primaryContainer,
  },
  bgPrimary: {
    backgroundColor: colors.primary,
  },
  bgWhite: {
    backgroundColor: "#fff",
  },
  bgOutlineVariant: {
    backgroundColor: colors.outlineVariant,
  },
  bgWaring: {
    backgroundColor: "#FFBB33",
  },
  bgSurface: {
    backgroundColor: colors.surface,
  },
  bgSurfaceVariant: {
    backgroundColor: colors.surfaceVariant,
  },
  bgOnSurfaceVariant: {
    backgroundColor: colors.onSurfaceVariant,
  },
  modal: {
    display: "grid",
    alignItem: "center",
  },

  btnRoundedPill: {
    padding: "10px 24px",
    borderRadius: "24px",
  },

  btnOutline: {
    border: `1px solid ${colors.outline}`,
    color: colors.onSurface,
    fontWeight: 500,
  },

  btnText: {
    background: "white",
    fontWeight: 500,
  },
};

// async function loadConfig() {
//   await api.post("guest/getconfig", { shop_id: window.shop_id }).then((res) => {
//     consol
//   });
// }

// loadConfig();

export { colors, assets, styles, homeblock };
