import React, { useState, useEffect } from "react";
import TopAppBar from "components/common/top_app_bar";
import { colors, assets, styles } from "theme-config/index";
import QRCode from "./QR_Code";
import api from "api";
import moment from "moment";
import MyVoucher from "components/home_page/MyVoucher";
import PointLButtonList from "./Point_Button_List";

const AccumulatePoints = ({ greeting }) => {
  return (
    <div style={{ paddingBottom: 60 }}>
      <div className="container-fluid p-0">
        <TopAppBar greeting={greeting} />
        <div className="container-fluid px-2 pt-3 d-flex flex-column" style={{ gap: 16, ...styles.mainBackground }}>
          <QRCode />
          <PointLButtonList />
        </div>
        <div style={{ gap: 16, ...styles.mainBackground }}>
          <MyVoucher />
        </div>
      </div>
    </div>
  );
};
export default AccumulatePoints;
