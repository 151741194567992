import React, { useState, useEffect } from "react";
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import { colors, assets, styles } from "theme-config/index";
import api from "api";
import moment from "moment";
import VoucherItem from "components/voucher_list/VoucherItem";
import VoucherDetailModal from "components/voucher_list/VoucherDetailModal";
import ModalZaUI from "components/common/modal/ModalZaUI";
import { getStorage } from "common/zalo_storage/ZaloStorage";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";

const MyVoucher = () => {
  const navigate = useNavigate();
  const [lstVoucher, setLstvoucher] = useState([]);
  const [isToggleModal, setIsToggleModal] = useState(false);
  const [modalConfig, setModalConfig] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalZaUIConfig, setModalZaUIConfig] = useState({});

  const getlstVoucher = async () => {
    await api
      .post("/guest/getlstvoucher", { pagging: { skip: 0, limit: 2 }, used: false })
      .then((data) => {
        setLstvoucher(data.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const expiryDate = (dateexpiry) => {
  //   var a = moment(dateexpiry);
  //   var b = moment();
  //   var numberdate = a.diff(b, "days");
  //   if (numberdate > 0) return `Hết hạn trong ${numberdate} ngày`;
  //   var numberhour = a.diff(b, "hours", true);
  //   if (numberhour > 0) return `Hết hạn trong ${numberhour} ngày`;
  //   return "Hết hạn";
  // };

  const showDetail = (item) => {
    var modal = {
      type: "voucherDetail",
      onOk: null,
      name: item.program.name,
      voucher: item.program.voucher,
      voucherId: item.voucher_id,
      description: item.program.description,
      width: document.getElementById("voucherList").offsetWidth,
    };
    setModalConfig(modal);
    setIsToggleModal(!isToggleModal);
  };

  useEffect(() => {
    getlstVoucher();
  }, []);

  /**
   * Hàm xử lý khi nhấn nút chuyển hướng trang cần phải bắt người dùng đăng nhập
   * @param {*} page trang muốn chuyển đến
   */
  const handleOnClickButtonWhenUserNotLoggedIn = async (page) => {
    // getStorage({
    //   keys: ["token"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { token } = data;

    //     if (token) {
    //       navigate(`/${page}`)
    //     } else {
    //       //hiện modal xin phép cấp quyền
    //       showModalConfirmZalo();
    //     }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    var { token } = await getStorage({ keys: ["token"] });

    if (token) {
      navigate(`/${window.shop_sub_path}/${page}`);
    } else {
      //hiện modal xin phép cấp quyền
      showModalConfirmZalo();
    }
  };

  /**
   * Hàm hiển thị modal xin phép cấp quyền khi user chưa cho phép
   * @param {*} page trang muốn chuyển đến
   */
  const showModalConfirmZalo = () => {
    setModalZaUIConfig({ type: "login", reload: true });
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="container-fluid px-2 py-3" id="voucherList">
        <div className="d-flex justify-content-between">
          <span style={styles.textOnPrimary}>Ưu đãi của tôi</span>
          <div
            type="button"
            //className="border-0 rounded-2 px-3 py-1"
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              fontSize: 16,
              height: 24,
              ...styles.textPrimary,
              fontSize: 12,
            }}
            onClick={() => {
              handleOnClickButtonWhenUserNotLoggedIn("VoucherList");
            }}
          >
            Xem tất cả
          </div>
        </div>
        <div className="container-fluid p-0 mt-2">
          {!lstVoucher || lstVoucher?.length === 0 ? (
            <>
              <div className="d-flex justify-content-center p-0">
                <img src={assets.empty_bg.img} alt="Logo" style={{ width: 190, height: 170 }} />
              </div>
              <div className="fw-bold text-center" style={{ ...styles.textSecondary, fontSize: 20 }}>
                Chưa có ưu đãi nào
              </div>
              <div className="text-center" style={{ ...styles.textSecondary, fontSize: 14 }}>
                Hãy đổi điểm để nhận ưu đãi ngay nhé !!!
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-between m-0 flex-column" style={{ gap: 8 }}>
              {lstVoucher.length > 0 &&
                lstVoucher.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <VoucherItem item={item} showDetail={showDetail} />
                    </React.Fragment>
                  );
                })}
            </div>
          )}
        </div>
        <VoucherDetailModal isToggle={isToggleModal} modalConfig={modalConfig} setIsToggle={setIsToggleModal} />
        <ModalZaUI isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalConfig={modalZaUIConfig} />
      </div>
    </>
  );
};
export default MyVoucher;
