import React, { useEffect, useState, useRef } from "react";
import { colors, assets, styles } from "theme-config";
import api from "api";

const ListCategory = ({ select, setSelect, showAll = true, stylesBgButton = styles.mainBackground }) => {
  const [listcategory, setListCategory] = useState();
  const handleOnclickChip = (type) => {
    setSelect(type);
  };
  const getListCategory = async () => {
    await api
      .post("/guestlogin/getblogcategories", { shop_name: window.shop_id })
      .then((data) => {
        console.log("res", data);
        setListCategory(data);
        if (!select) {
          setSelect(data[0].id);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getListCategory();
  }, [select]);
  return (
    <div className="container-fluid p-0 overflow-auto">
      <div className="d-flex flex-row flex-nowrap gap-2" style={{ fontSize: 14 }}>
        {showAll ? (
          <button
            type="button"
            className="border-0 rounded-3"
            style={{
              whiteSpace: "nowrap",
              ...styles.textOnSecondaryContainer,
              minWidth: 120,
              padding: "6px 16px",
              ...(select === "all" ? styles.bgSecondaryContainer : stylesBgButton),
              fontWeight: 500,
            }}
            onClick={() => handleOnclickChip("all")}
          >
            Tất cả
          </button>
        ) : (
          ""
        )}
        {listcategory ? (
          listcategory.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <button
                  type="button"
                  className="border-0 rounded-3"
                  style={{
                    flex: "0 0 auto",
                    whiteSpace: "nowrap",
                    ...styles.textOnSecondaryContainer,
                    minWidth: 120,
                    padding: "6px 16px",
                    ...(select === item.id ? styles.bgSecondaryContainer : stylesBgButton),
                    fontWeight: 500,
                  }}
                  onClick={() => handleOnclickChip(item.id)}
                >
                  {item.name}
                </button>
              </React.Fragment>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ListCategory;
