import React, { useEffect, useState } from "react";
import { colors } from "theme-config";

function VoucherDivider({ width, height, horizontal, vertical, background }) {
  const [repeatTime, setRepeatTime] = useState([]);
  useEffect(() => {
    const arr = [];
    if (horizontal) {
      for (let index = 0; index < parseInt(((width - height - height / 3) / (height / 3)).toString().split(".")[0]); index++) {
        arr.push(index);
      }
    } else {
      for (let index = 0; index < parseInt(((height - width - width / 3) / (width / 3)).toString().split(".")[0]); index++) {
        arr.push(index);
      }
    }

    setRepeatTime(arr);
  }, [width]);
  const renderDottedLine = () => {
    if (horizontal) {
      return <div style={{ height: height / 6, width: height / 6, background: background, borderRadius: height / 6 }}></div>;
    } else {
      return <div style={{ height: width / 6, width: width / 6, ...background, borderRadius: width / 6 }}></div>;
    }
  };

  return (
    <>
      {horizontal && (
        <div className="d-flex flex-row justify-content-between" style={{ width: width, height: height }}>
          <div
            style={{ height: height, width: height / 2, background: background, borderTopRightRadius: height / 2, borderBottomRightRadius: height / 2 }}
          ></div>
          <div style={{ height: height, width: width - height, padding: height / 6 }} className="d-flex flex-row justify-content-between align-items-center">
            {repeatTime.length > 0 &&
              repeatTime.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {renderDottedLine()}
                  </React.Fragment>
                );
              })}
          </div>
          <div style={{ height: height, width: height / 2, background: background, borderTopLeftRadius: height / 2, borderBottomLeftRadius: height / 2 }}></div>
        </div>
      )}

      {vertical && (
        <div className="d-flex flex-column" style={{ width: width, height: height }}>
          <div style={{ height: width / 2, width: width, ...background, borderBottomRightRadius: width / 2, borderBottomLeftRadius: width / 2 }}></div>
          <div style={{ height: height - width, width: width, padding: width / 6 }} className="d-flex flex-column justify-content-between align-items-center">
            {repeatTime.length > 0 &&
              repeatTime.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {renderDottedLine()}
                  </React.Fragment>
                );
              })}
          </div>
          <div style={{ height: width / 2, width: width, ...background, borderTopRightRadius: width / 2, borderTopLeftRadius: width / 2 }}></div>
        </div>
      )}
    </>

    // </div>
  );
}

export default VoucherDivider;
