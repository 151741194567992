import { api_url } from "config";

import axios from "axios";
// import { getStorage, setStorage } from "zmp-sdk/apis";
import { getStorage } from "common/zalo_storage/ZaloStorage";

console.log("axios.create", axios.create, axios);

let getToken = async function () {
  //token Cubetech
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImN1c3RvbWVyX2lkIjoiNTdFUFZPIiwiZXhwcmllZF9kYXRlIjoiMjAyMy0xMC0yNSIsInNob3BfaWQiOiIyYTQ0Zjg0ZS01NWMwLTQzNGMtYjUwMS03MjU2MjczN2IwYWUifSwiaWF0IjoxNjk3Nzg5OTU1LCJleHAiOjIwMTMxNDk5NTV9.qJi4ooIx31AzPKdVXuJD_tdF33tBFuKQZZQwhp4iZNk";
  //token Violet
  //acc Tuấn
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImN1c3RvbWVyX2lkIjoiOFVYQTI0IiwiZXhwcmllZF9kYXRlIjoiMjAyMy0xMi0xMSIsInNob3BfaWQiOiIyMDAwIn0sImlhdCI6MTcwMTg1NDMwMSwiZXhwIjoyMDE3MjE0MzAxfQ.nv-IAahXYbTKy0gY4u-OWMY6Mb04ft2UbnE2BHTy85g";
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImN1c3RvbWVyX2lkIjoiVkU2NVYwIiwiZXhwcmllZF9kYXRlIjoiMjAyMy0xMi0wOSIsInNob3BfaWQiOiIyMDAwIn0sImlhdCI6MTcwMTY0OTQ2MiwiZXhwIjoyMDE3MDA5NDYyfQ.tJJe5Avh8_a5zHil0XXo1xhQQoYWi5zOXhywnUr6QG8";
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImN1c3RvbWVyX2lkIjoiOFVYQTI0IiwiZXhwcmllZF9kYXRlIjoiMjAyMy0wOS0yNyIsInNob3BfaWQiOiIyMDAwIn0sImlhdCI6MTY5NTM3NTk5OSwiZXhwIjoyMDEwNzM1OTk5fQ.dYEb2TxgG6P0obJQz9mr8uFYZ8-BhqMe_PWC5xfuwBE";
  //token dpthienphuoc
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImN1c3RvbWVyX2lkIjoiTk5UNTZNIiwiZXhwcmllZF9kYXRlIjoiMjAyMy0xMS0yMCIsInNob3BfaWQiOiI2NmFiY2RkYi1mNWNjLTQ4NjItOWJkMS1jNzI5ZWMzODE5MTUifSwiaWF0IjoxNzAwMDE3NDc4LCJleHAiOjIwMTUzNzc0Nzh9.xESSbhpGXayYH02rEBqiJyQzw7CiJ6Jd1ee8Vf61fzI";
  // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImN1c3RvbWVyX2lkIjoiNTdFUFZPIiwiZXhwcmllZF9kYXRlIjoiMjAyMy0wOS0wNSIsInNob3BfaWQiOiIyYTQ0Zjg0ZS01NWMwLTQzNGMtYjUwMS03MjU2MjczN2IwYWUifSwiaWF0IjoxNjkzNDQ5OTY0LCJleHAiOjIwMDg4MDk5NjR9.XK_N_BkyhY0CpPRugbYtvNgftxd0gIB_0RyeliJUKjo";
  // if (axiosInstance._TokenKey) {
  //   return localStorage.getItem(axiosInstance._TokenKey);
  // }
  try {
    var { token } = await getStorage({ keys: ["token"] });
    console.log("token", token);
    return token;
  } catch (error) {
    console.log(error);
    return;
  }

  // return await getStorageZalo();
};
const getStorageZalo = async () => {
  // try {
  //   const { token } = await getStorage({
  //     keys: ["token"],
  //   });
  //   // console.log("token", token);
  //   return token;
  // } catch (error) {
  //   return;
  // }
};

var axiosInstance = axios.create({
  baseURL: api_url,
  timeout: 10 * 60 * 1000,
  /* other custom settings */
});

axiosInstance.interceptors.request.use(async function (config) {
  const token = await getToken();
  config.headers["token"] = token;
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    var data = response.data;
    if (data.status === "success") {
      return data.data;
    } else {
      console.error("api error: ", data.error);
      return Promise.reject(data.error);
    }
  },
  function (error) {
    console.error("api error: ", error);
    return Promise.reject(error);
  }
);

axiosInstance.setTokenKey = function (key) {
  axiosInstance._TokenKey = key;
};

// axiosInstance.setToken = function (token) {
//   var key = "";
//   if (axiosInstance._TokenKey) {
//     key = axiosInstance._TokenKey;
//   }
//   localStorage.setItem(key, token);
// };

axiosInstance.getToken = async function () {
  return await getToken();
};

export default axiosInstance;
