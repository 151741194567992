import React, { useState, useEffect } from "react";
import { colors, assets, styles } from "theme-config/index";
import moment from "moment";
import DatePickerCustom from "components/common/DatePickerCustom";
import DatePickerBottomSheet from "components/common/bottom_sheet/DatePickerBottomSheet";

const DropDownHistory = ({ applyHistoryDate, setApplyHistoryDate }) => {
  const [isToggleModal, setIsToggleModal] = useState(false);
  const [modalConfig, setModalConfig] = useState();
  const [value, setValue] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const onClickDropdown = () => {
    setShowModal(!showModal);
    var modal = {
      type: "datePicker",
      onOk: null,
    };
    setModalConfig(modal);
    setIsToggleModal(!isToggleModal);
  };

  useEffect(() => {
    if (modalConfig && modalConfig.type === "datePicker" && modalConfig.onOk === "ok") {
      // console.log(value);
      var newDateRange = {
        start: value[0],
        end: value[1],
      };
      setApplyHistoryDate(newDateRange);
      //set lại applyHistoryDate bằng value
    }
  }, [modalConfig]);

  useEffect(() => {
    if (applyHistoryDate) {
      var newValue = [applyHistoryDate.start, applyHistoryDate.end];
      setValue(newValue);
    }
  }, []);

  return (
    <>
      <div className="container-fluid p-0" style={styles.bgWhite}>
        <div className="d-flex justify-content-between align-items-center shadow-sm px-3 py-2 border-bottom">
          <svg className="col-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3 4.9 3 6V20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20ZM6.5 13C6.5 11.62 7.62 10.5 9 10.5C10.38 10.5 11.5 11.62 11.5 13C11.5 14.38 10.38 15.5 9 15.5C7.62 15.5 6.5 14.38 6.5 13Z"
              fill={colors.onSurfaceVariant}
            />
          </svg>
          <span style={styles.textOnSurfaceNormal} className="col-9">
            {applyHistoryDate && applyHistoryDate.end && applyHistoryDate.start
              ? moment(applyHistoryDate.start).format("DD/MM/YYYY") + "-" + moment(applyHistoryDate.end).format("DD/MM/YYYY")
              : "Tất cả"}
          </span>
          <span className="col-1" onClick={onClickDropdown}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill={colors.onSurfaceVariant} />
            </svg>
          </span>
        </div>
      </div>
      {/* <DatePickerCustom applyDate={applyHistoryDate} setApplyDate={setApplyHistoryDate} showModal={showModal} setShowModal={setShowModal} /> */}
      <DatePickerBottomSheet
        isToggle={isToggleModal}
        modalConfig={modalConfig}
        setIsToggle={setIsToggleModal}
        setModalConfig={setModalConfig}
        value={value}
        onChange={setValue}
      />
    </>
  );
};
export default DropDownHistory;
