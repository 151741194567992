import React, { useState, useEffect } from "react";
import { colors, assets, styles, homeblock } from "theme-config/index";
import TopAppBar from "components/common/top_app_bar";
import ListDisplayPriority from "./ListDisplayPriority";
import Explore from "./Explore";
import Info from "./Info";
import MyVoucher from "./MyVoucher";
import ProductCatSwiper from "./ProductCatSwiper";
import HomeSlider from "./HomeSlider";
import NewsSlider from "./NewsSlider";

const Home = ({ greeting }) => {
  window.location.menu = true;

  const renderDynamicBlock = () => {
    var displayNode;
    if (homeblock && homeblock?.length > 0) {
      displayNode = homeblock.map((item) => {
        console.log(item);
        switch (item.type) {
          case "product_block":
            return <ProductCatSwiper {...item.props} />;

          case "banner_block":
            return <HomeSlider />;

          case "news_zalo_block":
            return <NewsSlider {...item.props} />;

          default:
            break;
        }
      });
    }
    return displayNode;
  };

  return (
    <>
      <div style={{ ...styles.mainBackground, paddingBottom: 60 }}>
        <TopAppBar mode="home" title="Trang chủ" />

        <div style={{ position: "relative" }}>
          {/* <div
            className="container-fluid"
            style={{ height: 112, position: "absolute", clipPath: "polygon(0 0, 100% 0, 100% 50%, 50% 90%, 0 50%)", ...styles.bgPrimary }}
          ></div> */}
          {/* <div className="container-fluid px-2">
            <Info />
          </div> */}
          {/* <ListDisplayPriority /> */}
          {/* <HomeSlider /> */}
          {renderDynamicBlock()}
          {/* <ProductCatSwiper lablel={"Khuyến mãi tháng 11"} category_id={"3db365f8-0438-4439-abce-067294c1872a"} /> */}
          <Explore />
          {/* <MyVoucher /> */}
          {/* <BottomNavigation/> */}
        </div>
      </div>
    </>
  );
};
export default Home;
