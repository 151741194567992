import TopAppBar from "components/common/top_app_bar";
import React, { useEffect, useState, Suspense } from "react";
import { Tabs, List, Spinner } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import api from "api";
import { assets, styles, colors } from "theme-config";
import numeral from "numeral";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";
import OrderInfo from "components/common/order_info";
import { render } from "@testing-library/react";

function OrderHistory() {
  const navigate = useNavigate();
  const location = useLocation();
  const [orderList, setOrderList] = useState([]);
  const [shopName, setShopName] = useState();
  const [activeKey, setActiveKey] = useState();
  const [customerName, setCustomerName] = useState();
  const [loading, setLoading] = useState(false);

  const totalProduct = (arr) => {
    // var sum = 0;
    let result = arr
      .map((x) => {
        return x.quantity;
      })
      .reduce((x, y) => {
        return x + y;
      });
    return result;
  };

  const renderContent = (key, status) => {
    // console.log(orderList[0]);
    var filter =
      key === "all"
        ? orderList
        : orderList.filter((item) => {
            return item.order_status === key;
          });

    if (filter.length > 0) {
      return (
        <div className="d-flex flex-column" style={{ gap: 8 }}>
          {filter.map((item) => {
            // console.log(item);
            if (item.line_items.length > 0) {
              return (
                // <></>
                <OrderInfo
                  mode="order_history"
                  title={shopName}
                  items={[item.line_items[0]]}
                  data={item}
                  footer={
                    <button
                      className="btn"
                      style={{ border: `1px solid ${colors.primary}`, borderRadius: 8, color: colors.primary, fontWeight: 500, fontSize: 14, height: 40 }}
                      onClick={() => {
                        saveCurrentKey();
                        navigate(`/${window.shop_sub_path}/OrderDetail/${item.id}`, { state: { item: item, shopName: shopName, fullName: customerName } });
                      }}
                    >
                      Xem chi tiết
                    </button>
                  }
                />
              );
            }
          })}
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 94.5px)" }}>
          <img src={assets.empty_bg.img} alt="" />
          <div className="fw-bold text-center" style={{ ...styles.textSecondary, fontSize: 20 }}>
            Chưa có đơn hàng nào
          </div>
        </div>
      );
    }
  };
  const tabsList = [
    {
      key: "all",
      label: "Tất cả",
      content: renderContent("all"),
    },
    {
      key: "new",
      label: "Chờ xác nhận",
      content: renderContent("new"),
    },
    { key: "paid", label: "Đã xác nhận", content: renderContent("paid") },
    { key: "shipped", label: "Đang giao", content: renderContent("shipped") },
    { key: "completed", label: "Hoàn thành", content: renderContent("completed") },
  ];

  const getListOrder = () => {
    setLoading(true);
    api
      .get("guest/listorder")
      .then((res) => {
        setCustomerName(res.full_name);

        var orders = res.orders.sort((a, b) => new Date(b.created_on).getTime() - new Date(a.created_on).getTime());
        setOrderList(orders);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    // console.log(location);
    // if (location.state && location.state.currentKey) {
    //   console.log(location.state.currentKey);

    //   setActiveKey(location.state.currentKey);
    // } else {
    //   setActiveKey("new");
    // }
    // getStorage({
    //   keys: ["shop_name", "currentKey"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { shop_name, currentKey } = data;
    //     if (shop_name) {
    //       setShopName(shop_name);
    //     }

    //     if (currentKey) {
    //       // console.log("currentKey", currentKey);
    //       setActiveKey(currentKey);
    //     } else {
    //       setActiveKey("new");
    //     }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    getDataFromStorage();
    getListOrder();
  }, []);

  const getDataFromStorage = async () => {
    var keys = ["shopName", "currentKey"];
    var { shopName, currentKey } = await getStorage({ keys: keys });
    setShopName(shopName);
    if (currentKey) {
      setActiveKey(currentKey);
    } else {
      setActiveKey("all");
    }
  };

  const saveCurrentKey = async () => {
    // setStorage({
    //   data: {
    //     currentKey: activeKey,
    //   },
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    var data = { currentKey: activeKey };
    var { errorKeys } = await setStorage({ data });
  };

  useEffect(() => {
    if (activeKey) {
      var activeLine = document.querySelector(".orderTabs .zaui-tabs-tabbar-active-line").style;
      activeLine.setProperty("--primary", colors.primary);
    }
  }, [activeKey]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <TopAppBar mode="back" title="Lịch sử đơn hàng" />
      {/* <div className="overflow-auto"> */}
      {activeKey && (
        <Tabs
          className="orderTabs"
          onChange={(e) => {
            setActiveKey(e);
          }}
          defaultActiveKey={activeKey}
        >
          {tabsList.map((item) => {
            return (
              <Tabs.Tab
                style={{ paddingTop: 45 }}
                key={item.key}
                label={
                  <span style={{ color: item.key === activeKey ? colors.primary : colors.onSurfaceVariant, fontSize: 14, fontWeight: 500 }}>{item.label}</span>
                }
              >
                {/* <Suspense fallback={<Spinner visible logo={""} />}>{renderContent(item.key, item.label)}</Suspense> */}
                {loading && (
                  <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "calc(100vh - 96px)" }}>
                    <Spinner visible={loading} />
                  </div>
                )}

                {/* // <ContentTab
                  //   key={item.key}
                  //   label={item.label}
                  //   orderList={orderList}
                  //   shopName={shopName}
                  //   customerName={customerName}
                  //   saveCurrentKey={saveCurrentKey}
                  //   navigate={navigate}
                  // /> */}
                {!loading && orderList && orderList.length > 0 && renderContent(item.key, item.label)}
              </Tabs.Tab>
            );
          })}
        </Tabs>
      )}
      {/* </div> */}
    </div>
  );
}

function ContentTab({ key, label, orderList, shopName, customerName, saveCurrentKey, navigate }) {
  var filter = orderList.filter((item) => {
    return item.order_status === key;
  });

  if (filter.length > 0) {
    return (
      <div className="d-flex flex-column" style={{ gap: 8 }}>
        {filter.map((item) => {
          // console.log(item);
          if (item.line_items.length > 0) {
            return (
              // <></>
              <OrderInfo
                mode="order_history"
                title={shopName}
                items={[item.line_items[0]]}
                data={item}
                footer={
                  <button
                    className="btn"
                    style={{ border: `1px solid ${colors.primary}`, borderRadius: 8, color: colors.primary, fontWeight: 500, fontSize: 14, height: 40 }}
                    onClick={() => {
                      saveCurrentKey();
                      navigate(`/${window.shop_sub_path}/OrderDetail/${item.id}`, { state: { item: item, shopName: shopName, fullName: customerName } });
                    }}
                  >
                    Xem chi tiết
                  </button>
                }
              />
            );
          }
        })}
      </div>
    );
  } else {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 94.5px)" }}>
        <img src={assets.empty_bg.img} alt="" />
        <div className="fw-bold text-center" style={{ ...styles.textSecondary, fontSize: 20 }}>
          Chưa có đơn hàng nào
        </div>
      </div>
    );
  }
}

export default OrderHistory;
