import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import { colors, assets, styles } from "theme-config";
import { getStorage } from "common/zalo_storage/ZaloStorage";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import api from "api";
import CartContext from "context/cart/CartContext";
import OptionSheet from "../common/bottom_sheet/SelectProductSheet";

function ProductCard({ item, position, mode = "list" }) {
  const navigate = useNavigate();
  const [sheetVisible, setSheetVisible] = useState(false);
  // console.log(item);

  const discountNumber = (originalPrice, salePrice) => {
    var discount = ((originalPrice - salePrice) * 100) / originalPrice;
    var split = discount.toString().split(".");

    if (split[0] > 0) {
      return <>{numeral(discount).format("0")}%</>;
    } else {
      return <>{numeral(discount).format("0.0")}%</>;
    }
  };

  const dispayPrice = () => {
    var rs = "";
    //rs = item.is_require_contact ? "Liên hệ" : numeral(item.sale_price_range.sale_price_min).format("0,0") + " đ" + " ~ " + numeral(item.sale_price_range.sale_price_max).format("0,0") + " đ"
    if (item.is_require_contact) {
      rs = "Liên hệ";
    } else if (item.sale_price_range.sale_price_min == item.sale_price_range.sale_price_max) {
      rs = numeral(item.sale_price_range.sale_price_min).format("0,0") + " đ";
    } else {
      rs = numeral(item.sale_price_range.sale_price_min).format("0,0") + " đ" + " ~ " + numeral(item.sale_price_range.sale_price_max).format("0,0") + " đ";
    }
    return rs;
  };

  return (
    <div className={mode == "list" ? "col-6 p-1" : "col-12 p-1"} ref={position}>
      <div className="card" style={{ border: "unset", borderRadius: 8 }}>
        <div
          className="product-card"
          style={{
            width: "100%",
            height: "calc(50vw - 14px)",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            backgroundImage: `url(${item.images[0]})`,
            backgroundSize: "cover",
          }}
          onClick={() => navigate(`/${window.shop_sub_path}/ProductDetail/${item.id}`)}
        >
          {item.sale_price_range.sale_price_min !== item.original_price_range.original_price_min && !item.is_require_contact && (
            <span
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                borderTopRightRadius: 8,
                borderBottomLeftRadius: 8,
                padding: "2px 4px",
                fontSize: 11,
                background: colors.primary,
                color: colors.onPrimary,
              }}
            >
              {item.max_discount ? numeral(item.max_discount).format("0,0") + "%" : ""}
            </span>
          )}
        </div>
        <div className="card-body d-flex p-2">
          <div className="d-flex flex-column" style={{ gap: 8, lineHeight: "20px", height: "68px", position: "relative", width: "100%" }}>
            <span
              className="card-text flex-fill"
              style={{
                fontSize: 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
              onClick={() => navigate(`/${window.shop_sub_path}/ProductDetail/${item.id}`)}
            >
              {item.name}
            </span>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span className="d-flex align-items-end" style={{ color: colors.primary, fontSize: 12, fontWeight: 500 }}>
                {/* {item.is_require_contact ? "Liên hệ" : numeral(item.sale_price_range.sale_price_min).format("0,0") + " đ" } */}
                {dispayPrice()}
              </span>
              {item.is_require_contact ? (
                ""
              ) : (
                <svg
                  onClick={() => {
                    setSheetVisible(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.2em"
                  viewBox="0 0 512 512"
                  style={{ color: colors.primary, fill: colors.primary }}
                >
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>

      {sheetVisible && <OptionSheet sheetVisible={sheetVisible} setSheetVisible={setSheetVisible} product={item} mode={"addToCart"} />}
    </div>
  );
}

export default ProductCard;
