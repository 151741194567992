import { useReducer } from "react";
import CartContext from "./CartContext";
import CartReducer from "./CartReducer";
import { sumItems } from "./CartReducer";
import { getStorage } from "common/zalo_storage/ZaloStorage";

//Local Storage
// const storage = localStorage.getItem("cartItems") ? JSON.parse(localStorage.getItem("cartItems")) : [];
var data = await getStorage({ keys: ["cartItems", "shippingAddress", "voucher"] });
const CartState = ({ children }) => {
  //Initial State of the cart
  // const initialState = {
  //   cartItems: [],
  //   checkout: false,
  // };

  //Change the code above to that below to get the initial state from local storage
  const initialState = {
    cartItems: data.cartItems ? JSON.parse(data.cartItems) : [],
    shippingAddress: data.shippingAddress ? JSON.parse(data.shippingAddress) : {},
    voucher: data.voucher ? JSON.parse(data.voucher) : {},
    ...sumItems(data.cartItems ? JSON.parse(data.cartItems) : []),
    checkout: false,
  };

  //Set up the reducer
  const [state, dispatch] = useReducer(CartReducer, initialState);

  //Function to handle when an item is added from the store into the Cart
  const addToCart = (payload) => {
    dispatch({ type: "ADD_TO_CART", payload });
  };

  const updateToCart = (payload) => {
    dispatch({ type: "UPDATE_TO_CART", payload });
  };

  //Function to handle when an item that is in the cart is added again
  const increase = (payload) => {
    dispatch({ type: "INCREASE", payload });
  };

  //Function to handle when an item is removed from the cart
  const decrease = (payload) => {
    dispatch({ type: "DECREASE", payload });
  };

  //Function to remove an item from the cart
  const removeFromCart = (payload) => {
    dispatch({ type: "REMOVE_ITEM", payload });
  };

  //Function to clear the cart
  const clearCart = () => {
    dispatch({ type: "CLEAR" });
  };

  //Function to handle when the user clicks the checkout button
  const handleCheckout = () => {
    dispatch({ type: "CHECKOUT" });
  };

  const addShippingAddress = (payload) => {
    // console.log(payload);
    dispatch({ type: "ADD_SHIPPING_ADDRESS", payload });
  };

  const addVoucher = (payload) => {
    dispatch({ type: "ADD_VOUCHER", payload });
  };

  return (
    //Add the above functions into the Context provider, and pass to the children
    <CartContext.Provider
      value={{
        showCart: state.showCart,
        cartItems: state.cartItems,
        shippingAddress: state.shippingAddress,
        voucher: state.voucher,
        addToCart,
        updateToCart,
        removeFromCart,
        increase,
        decrease,
        handleCheckout,
        clearCart,
        addShippingAddress,
        addVoucher,
        // To access the total, we need to pass in the state
        ...state,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartState;
