import React, { useEffect, useState } from "react";
import TopAppBar from "../common/top_app_bar";
import api from "api";
import { colors, assets } from "theme-config";
import { getStorage } from "common/zalo_storage/ZaloStorage";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";

function ExchangePoint() {
  const navigate = useNavigate();
  const [exchangePointList, setExchangePointList] = useState([]);
  const [pointLogo, setPointLogo] = useState();
  const [shopName, setShopName] = useState();

  const getListExchangePoint = () => {
    api
      .post("guest/getconfiggift", { skip: 0, limit: 1000 })
      .then((res) => {
        // res.list.sort((a, b) => parseFloat(a.exchange.point) - parseFloat(b.exchange.point));
        setExchangePointList(res.list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListExchangePoint();
    // getStorage({
    //   keys: ["pointLogo", "shop_name"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     // console.log("getStorage", data);
    //     var { pointLogo, shop_name } = data;
    //     if (pointLogo) {
    //       setPointLogo(pointLogo);
    //     }

    //     if (shop_name) {
    //       setShopName(shop_name);
    //     }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    getDataFromStorage();
  }, []);

  const getDataFromStorage = async () => {
    try {
      var keys = ["pointLogo", "shopName"];
      var { pointLogo, shopName } = await getStorage({ keys: keys });
      setPointLogo(pointLogo);
      setShopName(shopName);
    } catch (error) {}
  };

  useEffect(() => {
    if (exchangePointList.length > 0) {
    }
  }, [exchangePointList]);

  return (
    <div style={{ minHeight: "100vh", background: colors.surface }}>
      <TopAppBar mode="back" title="Đổi điểm" />
      {exchangePointList && exchangePointList.length > 0 ? (
        <div style={{ boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)" }}>
          {exchangePointList.map((item, index) => {
            return (
              <div
                style={{
                  background: "white",
                  padding: "8px 24px 8px 16px",
                  borderBottom: index === exchangePointList.length - 1 ? "none" : `1px solid ${colors.outlineVariant}`,
                }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{ gap: 16 }}
                  onClick={() => {
                    navigate(`/${window.shop_sub_path}/ProgramDetail/${item.id}`, { state: { item: item, pointLogo: pointLogo } });
                  }}
                >
                  <img src={item.logo} alt="" style={{ width: 60, height: 60 }} />
                  <div className="d-flex flex-column">
                    <text style={{ fontWeight: 500, fontSize: 12 }}>{shopName}</text>
                    {item.name.length > 56 ? <text>{item.name.slice(0, 56) + "..."}</text> : <text>{item.name}</text>}
                  </div>
                  <div className="ms-auto">
                    <div className="d-flex flex-row h-100">
                      <text style={{ marginInline: 4, alignSelf: "center" }}>{item.exchange.point}</text>
                      {pointLogo ? (
                        <img src={pointLogo} alt="" style={{ width: 16, height: 16, alignSelf: "center" }} />
                      ) : (
                        <svg style={{ marginTop: 2 }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="Iconify/G">
                            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill={colors.primary} />
                            <path
                              id="Vector"
                              d="M12.9917 11.2318C12.4741 11.6453 11.8098 11.9536 11.0673 12.1249C10.1819 12.3436 9.24942 12.4514 8.31164 12.4437C7.33747 12.4584 6.37541 12.289 5.5248 11.9531C4.71689 11.6221 4.06985 11.1258 3.66899 10.5297C3.21454 9.84432 2.98642 9.09491 3.00189 8.33807V7.7505C2.97746 6.97973 3.19018 6.2144 3.62534 5.50745C3.99784 4.90833 4.6189 4.40329 5.40634 4.05911C6.23089 3.71479 7.17548 3.54069 8.1329 3.55667C9.58347 3.55667 10.7175 3.80149 11.5349 4.29114C12.3523 4.78078 12.8372 5.49227 12.9896 6.42553H10.5374C10.4251 5.9305 10.1778 5.56916 9.79337 5.33853C9.4089 5.10791 8.88312 4.99476 8.2098 4.99476C7.8281 4.98087 7.44775 5.03642 7.10503 5.15599C6.76231 5.27557 6.46868 5.45525 6.25216 5.67787C5.80535 6.13323 5.57675 6.81038 5.57259 7.70789V8.26018C5.57259 9.16797 5.81574 9.85347 6.30203 10.3167C6.78833 10.7799 7.50252 11.0115 8.44463 11.0115C9.39228 11.0115 10.0677 10.869 10.4709 10.5841V9.09312H8.18486V7.78134H13L12.9917 11.2318Z"
                              fill={colors.onPrimary}
                            />
                            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={colors.primary} />
                          </g>
                        </svg>
                      )}

                      {}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        // <div className="row my-auto">
        <div className="d-flex align-items-center justify-content-center" style={{ height: "calc(100vh - 50.5px)" }}>
          <div className="d-flex flex-column">
            <img style={{ width: 248, height: 248 }} src={assets.empty_bg.img} className="rounded mx-auto d-block" alt="..." />
            <text className="text-center" style={{ fontSize: 22, fontWeight: 400 }}>
              Chưa có ưu đãi nào
            </text>
            <p className="text-center" style={{ fontWeight: 500, color: colors.secondary }}>
              Hãy đổi điểm để nhận ưu đãi ngay nhé !!!
            </p>
          </div>
        </div>

        // </div>
      )}
    </div>
  );
}

export default ExchangePoint;
