import React, { useEffect, useState } from "react";
import api from "api";
import TopAppBar from "components/common/top_app_bar";
import { styles } from "theme-config";
import { Input, DatePicker, Select } from "zmp-ui";
import { useNavigate } from "react-router-dom";
import ModalZaUI from "components/common/modal/ModalZaUI";
import moment from "moment";

const { Option } = Select;

function EditAccount() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [user, setUser] = useState({
    gender: "",
  });
  const [dataPost, setDataPost] = useState({});

  useEffect(() => {
    api.get("guest/me").then((res) => {
      // console.log(res);
      setUser(res);
    });
  }, []);

  // useEffect(() => { }, [user])

  const onChangeInput = (e, nameInput) => {
    var value = e.target.value;
    var newUser = user;
    newUser[nameInput] = value;
    setUser({ ...newUser });

    var newDataPost = dataPost;
    newDataPost[nameInput] = value;
    setDataPost({ ...newDataPost });
  };

  /**
   * Hàm xử lý khi thay đổi ngày sinh
   * @param {*} value
   */
  const onChangeBirthDay = (value) => {
    setUser({ ...user, birth_day: moment(value).toISOString() });
    setDataPost({ ...dataPost, birth_day: moment(value).toISOString() });
  };

  /**
   * Hàm xử lý khi chọn giới tính
   * @param {*} value
   */
  const selectGender = (value) => {
    setUser({ ...user, gender: value });
    setDataPost({ ...dataPost, gender: value });
  };

  const save = () => {
    // console.log('dataPost', dataPost);
    var check = validate();

    if (check) {
      api
        .post("guest/savecustomer", { data: dataPost, id: user.id })
        .then((res) => {
          // console.log("guest/savecustomer", res);
          setModalConfig({
            type: "success",
            description: "Lưu thay đổi thông tin tài khoản thành công",
            onOk() {
              navigate(-1);
            },
          });
          setIsModalOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const validate = () => {
    var description;
    var result = true;

    if (dataPost["first_name"] === "") {
      result = false;
      description = "Tên không được để trống";
    } else if (dataPost["last_name"] === "") {
      result = false;
      description = "Họ không được để trống";
    } else if (dataPost["email"] === "") {
      result = false;
      description = "Email không được để trống";
    }

    if (!result) {
      setModalConfig({
        type: "error",
        description: description,
      });
      setIsModalOpen(true);
      return false;
    }

    return true;
  };

  return (
    <div style={{ height: "calc(100vh - 60px)", marginBottom: 60 }}>
      <TopAppBar mode="back" title="Chỉnh sửa tài khoản" />

      <div className="m-3">
        <Input
          label="Họ"
          value={user.last_name}
          onChange={(e) => {
            onChangeInput(e, "last_name");
          }}
        />
        <Input
          label="Tên"
          value={user.first_name}
          onChange={(e) => {
            onChangeInput(e, "first_name");
          }}
        />
        <Input label="Số điện thoại" value={user.phone} disabled />
        <Input label="Mã khách hàng" value={user.id} disabled />
        <Input label="Ngày đăng ký" value={user.created_on && moment(user.created_on).format("DD/MM/YYYY")} disabled />

        <DatePicker
          label="Ngày sinh"
          mask
          maskClosable
          dateFormat="dd/mm/yyyy"
          title="Ngày sinh"
          value={new Date(user.birth_day)}
          onChange={onChangeBirthDay}
        />
        <Select placeholder="Giới tính" label="Giới tính" defaultValue="" value={user.gender} onChange={selectGender}>
          <Option value="male" title="Nam" />
          <Option value="female" title="Nữ" />
        </Select>
        <Input
          label="Email"
          value={user.email}
          onChange={(e) => {
            onChangeInput(e, "email");
          }}
        />
      </div>

      <nav
        className="navbar fixed-bottom"
        style={{ ...styles.mainBackground, boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)" }}
      >
        <div className="row" style={{ gap: 8, padding: "4px 16px", margin: 0, width: "100%", fontSize: 12 }}>
          <button className="btn" style={{ ...styles.btnPrimary, borderRadius: 12, height: 40 }} onClick={save}>
            Lưu
          </button>
        </div>
      </nav>

      <ModalZaUI isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalConfig={modalConfig} />
    </div>
  );
}

export default EditAccount;
