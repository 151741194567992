import MiniApp from "components/mini_app";
import { login, configAppView, onConfirmToExit } from "zmp-sdk/apis";
import api from "api";
import { useEffect } from "react";
import { colors, script } from "theme-config";
// import { ZMPRouter, SnackbarProvider } from "zmp-ui";
import { offConfirmToExit, closeApp } from "zmp-sdk/apis";
import { setStorage, removeStorage } from "common/zalo_storage/ZaloStorage";
import { useParams } from "react-router-dom";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Home from "./components/home_page";

function App() {
  console.log(window.location.href);
  const param = useParams();
  useEffect(() => {
    console.log("param", window.location.search);
    getBranding();
    // login({
    //   success: async () => {
    //     var data = { queryParam: window.location.search };
    //     var { errorKeys } = await setStorage({ data });
    //   },
    //   fail: (error) => {
    //     console.log(error);
    //   },
    // });
    // configAppView({
    //   headerColor: colors.primary,
    //   actionBar: {
    //     hide: true,
    //   },
    //   success: (res) => {
    //     // xử lý khi gọi api thành công
    //     // console.log("configAppViewSuccess", res);
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log("configAppView", error);
    //   },
    // });

    // onConfirmToExit(() => exitAppEvent());
    // return () => offConfirmToExit();
  }, []);

  const injectionScript = (script) => {
    var range = document.createRange();
    range.selectNode(document.getElementsByTagName("BODY")[0]);
    var documentFragment = range.createContextualFragment(script);
    document.body.appendChild(documentFragment);
  };

  const exitAppEvent = () => {
    removeStorageData();
  };

  const removeStorageData = async () => {
    try {
      const { errorKeys } = await removeStorage({
        keys: ["lastDisplayModal"],
      });
      await closeApp({});
    } catch (error) {
      //   // xử lý khi gọi api thất bại
      //   console.log(error);
      await closeApp({});
    }
  };

  const getBranding = () => {
    api
      .post("/guestlogin/getbranding", { shop_name: window.shop_id })
      .then(async (res) => {
        const miniapp = res.miniapp_config ? JSON.stringify(res.miniapp_config) : "";
        if (res.code) {
          injectionScript(res.code);
        }

        var data = {
          logo: res.logo,
          shopName: res.name ? res.name : window.shop_id,
          shopPhone: res.phone_number,
          miniappConfig: miniapp,
        };
        var { errorKeys } = await setStorage({ data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <BrowserRouter>
      <MiniApp></MiniApp>
    </BrowserRouter>
  );
}

export default App;
