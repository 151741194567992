import React from "react";
import { colors } from "theme-config";
import { Button, Sheet, Text, Box, Page } from "zmp-ui";
import ReactStars from "react-rating-stars-component";

function SelectStarSheet({ sheetVisible, setSheetVisible, onChange }) {
  const onChangeStar = (star) => {
    onChange(star);
    setSheetVisible(false);
  };
  return (
    <Sheet visible={sheetVisible} onClose={() => setSheetVisible(false)} autoHeight mask handler swipeToClose>
      <Box p={4} className="custom-bottom-sheet" flex flexDirection="column">
        {/* <Box className="bottom-sheet-cover">
          <img alt="Bottom Sheet" src={""} />
        </Box> */}
        <Box className="bottom-sheet-body" style={{ overflowY: "auto" }}>
          <Box flex flexDirection="column" p={1} style={{ gap: 8 }}>
            <Box
              flex
              flexDirection="row"
              onClick={() => {
                onChangeStar(1);
              }}
              style={{ gap: 4 }}
            >
              <Text style={{ fontSize: 14 }}>1</Text>
              <ReactStars count={1} value={1} edit={false} size={12} activeColor={"#FFBB33"} />
            </Box>

            <hr className="mt-0 mb-0 w-100" style={{ color: colors.outlineVariant, opacity: 1, minWidth: 16, borderTop: "1px solid" }}></hr>
            <Box
              flex
              flexDirection="row"
              onClick={() => {
                onChangeStar(2);
              }}
              style={{ gap: 4 }}
            >
              <Text style={{ fontSize: 14 }}>2</Text>
              <ReactStars count={2} value={2} edit={false} size={12} activeColor={"#FFBB33"} />
            </Box>
            <hr className="mt-0 mb-0 w-100" style={{ color: colors.outlineVariant, opacity: 1, minWidth: 16, borderTop: "1px solid" }}></hr>
            <Box
              flex
              flexDirection="row"
              onClick={() => {
                onChangeStar(3);
              }}
              style={{ gap: 4 }}
            >
              <Text style={{ fontSize: 14 }}>3</Text>
              <ReactStars count={3} value={3} edit={false} size={12} activeColor={"#FFBB33"} />
            </Box>

            <hr className="mt-0 mb-0 w-100" style={{ color: colors.outlineVariant, opacity: 1, minWidth: 16, borderTop: "1px solid" }}></hr>
            <Box
              flex
              flexDirection="row"
              onClick={() => {
                onChangeStar(4);
              }}
              style={{ gap: 4 }}
            >
              <Text style={{ fontSize: 14 }}>4</Text>
              <ReactStars count={4} value={4} edit={false} size={12} activeColor={"#FFBB33"} />
            </Box>
            <hr className="mt-0 mb-0 w-100" style={{ color: colors.outlineVariant, opacity: 1, minWidth: 16, borderTop: "1px solid" }}></hr>
            <Box
              flex
              flexDirection="row"
              onClick={() => {
                onChangeStar(5);
              }}
              style={{ gap: 4 }}
            >
              <Text style={{ fontSize: 14 }}>5</Text>
              <ReactStars count={5} value={5} edit={false} size={12} activeColor={"#FFBB33"} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Sheet>
  );
}

export default SelectStarSheet;
