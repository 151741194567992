import { useEffect, useState } from "react";
import { Button, Text, Modal, Box } from "zmp-ui";
import { assets, colors, styles } from "theme-config";
import { getAccessToken, getPhoneNumber, getUserInfo } from "zmp-sdk/apis";
import api from "api";
import { getStorage, setStorage } from "common/zalo_storage/ZaloStorage";

/**
 * Modal Zalo UI custom
 * @param {Boolean} isModalOpen True/False
 * @param {Function} setIsModalOpen Gán giá trị cho isModalOpen
 * @param {Object} modalConfig {
 * type : "success" || "error" || "warning",
 * description(string): Mô tả,
 * onOk(function): Hàm khi nhấn nút OK,
 * okText(string): Đổi chữ nút OK,
 * cancelText(string): Đổi chữ nút Cancel
 * }
 */
function ModalZaUI({ isModalOpen, setIsModalOpen, modalConfig }) {
  const [logo, setLogo] = useState();
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    // getStorage({
    //   keys: ["logo"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     var { logo } = data;
    //     // console.log("logo", logo);
    //     if (logo) {
    //       setLogo(logo);
    //     }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại

    //     console.log(error);
    //   },
    // });
    getLogo();
    getInfo();
  }, []);

  const getLogo = async () => {
    try {
      var { logo } = await getStorage({ keys: ["logo"] });
      setLogo(logo);
    } catch (error) {}
  };

  const getInfo = async () => {
    var { userInfo } = await getUserInfo();
    setUserInfo(userInfo);
  };

  const renderBodyModal = () => {
    switch (modalConfig.type) {
      case "info":
        return modalInfo();
      case "success":
        return modalSuccess();
      case "error":
        return modalError();
      case "warning":
        return modalWarning();
      case "delete":
        return modalDelete();
      case "login":
        return modalLogin();
      case "reward_wheel":
        return modalGameReward();
      case "reward_giftbox":
        return modalGameReward();
      case "info_shipping_address":
        return modalInfoShippingAdress();
      default:
        break;
    }
  };

  const modalSuccess = () => {
    return (
      <Box alignContent="center" flex={true} flexDirection="column">
        <svg className="mb-3" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
          <path
            d="M24.7333 38.7334L41.1833 22.2834L37.9166 19.0167L24.7333 32.2L18.0833 25.55L14.8166 28.8167L24.7333 38.7334ZM28 51.3334C24.7722 51.3334 21.7388 50.7205 18.9 49.4947C16.0611 48.2705 13.5916 46.6084 11.4916 44.5084C9.39163 42.4084 7.72952 39.9389 6.50529 37.1C5.27952 34.2611 4.66663 31.2278 4.66663 28C4.66663 24.7722 5.27952 21.7389 6.50529 18.9C7.72952 16.0611 9.39163 13.5917 11.4916 11.4917C13.5916 9.39169 16.0611 7.7288 18.9 6.50302C21.7388 5.2788 24.7722 4.66669 28 4.66669C31.2277 4.66669 34.2611 5.2788 37.1 6.50302C39.9388 7.7288 42.4083 9.39169 44.5083 11.4917C46.6083 13.5917 48.2704 16.0611 49.4946 18.9C50.7204 21.7389 51.3333 24.7722 51.3333 28C51.3333 31.2278 50.7204 34.2611 49.4946 37.1C48.2704 39.9389 46.6083 42.4084 44.5083 44.5084C42.4083 46.6084 39.9388 48.2705 37.1 49.4947C34.2611 50.7205 31.2277 51.3334 28 51.3334Z"
            fill="#00C851"
          />
        </svg>

        <Text.Title className="mb-3" style={{ fontSize: 24 }}>
          Thành công
        </Text.Title>
        <Text className="mb-3">{modalConfig.description}</Text>

        <Button
          onClick={() => {
            if (modalConfig.onOk) {
              modalConfig.onOk();
            }
            setIsModalOpen(false);
          }}
          fullWidth
          style={styles.btnConfirm}
          className="p-0 rounded-3"
        >
          {modalConfig.okText ? modalConfig.okText : "Xác nhận"}
        </Button>
      </Box>
    );
  };

  const modalError = () => {
    return (
      <Box alignContent="center" flex={true} flexDirection="column">
        <svg className="mb-3" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
          <path
            d="M4.66663 51.3333V9.3333C4.66663 8.04996 5.12396 6.95096 6.03863 6.0363C6.95329 5.12163 8.05151 4.66507 9.33329 4.66663H46.6666C47.95 4.66663 49.049 5.12396 49.9636 6.03863C50.8783 6.9533 51.3348 8.05152 51.3333 9.3333V37.3333C51.3333 38.6166 50.876 39.7156 49.9613 40.6303C49.0466 41.545 47.9484 42.0015 46.6666 42H14L4.66663 51.3333ZM21.9333 32.6666L28 26.6L34.0666 32.6666L37.3333 29.4L31.2666 23.3333L37.3333 17.2666L34.0666 14L28 20.0666L21.9333 14L18.6666 17.2666L24.7333 23.3333L18.6666 29.4L21.9333 32.6666Z"
            fill="#BA1A1A"
          />
        </svg>

        <Text.Title className="mb-3" style={{ fontSize: 24 }}>
          Lỗi
        </Text.Title>
        <Text className="mb-3">{modalConfig.description}</Text>

        <Button
          onClick={() => {
            if (modalConfig.onOk) {
              modalConfig.onOk();
            }
            setIsModalOpen(false);
          }}
          fullWidth
          style={styles.btnConfirm}
          className="p-0 rounded-3"
        >
          {modalConfig.okText ? modalConfig.okText : "Đóng"}
        </Button>
      </Box>
    );
  };

  const modalWarning = () => {
    return (
      <Box alignContent="center" flex={true} flexDirection="column">
        <svg className="mb-3" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.9998 4.66669C15.1198 4.66669 4.6665 15.12 4.6665 28C4.6665 40.88 15.1198 51.3334 27.9998 51.3334C40.8798 51.3334 51.3332 40.88 51.3332 28C51.3332 15.12 40.8798 4.66669 27.9998 4.66669ZM25.6665 39.6667V35H30.3332V39.6667H25.6665ZM25.6665 16.3334V30.3334H30.3332V16.3334H25.6665Z"
            fill="#FFBB33"
          />
        </svg>

        <Text.Title className="mb-3" style={{ fontSize: 24 }}>
          Lưu ý
        </Text.Title>
        <Text className="mb-3">{modalConfig.description}</Text>

        <Box width={"100%"} flex={true}>
          <Button
            onClick={() => {
              setIsModalOpen(false);
            }}
            fullWidth
            className="me-2 p-0 rounded-3"
            style={styles.btnClose}
          >
            {modalConfig.cancelText ? modalConfig.cancelText : "Đóng"}
          </Button>

          <Button
            onClick={() => {
              if (modalConfig.onOk) {
                modalConfig.onOk();
              }
              setIsModalOpen(false);
            }}
            fullWidth
            className="ms-2 p-0 rounded-3"
            style={styles.btnConfirm}
          >
            {modalConfig.okText ? modalConfig.okText : "Xác nhận"}
          </Button>
        </Box>
      </Box>
    );
  };

  const modalInfo = () => {
    return (
      <Box alignContent="center" flex={true} flexDirection="column" style={{ gap: 16 }}>
        {modalConfig.logo ? (
          <Box
            style={{
              width: 48,
              height: 48,
              borderRadius: "50%",
              border: `0.6px solid ${colors.outlineVariant}`,
              backgroundImage: `url(${logo})`,
              backgroundPosition: "center",
              backgroundSize: 40,
              backgroundRepeat: "no-repeat",
              justifySelf: "center",
              alignSelf: "center",
            }}
          ></Box>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <path
              d="M25.6665 39.667H30.3332V25.667H25.6665V39.667ZM27.9998 21.0003C28.6609 21.0003 29.2155 20.7763 29.6635 20.3283C30.1099 19.8819 30.3332 19.3281 30.3332 18.667C30.3332 18.0059 30.1099 17.4513 29.6635 17.0033C29.2155 16.5569 28.6609 16.3337 27.9998 16.3337C27.3387 16.3337 26.7849 16.5569 26.3385 17.0033C25.8905 17.4513 25.6665 18.0059 25.6665 18.667C25.6665 19.3281 25.8905 19.8819 26.3385 20.3283C26.7849 20.7763 27.3387 21.0003 27.9998 21.0003ZM27.9998 51.3337C24.7721 51.3337 21.7387 50.7208 18.8998 49.495C16.0609 48.2708 13.5915 46.6087 11.4915 44.5087C9.3915 42.4087 7.72939 39.9392 6.50517 37.1003C5.27939 34.2614 4.6665 31.2281 4.6665 28.0003C4.6665 24.7725 5.27939 21.7392 6.50517 18.9003C7.72939 16.0614 9.3915 13.592 11.4915 11.492C13.5915 9.39199 16.0609 7.7291 18.8998 6.50333C21.7387 5.2791 24.7721 4.66699 27.9998 4.66699C31.2276 4.66699 34.2609 5.2791 37.0998 6.50333C39.9387 7.7291 42.4082 9.39199 44.5082 11.492C46.6082 13.592 48.2703 16.0614 49.4945 18.9003C50.7203 21.7392 51.3332 24.7725 51.3332 28.0003C51.3332 31.2281 50.7203 34.2614 49.4945 37.1003C48.2703 39.9392 46.6082 42.4087 44.5082 44.5087C42.4082 46.6087 39.9387 48.2708 37.0998 49.495C34.2609 50.7208 31.2276 51.3337 27.9998 51.3337ZM27.9998 46.667C33.2109 46.667 37.6248 44.8587 41.2415 41.242C44.8582 37.6253 46.6665 33.2114 46.6665 28.0003C46.6665 22.7892 44.8582 18.3753 41.2415 14.7587C37.6248 11.142 33.2109 9.33366 27.9998 9.33366C22.7887 9.33366 18.3748 11.142 14.7582 14.7587C11.1415 18.3753 9.33317 22.7892 9.33317 28.0003C9.33317 33.2114 11.1415 37.6253 14.7582 41.242C18.3748 44.8587 22.7887 46.667 27.9998 46.667Z"
              fill="#005AC1"
            />
          </svg>
        )}

        <Text.Title style={{ fontSize: 24 }}>Thông báo</Text.Title>
        {modalConfig.type === "reward_info" && (
          <Text>
            Chúc mừng <span style={{ fontWeight: 500 }}>{userInfo.name}</span> đã quay vào ô <span style={{ fontWeight: 500 }}>{modalConfig.description}</span>.
            Vui lòng kiểm tra kho quà của bạn để xem phần thưởng nhé
          </Text>
        )}

        {modalConfig.type === "info" && <Text>{modalConfig.description}</Text>}

        <Box width={"100%"} flex={true}>
          {modalConfig.isCancel === false ? (
            <></>
          ) : (
            <Button
              onClick={() => {
                if (modalConfig.onCancel) {
                  modalConfig.onCancel();
                }
                setIsModalOpen(false);
              }}
              fullWidth
              className="me-2 p-0 rounded-3"
              style={styles.btnClose}
            >
              {modalConfig.cancelText ? modalConfig.cancelText : "Đóng"}
            </Button>
          )}

          <Button
            onClick={() => {
              if (modalConfig.onOk) {
                modalConfig.onOk();
              }
              setIsModalOpen(false);
            }}
            fullWidth
            className="ms-2 p-0 rounded-3"
            style={styles.btnConfirm}
          >
            {modalConfig.okText ? modalConfig.okText : "Xác nhận"}
          </Button>
        </Box>
      </Box>
    );
  };

  const modalInfoShippingAdress = () => {
    return (
      <Box flex={true} flexDirection="column" style={{ gap: 16 }}>
        {modalConfig.logo ? (
          <Box
            style={{
              width: 48,
              height: 48,
              borderRadius: "50%",
              border: `0.6px solid ${colors.outlineVariant}`,
              backgroundImage: `url(${logo})`,
              backgroundPosition: "center",
              backgroundSize: 40,
              backgroundRepeat: "no-repeat",
              justifySelf: "center",
              alignSelf: "center",
            }}
          ></Box>
        ) : (
          <svg style={{ alignSelf: "center" }} xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <path
              d="M25.6665 39.667H30.3332V25.667H25.6665V39.667ZM27.9998 21.0003C28.6609 21.0003 29.2155 20.7763 29.6635 20.3283C30.1099 19.8819 30.3332 19.3281 30.3332 18.667C30.3332 18.0059 30.1099 17.4513 29.6635 17.0033C29.2155 16.5569 28.6609 16.3337 27.9998 16.3337C27.3387 16.3337 26.7849 16.5569 26.3385 17.0033C25.8905 17.4513 25.6665 18.0059 25.6665 18.667C25.6665 19.3281 25.8905 19.8819 26.3385 20.3283C26.7849 20.7763 27.3387 21.0003 27.9998 21.0003ZM27.9998 51.3337C24.7721 51.3337 21.7387 50.7208 18.8998 49.495C16.0609 48.2708 13.5915 46.6087 11.4915 44.5087C9.3915 42.4087 7.72939 39.9392 6.50517 37.1003C5.27939 34.2614 4.6665 31.2281 4.6665 28.0003C4.6665 24.7725 5.27939 21.7392 6.50517 18.9003C7.72939 16.0614 9.3915 13.592 11.4915 11.492C13.5915 9.39199 16.0609 7.7291 18.8998 6.50333C21.7387 5.2791 24.7721 4.66699 27.9998 4.66699C31.2276 4.66699 34.2609 5.2791 37.0998 6.50333C39.9387 7.7291 42.4082 9.39199 44.5082 11.492C46.6082 13.592 48.2703 16.0614 49.4945 18.9003C50.7203 21.7392 51.3332 24.7725 51.3332 28.0003C51.3332 31.2281 50.7203 34.2614 49.4945 37.1003C48.2703 39.9392 46.6082 42.4087 44.5082 44.5087C42.4082 46.6087 39.9387 48.2708 37.0998 49.495C34.2609 50.7208 31.2276 51.3337 27.9998 51.3337ZM27.9998 46.667C33.2109 46.667 37.6248 44.8587 41.2415 41.242C44.8582 37.6253 46.6665 33.2114 46.6665 28.0003C46.6665 22.7892 44.8582 18.3753 41.2415 14.7587C37.6248 11.142 33.2109 9.33366 27.9998 9.33366C22.7887 9.33366 18.3748 11.142 14.7582 14.7587C11.1415 18.3753 9.33317 22.7892 9.33317 28.0003C9.33317 33.2114 11.1415 37.6253 14.7582 41.242C18.3748 44.8587 22.7887 46.667 27.9998 46.667Z"
              fill="#005AC1"
            />
          </svg>
        )}

        <Text.Title style={{ fontSize: 24, alignSelf: "center" }}>Thông báo</Text.Title>

        <div style={{ maxHeight: 140, overflowY: "auto" }}>
          <Text style={{ fontSize: 14 }}>{"Đơn hàng chỉ hỗ trợ giao " + modalConfig.province_list?.length + " tỉnh thành"} </Text>
          {modalConfig.province_list.map((item) => {
            return <Text style={{ fontWeight: 500, fontSize: 14 }}>{item.name} </Text>;
          })}
        </div>

        <Box width={"100%"} flex={true}>
          {modalConfig.isCancel === false ? (
            <></>
          ) : (
            <Button
              onClick={() => {
                if (modalConfig.onCancel) {
                  modalConfig.onCancel();
                }
                setIsModalOpen(false);
              }}
              fullWidth
              className="me-2 p-0 rounded-3"
              style={styles.btnClose}
            >
              {modalConfig.cancelText ? modalConfig.cancelText : "Đóng"}
            </Button>
          )}

          <Button
            onClick={() => {
              if (modalConfig.onOk) {
                modalConfig.onOk();
              }
              setIsModalOpen(false);
            }}
            fullWidth
            className="ms-2 p-0 rounded-3"
            style={styles.btnConfirm}
          >
            {modalConfig.okText ? modalConfig.okText : "Xác nhận"}
          </Button>
        </Box>
      </Box>
    );
  };

  const modalGameReward = () => {
    return (
      <Box alignContent="center" flex={true} flexDirection="column" style={{ gap: 16 }}>
        <Box
          style={{
            width: 48,
            height: 48,
            borderRadius: "50%",
            border: `0.6px solid ${colors.outlineVariant}`,
            backgroundImage: `url(${logo})`,
            backgroundPosition: "center",
            backgroundSize: 40,
            backgroundRepeat: "no-repeat",
            justifySelf: "center",
            alignSelf: "center",
          }}
        ></Box>
        <Text.Title style={{ fontSize: 24 }}>Thông báo</Text.Title>
        {modalConfig.type === "reward_wheel" && (
          <Text>
            Chúc mừng <span style={{ fontWeight: 500 }}>{userInfo.name}</span> đã quay vào ô <span style={{ fontWeight: 500 }}>{modalConfig.description}</span>.
            Vui lòng kiểm tra kho quà của bạn để xem phần thưởng nhé
          </Text>
        )}

        {modalConfig.type === "reward_giftbox" && (
          <Text>
            Chúc mừng <span style={{ fontWeight: 500 }}>{userInfo.name}</span> đã mở hộp trúng{" "}
            <span style={{ fontWeight: 500 }}>{modalConfig.description}</span>. Vui lòng kiểm tra kho quà của bạn để xem phần thưởng nhé
          </Text>
        )}

        <Box width={"100%"} flex={true}>
          {modalConfig.isCancel === false ? (
            <></>
          ) : (
            <Button
              onClick={() => {
                if (modalConfig.onCancel) {
                  modalConfig.onCancel();
                }
                setIsModalOpen(false);
              }}
              fullWidth
              className="me-2 p-0 rounded-3"
              style={styles.btnClose}
            >
              {modalConfig.cancelText ? modalConfig.cancelText : "Đóng"}
            </Button>
          )}

          <Button
            onClick={() => {
              if (modalConfig.onOk) {
                modalConfig.onOk();
              }
              setIsModalOpen(false);
            }}
            fullWidth
            className="ms-2 p-0 rounded-3"
            style={styles.btnConfirm}
          >
            {modalConfig.okText ? modalConfig.okText : "Xác nhận"}
          </Button>
        </Box>
      </Box>
    );
  };

  const modalDelete = () => {
    return (
      <Box alignContent="center" flex={true} flexDirection="column" style={{ gap: 16 }}>
        {modalConfig.logo ? (
          <Box
            style={{
              width: 48,
              height: 48,
              borderRadius: "50%",
              border: `0.6px solid ${colors.outlineVariant}`,
              backgroundImage: `url(${logo})`,
              backgroundPosition: "center",
              backgroundSize: 40,
              backgroundRepeat: "no-repeat",
              justifySelf: "center",
              alignSelf: "center",
            }}
          ></Box>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
            <path
              d="M25.6665 39.667H30.3332V25.667H25.6665V39.667ZM27.9998 21.0003C28.6609 21.0003 29.2155 20.7763 29.6635 20.3283C30.1099 19.8819 30.3332 19.3281 30.3332 18.667C30.3332 18.0059 30.1099 17.4513 29.6635 17.0033C29.2155 16.5569 28.6609 16.3337 27.9998 16.3337C27.3387 16.3337 26.7849 16.5569 26.3385 17.0033C25.8905 17.4513 25.6665 18.0059 25.6665 18.667C25.6665 19.3281 25.8905 19.8819 26.3385 20.3283C26.7849 20.7763 27.3387 21.0003 27.9998 21.0003ZM27.9998 51.3337C24.7721 51.3337 21.7387 50.7208 18.8998 49.495C16.0609 48.2708 13.5915 46.6087 11.4915 44.5087C9.3915 42.4087 7.72939 39.9392 6.50517 37.1003C5.27939 34.2614 4.6665 31.2281 4.6665 28.0003C4.6665 24.7725 5.27939 21.7392 6.50517 18.9003C7.72939 16.0614 9.3915 13.592 11.4915 11.492C13.5915 9.39199 16.0609 7.7291 18.8998 6.50333C21.7387 5.2791 24.7721 4.66699 27.9998 4.66699C31.2276 4.66699 34.2609 5.2791 37.0998 6.50333C39.9387 7.7291 42.4082 9.39199 44.5082 11.492C46.6082 13.592 48.2703 16.0614 49.4945 18.9003C50.7203 21.7392 51.3332 24.7725 51.3332 28.0003C51.3332 31.2281 50.7203 34.2614 49.4945 37.1003C48.2703 39.9392 46.6082 42.4087 44.5082 44.5087C42.4082 46.6087 39.9387 48.2708 37.0998 49.495C34.2609 50.7208 31.2276 51.3337 27.9998 51.3337ZM27.9998 46.667C33.2109 46.667 37.6248 44.8587 41.2415 41.242C44.8582 37.6253 46.6665 33.2114 46.6665 28.0003C46.6665 22.7892 44.8582 18.3753 41.2415 14.7587C37.6248 11.142 33.2109 9.33366 27.9998 9.33366C22.7887 9.33366 18.3748 11.142 14.7582 14.7587C11.1415 18.3753 9.33317 22.7892 9.33317 28.0003C9.33317 33.2114 11.1415 37.6253 14.7582 41.242C18.3748 44.8587 22.7887 46.667 27.9998 46.667Z"
              fill="#005AC1"
            />
          </svg>
        )}

        <Text.Title style={{ fontSize: 24 }}>Thông báo</Text.Title>
        <Text>{modalConfig.description}</Text>

        <Box width={"100%"} flex={true}>
          <Button
            onClick={() => {
              if (modalConfig.onCancel) {
                modalConfig.onCancel();
              }
              setIsModalOpen(false);
            }}
            fullWidth
            className="me-2 p-0 rounded-3"
            style={styles.btnClose}
          >
            {modalConfig.cancelText ? modalConfig.cancelText : "Đóng"}
          </Button>
          {/* )} */}

          <Button
            onClick={() => {
              if (modalConfig.onOk) {
                modalConfig.onOk();
              }
              setIsModalOpen(false);
            }}
            fullWidth
            className="ms-2 p-0 rounded-3"
            style={styles.btnDelete}
          >
            {modalConfig.okText ? modalConfig.okText : "Xác nhận"}
          </Button>
        </Box>
      </Box>
    );
  };

  const modalLogin = () => {
    return (
      <Box alignContent="center" flex={true} flexDirection="column">
        <img
          style={{
            width: 200,
            height: 199,
            marginBottom: 16,
          }}
          src={assets.login_bg.img}
          alt=""
        />
        <img
          style={{
            width: 48,
            height: 48,
            padding: 4,
            border: `0.3px solid ${colors.outlineVariant}`,
            borderRadius: 12,
            boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
          }}
          src={logo}
          className="mx-auto d-block"
          alt="..."
        />
        <Text.Title className="my-3" style={{ fontSize: 24 }}>
          Kết nối tài khoản
        </Text.Title>
        <Text className="mb-3">
          {userInfo.name && (
            <>
              Xin chào&nbsp;
              <span style={{ fontWeight: "bold" }}>{userInfo.name}</span>,&nbsp;
            </>
          )}
          Chúng tôi cần số điện thoại của bạn để kết nối tài khoản, vui lòng nhấn “Đồng ý” để cấp quyền.
        </Text>

        <Box width={"100%"} flex={true}>
          <Button
            onClick={() => {
              setIsModalOpen(false);
            }}
            fullWidth
            className="me-2 p-0 rounded-3"
            style={styles.btnClose}
          >
            {modalConfig.cancelText ? modalConfig.cancelText : "Để sau"}
          </Button>

          <Button
            onClick={() => {
              confirmZalo();
              setIsModalOpen(false);
            }}
            fullWidth
            className="ms-2 p-0 rounded-3"
            style={styles.btnConfirm}
          >
            {modalConfig.okText ? modalConfig.okText : "Đồng ý"}
          </Button>
        </Box>
      </Box>
    );
  };

  /**
   * Hàm xử lý khi nhấn đồng ý cấp quyền lấy số điện thoại
   */
  const confirmZalo = () => {
    getPhoneNumber({
      success: async (data) => {
        let { token } = data;
        const accessToken = await getAccessToken({});
        var { userInfo } = await getUserInfo();
        var zalo = {
          accessToken: accessToken,
          token: token,
          userInfo: userInfo,
        };
        api
          .post("guestlogin/zalologin", { zalo, shop_name: window.shop_id })
          .then(async (res) => {
            var data = {
              token: res.token,
              zaloId: userInfo.id,
            };
            var { errorKeys } = await setStorage({ data });
            if (modalConfig.reload) {
              window.location.reload();
            }

            if (modalConfig.onOk) {
              modalConfig.onOk();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      fail: async (error) => {
        console.log(error);
      },
    });
  };

  return (
    <Modal
      className="modal-zalo-custom"
      visible={isModalOpen}
      // onClose={() => {
      //   setIsModalOpen(false);
      // }}
      verticalActions
      // description={description}
      modalStyle={{ padding: "24px 16px" }}
      width={"calc(100vw - 60px)"}
    >
      {renderBodyModal()}
    </Modal>
  );
}

export default ModalZaUI;
