import React from "react";
import Skeleton from "react-loading-skeleton";

function NewsSkeleton({ mode }) {
  return (
    <div className={mode == "list" ? "col-6 p-1" : "col-12 p-1"}>
      <div className="card" style={{ border: "unset", borderRadius: 8 }}>
        <div
          className="product-card"
          style={{
            width: "100%",
            height: "calc(50vw - 14px)",
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
          }}
        >
          <Skeleton style={{ width: "100%", height: "100%", display: "flex" }} />
        </div>
        <div className="card-body d-flex p-2">
          <div className="d-flex flex-column" style={{ gap: 8, lineHeight: "20px", height: "68px", position: "relative", width: "100%" }}>
            <span
              className="card-text flex-fill"
              style={{
                fontSize: 14,
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "20px",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              <Skeleton style={{ width: "100%", height: "100%", display: "flex" }} />
            </span>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ position: "relative", justifyContent: "space-between", width: "100%", height: "100%" }}>
                <Skeleton style={{ width: "100%", height: "100%", display: "flex" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsSkeleton;
