import TopAppBar from "components/common/top_app_bar";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { colors, styles } from "theme-config";
import api from "api";
import moment from "moment";
import SelectPhoneCardModal from "./SelectPhoneCardModal";
import VoucherDetailModal from "components/voucher_list/VoucherDetailModal";
import ModalZaUI from "components/common/modal/ModalZaUI";
import { getStorage } from "common/zalo_storage/ZaloStorage";

function ProgramDetail() {
  const { id } = useParams();
  const { state } = useLocation();
  const [programDetail, setProgramDetail] = useState();
  const [isToggleModal, setIsToggleModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [voucherId, setVoucherId] = useState();
  const [shopName, setShopName] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getDetail();
    // getStorage({
    //   keys: ["shop_name"],
    //   success: (data) => {
    //     // xử lý khi gọi api thành công
    //     const { shop_name } = data;
    //     if (shop_name) {
    //       setShopName(shop_name);
    //     }
    //   },
    //   fail: (error) => {
    //     // xử lý khi gọi api thất bại
    //     console.log(error);
    //   },
    // });
    getShopName();
  }, []);

  const getShopName = async () => {
    var { shopName } = await getStorage({ keys: ["shopName"] });
    setShopName(shopName);
  };

  const getDetail = () => {
    api
      .post("guest/getconfigbyid", { id: id })
      .then((res) => {
        // console.log(res);
        setProgramDetail(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const showWarning = () => {
    var modal = {
      type: "warning",
      description: "Bạn có chắc muốn dùng điểm để đổi ưu đãi/ voucher này?",
      onOk() {
        createVoucher({ id: id });
      },
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  const showSuccess = (voucherId) => {
    var modal = {
      type: "success",
      description: "Đổi thành công, vui lòng kiểm tra ưu đãi/ voucher trong mục Ưu đãi của tôi",
      onOk() {
        showDetail(voucherId);
      },
    };
    setModalConfig(modal);
    // setIsToggleModal(!isToggleModal);
    setIsModalOpen(true);
  };

  const showError = (message) => {
    var modal = {
      type: "error",
      description: message,
    };
    setModalConfig(modal);
    setIsModalOpen(true);
  };

  const showSelectPhoneCard = () => {
    var modal = {
      type: "selectPhoneCard",
      onOk: null,
      id: programDetail.voucher.id,
    };
    setModalConfig(modal);
    setIsToggleModal(!isToggleModal);
  };

  const createVoucher = (dataPost) => {
    api
      .post("guest/createvoucher", dataPost)
      .then((res) => {
        if (res) {
          showSuccess(res);
          setVoucherId(res);
        }
      })
      .catch((error) => {
        // console.log(error);
        showError(error.message);
        // console.log(error);
      });
  };

  const showDetail = (id) => {
    var modal = {
      type: "voucherDetail",
      onOk: null,
      voucherId: id,
      width: document.getElementById("programDetail").offsetWidth,
    };
    setModalConfig(modal);
    setIsToggleModal(!isToggleModal);
  };

  useEffect(() => {
    if (modalConfig && modalConfig.type === "selectPhoneCard" && modalConfig.onOk === "confirm") {
      createVoucher({ id: id, switchboard: modalConfig.selectedCard });
    }
  }, [modalConfig]);

  // useEffect(() => {
  //   if (programDetail) {
  //     console.log(checkAvailable());
  //   }
  // }, [programDetail]);

  const checkAvailable = () => {
    if (programDetail.type !== "phone_card") {
      // console.log("programDetail", programDetail);
      if (
        programDetail.voucher.voucher.list_chanel.length > 0 ||
        (programDetail.voucher.voucher.list_voucher.length > 0 &&
          programDetail.voucher.voucher.list_voucher.find((item) => {
            return item.divided === false;
          }))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (programDetail.voucher.voucher.list_phone_card) {
        var checkList = [];
        Object.keys(programDetail.voucher.voucher.list_phone_card).forEach((item) => {
          var find = programDetail.voucher.voucher.list_phone_card[item].find((item) => {
            return item.divided === false;
          });
          if (find) {
            checkList.push(find);
          }
        });
        if (checkList.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <div id="programDetail" style={{ minHeight: "100vh", ...styles.mainBackground }}>
        <TopAppBar mode="back" title={state.item.name.length > 25 ? state.item.name.slice(0, 25) + "..." : state.item.name}></TopAppBar>
        {programDetail && (
          <>
            <div style={{ position: "relative", width: "100vw", height: 200, backgroundImage: `url(${programDetail.image})`, backgroundSize: "cover" }}>
              {/* <img src={programDetail.image} style={{ width: "100vw" }} alt="" /> */}
              <div
                style={{
                  position: "absolute",
                  width: "calc(100vw - 16px)",
                  margin: 8,
                  padding: 16,
                  bottom: -48,

                  background: "white",
                  borderRadius: 12,
                  boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
                }}
              >
                <div className="d-flex flex-column">
                  <text style={{ fontWeight: 500, fontSize: 12 }}>{shopName}</text>
                  {programDetail.voucher.name.length > 25 ? (
                    <text>{programDetail.voucher.name.slice(0, 25) + "..."}</text>
                  ) : (
                    <text>{programDetail.voucher.name}</text>
                  )}
                  <text style={{ fontSize: 12, color: colors.primary }}>
                    {programDetail?.voucher?.voucher_limit?.is_voucher_limit
                      ? `Sử dụng trong ${programDetail.voucher.voucher_limit.value} ngày`
                      : "Không hết hạn"}
                  </text>
                </div>
              </div>
            </div>
            <div
              style={{
                margin: "72px 8px 16px 8px",
                padding: 16,

                background: "white",
                borderRadius: 12,
                boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
              }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <text style={{ fontSize: 12 }}>Quy đổi với</text>
                  <div className="d-flex flex-row h-100">
                    <text style={{ marginInline: 4, fontWeight: 500, fontSize: 16, color: colors.onSurfaceVariant }}>{programDetail.exchange.point}</text>
                    {/*  */}

                    {state.pointLogo ? (
                      <img src={state.pointLogo} alt="" style={{ width: 16, height: 16, alignSelf: "center" }} />
                    ) : (
                      <svg style={{ marginTop: 4 }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Iconify/G">
                          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill={colors.primary} />
                          <path
                            id="Vector"
                            d="M12.9917 11.2318C12.4741 11.6453 11.8098 11.9536 11.0673 12.1249C10.1819 12.3436 9.24942 12.4514 8.31164 12.4437C7.33747 12.4584 6.37541 12.289 5.5248 11.9531C4.71689 11.6221 4.06985 11.1258 3.66899 10.5297C3.21454 9.84432 2.98642 9.09491 3.00189 8.33807V7.7505C2.97746 6.97973 3.19018 6.2144 3.62534 5.50745C3.99784 4.90833 4.6189 4.40329 5.40634 4.05911C6.23089 3.71479 7.17548 3.54069 8.1329 3.55667C9.58347 3.55667 10.7175 3.80149 11.5349 4.29114C12.3523 4.78078 12.8372 5.49227 12.9896 6.42553H10.5374C10.4251 5.9305 10.1778 5.56916 9.79337 5.33853C9.4089 5.10791 8.88312 4.99476 8.2098 4.99476C7.8281 4.98087 7.44775 5.03642 7.10503 5.15599C6.76231 5.27557 6.46868 5.45525 6.25216 5.67787C5.80535 6.13323 5.57675 6.81038 5.57259 7.70789V8.26018C5.57259 9.16797 5.81574 9.85347 6.30203 10.3167C6.78833 10.7799 7.50252 11.0115 8.44463 11.0115C9.39228 11.0115 10.0677 10.869 10.4709 10.5841V9.09312H8.18486V7.78134H13L12.9917 11.2318Z"
                            fill={colors.onPrimary}
                          />
                          <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={colors.primary} />
                        </g>
                      </svg>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <text className="text-end" style={{ fontSize: 12 }}>
                    Thời hạn quy đổi
                  </text>
                  {programDetail.conditions.time_limit.is_limited_time ? (
                    <text className="text-end" style={{ marginInline: 4, fontWeight: 500, fontSize: 16, color: colors.onSurfaceVariant }}>
                      {moment(programDetail.conditions.time_limit.end).format("DD/MM/YYYY")}
                    </text>
                  ) : (
                    <text className="text-end" style={{ marginInline: 4, fontWeight: 500, fontSize: 16, color: colors.onSurfaceVariant }}>
                      Không thời hạn
                    </text>
                  )}
                </div>
              </div>
            </div>
            <div style={{ padding: "12px 12px 76px 12px " }}>
              <div style={{ marginBottom: 16 }}>
                <h6 style={{ fontSize: "1rem" }}>Chi tiết ưu đãi</h6>
                <div dangerouslySetInnerHTML={{ __html: programDetail.description }}></div>
              </div>
              <div>
                <h6 style={{ fontSize: "1rem" }}>Thể lệ</h6>
                <text>{programDetail.short_description}</text>
              </div>
            </div>

            <nav
              className="navbar fixed-bottom"
              style={{ ...styles.mainBackground, boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)" }}
            >
              <div className="row" style={{ gap: 8, padding: "4px 16px", margin: 0, width: "100%", fontSize: 12 }}>
                {checkAvailable() ? (
                  <button
                    className="btn"
                    style={{ ...styles.btnPrimary, borderRadius: 12, height: 40 }}
                    onClick={() => {
                      if (programDetail.type !== "phone_card") {
                        showWarning();
                      } else {
                        showSelectPhoneCard();
                      }
                    }}
                  >
                    Đổi ngay
                  </button>
                ) : (
                  <button
                    className="btn"
                    disabled={true}
                    style={{ ...styles.btnDisabled, borderRadius: 12, height: 40 }}
                    onClick={() => {
                      showWarning();
                    }}
                  >
                    Hết mã
                  </button>
                )}
              </div>
            </nav>
            {/* <WarningModal isToggle={isToggleModal} modalConfig={modalConfig} setModalConfig={setModalConfig} setIsToggle={setIsToggleModal}></WarningModal>
            <SuccessModal isToggle={isToggleModal} modalConfig={modalConfig} setModalConfig={setModalConfig} setIsToggle={setIsToggleModal}></SuccessModal>
            <ErrorModal isToggle={isToggleModal} modalConfig={modalConfig} setModalConfig={setModalConfig} setIsToggle={setIsToggleModal}></ErrorModal> */}
            <SelectPhoneCardModal isToggle={isToggleModal} modalConfig={modalConfig} setModalConfig={setModalConfig} setIsToggle={setIsToggleModal} />
            <VoucherDetailModal isToggle={isToggleModal} modalConfig={modalConfig} setIsToggle={setIsToggleModal} />
            <ModalZaUI isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalConfig={modalConfig} />
          </>
        )}
      </div>
    </>
  );
}

export default ProgramDetail;
