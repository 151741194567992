import React, { useEffect, useState } from "react";
import api from "api";
import TopAppBar from "components/common/top_app_bar";
import { useParams } from "react-router-dom";
import { styles } from "theme-config";
import { fomatPoint } from "components/common/converNumber";
import { getStorage } from "common/zalo_storage/ZaloStorage";

const TierDetail = () => {
  const [tier, setTier] = useState();
  const [shopName, setShopName] = useState();
  const param = useParams();
  const getTier = async () => {
    await api
      .post("/guest/gettierbyid", { tierId: param.id })
      .then((data) => {
        setTier(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getNameShop = async () => {
    var { shopName } = await getStorage({ keys: ["shopName"] });
    setShopName(shopName);
    // try {
    //   const { shop_name } = await getStorage({
    //     keys: ["shop_name"],
    //   });
    //   setShopName(shop_name);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  useEffect(() => {
    getTier();
    getNameShop();
  }, []);

  useEffect(() => {
    if (tier) {
      var contents = document.querySelectorAll("#content-detail img");
      contents.forEach((item) => {
        item.style.width = "100%";
        item.style.height = "auto";
      });
    }
  }, [tier]);
  return (
    <>
      <div className="container-fluid m-0 p-0" style={{ height: "100vh" }}>
        <TopAppBar mode="back" title={tier?.name || "Mới"} />
        <div className="container-fluid" style={{ padding: "12px 12px 24px 12px" }}>
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="text-center pb-1" style={{ ...styles.textOnSurface, fontWeight: 500, fontSize: 12 }}>
                {shopName || ""}
              </div>
              <div className="d-flex flex-column gap-2 py-2">
                <div className="d-flex justify-content-center">
                  {param.id === "default" ? (
                    <div
                      style={{ width: 40, height: 40, ...styles.bgPrimary, ...styles.textWhite }}
                      className="rounded-circle d-flex justify-content-center align-items-center"
                    >
                      <span>Mới</span>
                    </div>
                  ) : (
                    <img src={tier?.logo} style={{ width: 40, height: 40 }} className="rounded-pill" />
                  )}
                </div>
                <div className="text-center" style={{ ...styles.textOnSurface, fontWeight: 400, fontSize: 22 }}>
                  {tier?.name || "Mới"}
                </div>
              </div>
              <div className="d-flex justify-content-between gap-2 py-3 border-bottom border-top border-1">
                <div style={{ ...styles.textOnSurfaceVariant, fontWeight: 500, fontSize: 14 }}>Điểm cần có:</div>
                <div style={{ ...styles.textPrimary, fontWeight: 500, fontSize: 16 }}>{fomatPoint(tier?.value || 0)}</div>
              </div>
              <div className="py-3 d-flex flex-column gap-1">
                <div className="pb-1">
                  <p style={{ ...styles.textOnSurfaceVariant, fontWeight: 500, fontSize: 14 }} className="m-0 p-0">
                    Mô tả ngắn:
                  </p>
                  <p style={{ ...styles.textOnSurfaceVariant, fontWeight: 400, fontSize: 14 }} className="m-0 p-0">
                    {tier?.short_description || ""}
                  </p>
                </div>
                <div className="pt-2 pb-3">
                  <p style={{ ...styles.textOnSurfaceVariant, fontWeight: 500, fontSize: 14 }} className="m-0 p-0">
                    Mô tả chi tiết:
                  </p>
                  {tier?.short_description ? (
                    <div
                      id="content-detail"
                      style={{ ...styles.textOnSurfaceVariant, fontWeight: 400, fontSize: 14 }}
                      dangerouslySetInnerHTML={{ __html: tier?.description }}
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TierDetail;
