import React, { useState, useEffect } from "react";
import { colors, assets, styles } from "theme-config/index";
import ListCategory from "components/explore_list/ListCategory";
import BlogItem from "components/explore_list/BlogItem";
// import { useNavigate } from "zmp-ui";
import { useNavigate } from "react-router-dom";

const Explore = () => {
  const navigate = useNavigate();
  const [select, setSelect] = useState();
  const showExploreList = () => {
    navigate(`/${window.shop_sub_path}/ExploreList`);
  };
  return (
    <>
      <div className="container-fluid px-2 pt-3" style={styles.bgWhite}>
        <div className="d-flex justify-content-between mb-1">
          <span style={styles.textOnPrimary}>Khám phá thêm</span>
          <div
            onClick={showExploreList}
            type="button"
            //className="border-0 rounded-2 px-3 py-1"
            // style={{ boxSizing: "border-box", ...styles.textSecondary, ...styles.bgSecondaryContainer, fontSize: 12 }}
            style={{
              display: "flex",
              alignItems: "center",
              fontWeight: 500,
              fontSize: 16,
              height: 24,
              ...styles.textPrimary,
              fontSize: 12,
            }}
          >
            Xem tất cả
          </div>
        </div>
        <ListCategory select={select} setSelect={setSelect} showAll={false} stylesBgButton={styles.bgWhite}></ListCategory>
      </div>
      <BlogItem select={select} sizePage={4} stylesBg={styles.bgWhite} autoNextPage={false} showEmtyCenterBig={false} />
    </>
  );
};
export default Explore;
