import React, { useState, useEffect } from "react";
import * as bootstrap from "bootstrap";
import { colors, styles } from "theme-config";
import moment from "moment";
import RangePicker from "../range_picker";

function DatePickerBottomSheet({ isToggle, setIsToggle, modalConfig, setModalConfig, value, onChange }) {
  const [modal, setModal] = useState();
  const [activeBtn, setActiveBtn] = useState();
  useEffect(() => {
    const modal = new bootstrap.Modal(document.getElementById("voucherDetailModal"));

    setModal(modal);
  }, []);

  useEffect(() => {
    if (modal) {
      if (modalConfig?.type === "datePicker") {
        modal.toggle();
        // getVoucherById();
      }
    }
  }, [isToggle, modal]);

  const onApply = () => {
    var newModalConfig = { ...modalConfig };
    newModalConfig.onOk = "ok";
    setModalConfig(newModalConfig);
    setIsToggle(!isToggle);
  };

  const selectedFilter = (key) => {
    setActiveBtn(key);
    switch (key) {
      case "today":
        onChange([new Date(), new Date()]);
        break;
      case "yesterday":
        onChange([moment(new Date()).subtract(1, "days").toDate(), moment(new Date()).subtract(1, "days").toDate()]);
        break;
      case "this_month":
        onChange([moment(new Date()).startOf("month").toDate(), moment(new Date()).endOf("month").toDate()]);
        break;
      case "last_month":
        onChange([moment(new Date()).subtract(1, "months").startOf("month").toDate(), moment(new Date()).subtract(1, "months").endOf("month").toDate()]);
        break;
      case "7_days":
        onChange([moment(new Date()).subtract(7, "days").toDate(), new Date()]);
        break;

      case "30_days":
        onChange([moment(new Date()).subtract(30, "days").toDate(), new Date()]);
        break;
      default:
        break;
    }
  };

  return (
    <div className="modal fade-scale" id="voucherDetailModal" tabIndex="-1" role="dialog" aria-labelledby="voucherDetailModalLabel" aria-hidden="true">
      <div className="modal-dialog" style={{ margin: 0, minHeight: "100%", display: "flex", alignItems: "end", maxWidth: "100vw" }}>
        <div
          className="modal-content"
          style={{
            background: "white",
            width: "100vw",
            margin: 0,
            marginTop: 64,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,

            padding: "16px 16px 24px 16px",
          }}
        >
          <div class="d-flex flex-column" style={{ gap: 16 }}>
            <div className="align-self-center" style={{ padding: 16 }}>
              <div
                style={{ width: 32, height: 4, background: colors.outline, opacity: 0.12, borderRadius: 8 }}
                onClick={() => {
                  setIsToggle(!isToggle);
                }}
              ></div>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{ gap: 8 }}>
              <button
                className="btn"
                style={{ width: "50%", ...styles.btnOutline, color: colors.primary, background: activeBtn === "today" ? colors.primaryContainer : "white" }}
                onClick={() => {
                  selectedFilter("today");
                }}
              >
                {activeBtn === "today" && (
                  <svg
                    style={{ marginRight: 8, marginBottom: 2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                )}
                Hôm nay
              </button>
              <button
                className="btn"
                style={{ width: "50%", ...styles.btnOutline, color: colors.primary, background: activeBtn === "yesterday" ? colors.primaryContainer : "white" }}
                onClick={() => {
                  selectedFilter("yesterday");
                }}
              >
                {activeBtn === "yesterday" && (
                  <svg
                    style={{ marginRight: 8, marginBottom: 2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                )}
                Hôm qua
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{ gap: 8 }}>
              <button
                className="btn"
                style={{
                  width: "50%",
                  ...styles.btnOutline,
                  color: colors.primary,
                  background: activeBtn === "this_month" ? colors.primaryContainer : "white",
                }}
                onClick={() => {
                  selectedFilter("this_month");
                }}
              >
                {activeBtn === "this_month" && (
                  <svg
                    style={{ marginRight: 8, marginBottom: 2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                )}
                Tháng này
              </button>
              <button
                className="btn"
                style={{
                  width: "50%",
                  ...styles.btnOutline,
                  color: colors.primary,
                  background: activeBtn === "last_month" ? colors.primaryContainer : "white",
                }}
                onClick={() => {
                  selectedFilter("last_month");
                }}
              >
                {activeBtn === "last_month" && (
                  <svg
                    style={{ marginRight: 8, marginBottom: 2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                )}
                Tháng trước
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center" style={{ gap: 8 }}>
              <button
                className="btn"
                style={{ width: "50%", ...styles.btnOutline, color: colors.primary, background: activeBtn === "7_days" ? colors.primaryContainer : "white" }}
                onClick={() => {
                  selectedFilter("7_days");
                }}
              >
                {activeBtn === "7_days" && (
                  <svg
                    style={{ marginRight: 8, marginBottom: 2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                )}
                7 ngày
              </button>
              <button
                className="btn"
                style={{ width: "50%", ...styles.btnOutline, color: colors.primary, background: activeBtn === "30_days" ? colors.primaryContainer : "white" }}
                onClick={() => {
                  selectedFilter("30_days");
                }}
              >
                {activeBtn === "30_days" && (
                  <svg
                    style={{ marginRight: 8, marginBottom: 2 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                )}
                30 ngày
              </button>
            </div>

            <text style={{ fontSize: 16, fontWeight: 500 }}>Tuỳ chỉnh</text>
            {/* <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
              <span class="input-group-text" id="basic-addon1" onClick={showRangePicker}>
                @
              </span>
            </div> */}
            <RangePicker value={value} onChange={onChange} />
            <div className="d-flex justify-content-center align-items-center" style={{ gap: 16 }}>
              <button
                className="btn"
                style={{ width: "50%", ...styles.btnClose, height: 40 }}
                onClick={() => {
                  setIsToggle(!isToggle);
                }}
              >
                Huỷ
              </button>
              <button className="btn" style={{ width: "50%", ...styles.btnPrimary, height: 40 }} onClick={onApply}>
                Áp dụng
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatePickerBottomSheet;
